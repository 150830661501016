import React, { FC, useEffect, useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router';

import { DrawerHeader } from './PanelDrawerWrapper';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { colors } from 'css/Theme';
import { finishCheckoutOnTwoPanelLayout } from 'store/actions/CheckoutActions';
import * as api from 'api/TransactionsApi';
import { ReactComponent as EnvelopeIcon } from 'assets/icons/envelope.svg';
import { ReactComponent as PrinterIcon } from 'assets/icons/printer.svg';
import { ReactComponent as CurrencyIcon } from 'assets/icons/icon-currency.svg';
import { ReactComponent as CheckCircleIcon } from 'assets/icons/icon-check-circle.svg';
import { ReactComponent as DownArrowIcon } from 'assets/icons/chevron-down.svg';
import { Button, ButtonWithIcon } from 'components/buttons';
import { Input } from 'components/inputs';
import { roundChangeDue } from 'util/Helpers';
import { CashAmount } from 'components/text';
import { SidePanel, SidePanelSection, SectionHeader } from 'components/layout';
import { OrderTotals } from 'components/cart/OrderTotals';
import { DutchiePayTipBanner } from './DutchiePayTipBanner';
import { PaymentType } from 'models/Checkout';
import { PrintStatus } from 'models/Printing';
import { usePrintJob } from 'util/hooks/printing/usePrintJob';
import { usePrintJobStatus } from 'util/hooks/printing/usePrintJobStatus';
import { usePopup } from 'components/popups';
import { ConfirmOpenCashDrawerPopup } from 'components/CartPopups/components/ConfirmOpenCashDrawerPopup';
import { useAnonymousCart } from '../hooks/useAnonymousCart';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useTransactionManager } from '../hooks/useTransactionManager';
import { useMetrics } from 'util/metrics/useMetrics';
import { customEventKeys } from 'util/logger';
import { DurationUnit } from 'store/reducers/MetricsReducer';
import { useCompactCartLayout } from 'util/hooks/responsive/useCompactCartLayout';
import { useGetCartDetails } from '../hooks/useGetCartDetails';

export const PaymentComplete: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const settings = useAppSelector((state) => state.settings);
  const { features, userSettings } = settings;
  const guest = useAppSelector((state) => state.customer.details);

  const checkout = useAppSelector((state) => state.checkout);
  const [email, setEmail] = useState(guest?.Email || '');
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const { printReceipt, printLabels } = usePrintJob();
  const { printReceiptStatus } = usePrintJobStatus();
  const { isVisible: isConfirmOpenCashDrawerPopupVisible, toggle: toggleConfirmOpenCashDrawerPopup } = usePopup();

  const { data: cart } = useGetCartDetails();

  const metrics = useMetrics();

  // Features to track with metrics
  const wasCollectAnonymousDemographicsEnabled = useAppSelector(
    (state) => state.settings.features.CollectAnonymousDemo
  );
  const wasConfirmBirthdayOnCheckinEnabled = useAppSelector(
    (state) => state.settings.features.ConfirmBirthdayOnCheckin
  );
  const wasConfirmBirthdayOnCheckoutEnabled = useAppSelector(
    (state) => state.settings.features.ConfirmBirthdayOnCheckout
  );

  useEffect(() => {
    // Increment the count of orders completed
    metrics.addCount(customEventKeys.metrics.count.ordersCompleted, {
      unit: 'orders',
      logData: { shipmentId: cart.ShipmentId, numberOfItems: cart.Cart.length, total: cart.GrandTotalRounded },
    });
    // Track how long it took to complete this order
    // Note: this will only be tracked if the timestamp 'anonymousTransactionStarted' was set
    metrics.addDuration(customEventKeys.metrics.durations.completedPayment, {
      timestampKey: customEventKeys.metrics.timestamps.anonymousTransactionStarted,
      unit: DurationUnit.SECONDS,
      logData: {
        guestId: cart.CustomerId,
        shipmentId: cart.ShipmentId,
        // Log any features that may have affected the duration of the order
        wasCollectAnonymousDemographicsEnabled,
        wasConfirmBirthdayOnCheckinEnabled,
        wasConfirmBirthdayOnCheckoutEnabled,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tipAmount = checkout.payment?.methods[0]?.tipAmount || 0;
  const showDutchiePayTipBanner = checkout.payment?.methods[0]?.type === PaymentType.DutchiePay && tipAmount > 0;

  const isPrintReceiptButtonDisabled = printReceiptStatus === PrintStatus.PRINTING;

  const { isStartNewCartAfterCheckoutEnabled } = useAnonymousCart();
  const { clearTransaction } = useTransactionManager();

  const onDone = useCallback(() => {
    // If the new setting is enabled, start a new cart after checkout
    if (isStartNewCartAfterCheckoutEnabled) {
      // Add a timestamp for the done button if the start new cart after checkout feature is enabled
      metrics.addTimestamp(customEventKeys.metrics.timestamps.clickTransactionDoneButton);
      clearTransaction();
    } else {
      history.push('/guestlist');
    }
    dispatch(finishCheckoutOnTwoPanelLayout());
  }, [clearTransaction, dispatch, history, isStartNewCartAfterCheckoutEnabled, metrics]);

  const sendToEmail = (Email: string) => {
    //note: We should probably add some validation for better UX
    api
      .sendEmail({
        Email,
        Register: settings.selectedRegister?.value,
        ShipmentId: cart.ShipmentId,
      })
      .then(() => dispatch(successNotification('Email sent')))
      .catch(({ message }) => dispatch(errorNotification(message)));
  };

  const onReceiptPrint = () => {
    if (cart.ShipmentId) {
      printReceipt({
        ReceiptType: 'Receipt',
        ReceiptParameters: cart.ShipmentId,
        ForDelivery: false,
        PrinterId: userSettings.selectedReceiptPrinter?.PrinterId || 0,
        subtotal: cart.SubTotal,
        total: cart.GrandTotalRounded,
        localPrinter: userSettings.selectedReceiptPrinter?.LocalPrinter || false,
        popCashDrawer: false,
      });
    }
  };

  const onPrintAllLabel = () => {
    if (cart.ShipmentId) {
      printLabels({
        guest: { ShipmentId: cart.ShipmentId },
        items: cart.Cart,
        printAll: true,
        autoPrint: false,
      });
    }
  };

  const cashPayments = checkout?.payment?.methods.filter((method) => method.type === PaymentType.Cash) ?? [];
  const isCashTransaction = checkout.changeDue > 0 || cashPayments?.length > 0;

  const { isCompactLayout } = useCompactCartLayout();

  return (
    <SidePanel>
      <SidePanelSection gap='1rem'>
        {isCompactLayout ? (
          <DrawerHeader actionProps={{ label: 'Done', onClick: onDone }}>
            <CheckCircleIcon />
            <PanelTitle data-testid='payment-success_title_payment-successful'>Payment successful!</PanelTitle>
          </DrawerHeader>
        ) : (
          <TitleContainer>
            <CheckCircleIcon />
            <Title data-testid='payment-success_title_payment-successful'>Payment successful!</Title>
          </TitleContainer>
        )}
        {showDutchiePayTipBanner && <DutchiePayTipBanner currencySymbol='$' tipAmount={tipAmount} />}
        <EmailContainer>
          <Input
            placeholder='Recipient Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            startAdornment={<EnvelopeIcon />}
            automationId='payment-success_dutchie-input_email-input'
          />
          <Button onClick={() => sendToEmail(email)} automationId='payment-success_dutchie-button_email-receipt'>
            Send
          </Button>
        </EmailContainer>
        <PrintButtons>
          <ButtonWithIcon
            tertiary
            onClick={onReceiptPrint}
            disabled={isPrintReceiptButtonDisabled}
            icon={PrinterIcon}
            label='Print receipt'
          />
          <ButtonWithIcon tertiary onClick={onPrintAllLabel} icon={PrinterIcon} label='Print label' />
        </PrintButtons>
      </SidePanelSection>
      <SidePanelSection gap='1rem' showBorder={false}>
        <OrderHeader
          onClick={(e) => {
            e.preventDefault();
            setShowOrderDetails(!showOrderDetails);
          }}
        >
          <SectionHeader>Order details</SectionHeader>
          <Chevron data-testid='payment-success_chevron_open' open={showOrderDetails} />
        </OrderHeader>
        {showOrderDetails && (
          <div>
            <Row>
              <div>Order number</div>
              <div data-testid='summary_div_cart-order-number'>{cart.ShipmentId}</div>
            </Row>
            <Row>
              <div>Reference number</div>
              <div data-testid='summary_div_cart-transaction-reference'>{guest?.TransactionReference}</div>
            </Row>
            <Divider />
            <OrderTotals cart={cart} showTotal={false} hideGetTotalBtn />
          </div>
        )}
      </SidePanelSection>
      <StickyContainer flex>
        <BoldRow>
          <div>Order Total</div>
          <CashAmount value={cart?.GrandTotalRounded} automationId='payment-success_cash-amount_order-total' />
        </BoldRow>
        <BoldRow>
          <div>Paid</div>
          <CashAmount automationId='payment-success_cash-amount_paid' value={checkout.totalPaid} />
        </BoldRow>
        <CashDrawerContainer>
          <ChangeDueRow>
            <CurrencyIcon />
            <div style={{ marginRight: 'auto' }}>Change due</div>
            <CashAmount
              value={roundChangeDue(features, checkout.changeDue)}
              automationId='payment-success_cash-amount_change-due'
            />
          </ChangeDueRow>
          {isCashTransaction && (
            <>
              <OpenCashDrawerButton
                secondary
                onClick={toggleConfirmOpenCashDrawerPopup}
                marginLeft={10}
                automationId='payment-success_cash-drawer-button'
              >
                Open Cash Drawer
              </OpenCashDrawerButton>
              <ConfirmOpenCashDrawerPopup
                isVisible={isConfirmOpenCashDrawerPopupVisible}
                hide={toggleConfirmOpenCashDrawerPopup}
              />
            </>
          )}
        </CashDrawerContainer>
        <Button cta onClick={onDone} automationId='payment-success_done-button_done'>
          Done
        </Button>
      </StickyContainer>
    </SidePanel>
  );
};

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 0.5rem;
`;

const PanelTitle = styled.div``;

const Title = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
`;

const EmailContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const PrintButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  & > button {
    height: 2.75rem;
    flex-grow: 1;

    & > svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;

const OrderHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Chevron = styled(DownArrowIcon)<{ open?: boolean }>`
  cursor: pointer;

  ${({ open }) =>
    open &&
    `
        transform: rotate(180deg);
    `}
`;

const BaseFontStyles = css`
  color: ${colors.dutchie.grey30};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.5%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${BaseFontStyles}
`;

const BoldRow = styled(Row)`
  color: ${colors.dutchie.almostBlack};
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 700;
`;

const ChangeDueRow = styled(Row)`
  flex-grow: 1;
  padding: 0.75rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  border-radius: 0.5rem;
  color: ${colors.dutchie.green80};
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 700;
  background: ${colors.dutchie.green10};
  & svg path {
    stroke: ${colors.dutchie.green80};
  }
`;

const Divider = styled.hr`
  height: 0;
  border: 1px solid ${colors.dutchie.backgroundGrey};
  margin: 0.75rem 0;
`;

const StickyContainer = styled(SidePanelSection)`
  position: sticky;
  justify-content: flex-end;
  bottom: 0;
  border: none;
  background: ${colors.dutchie.primaryWhite};
  border-top: 1px solid ${colors.dutchie.backgroundGrey};
`;

const CashDrawerContainer = styled.div`
  margin: 0.75rem 0 1.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const OpenCashDrawerButton = styled(Button)`
  height: 100%;
`;
