import { logger, customEventKeys } from 'util/logger';
import { parseErrorMessage } from 'util/helpers/parseErrorMessage';

import type { PrintJobContext } from 'util/logger/types/printing';

import { successNotification, errorNotification } from 'store/actions/NotificationsActions';
import { setPrintJobStatus } from 'store/actions/PrintJobsActions';

import { PrintJob, PrintStatus } from 'models/Printing';

import {
  getStoredHardware,
  peripheralInfo,
  GetPrintJobResponse,
  processHardwarePrintJob,
  selectHardwarePrinterType,
} from 'util/hardwareLibrary/hardware-library-utils';
import { useAppDispatch } from 'util/hooks';
import { post } from 'api/HttpHelpers';

type UsePrintPickTicketParams = {
  guestId: number;
  shipmentId: number;
};

export const usePrintPickTicket = () => {
  const dispatch = useAppDispatch();

  return async (params: UsePrintPickTicketParams) => {
    const { fulfillmentPrinter } = getStoredHardware();
    try {
      if (!fulfillmentPrinter) {
        throw new Error('No printer selected');
      }

      dispatch(successNotification('Printing pick ticket...'));
      dispatch(setPrintJobStatus({ printJob: PrintJob.PICK_TICKET, status: PrintStatus.PRINTING }));

      logger.info<PrintJobContext>('print pick ticket', {
        key: customEventKeys.printing.jobStarted,
        job: PrintJob.PICK_TICKET,
        requestParams: params,
        printer: peripheralInfo(fulfillmentPrinter),
      });

      const job = await post<GetPrintJobResponse>('v2/print-jobs/get-preorder-receipt-job', {
        AcctId: params.guestId,
        PrinterId: 0, // not used by endpoint
        PrinterType: selectHardwarePrinterType(fulfillmentPrinter),
        ShipmentId: params.shipmentId,
      });
      await processHardwarePrintJob(job, fulfillmentPrinter);

      dispatch(successNotification('Pick ticket printed'));
      dispatch(setPrintJobStatus({ printJob: PrintJob.PICK_TICKET, status: PrintStatus.SUCCESSFUL }));
    } catch (e) {
      const message = parseErrorMessage(e);
      dispatch(errorNotification(`Error printing pick ticket: ${message}`));
      dispatch(setPrintJobStatus({ printJob: PrintJob.PICK_TICKET, status: PrintStatus.FAILED }));
      logger.error(e, {
        message: 'Failed to print pick ticket',
        requestParams: params,
        printer: peripheralInfo(fulfillmentPrinter),
      });
    }
  };
};
