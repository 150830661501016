import React from 'react';
import styled from 'styled-components';

import { colors } from 'css/Theme';
import { ReactComponent as ErrorIcon } from 'assets/icons/icon-error.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/icon-success.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/icon-warning.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/icon-info.svg';

export enum AlertBannerStyles {
  default,
  error,
  info,
  success,
  warning,
}

type AlertBannerStyleProps = {
  [index: string]: {
    background: string;
    textColor: string;
    displayIcon?: JSX.Element;
  };
};

const StyledErrorIcon = styled(ErrorIcon)`
  margin-top: 0 !important;
`;

const StyledSuccessIcon = styled(SuccessIcon)`
  margin-top: 0 !important;
`;

const StyledWarningIcon = styled(WarningIcon)`
  margin-top: 0 !important;
`;

const StyledInfoIcon = styled(InfoIcon)`
  margin-top: 0 !important;
`;

export const AlertBannerStyle: AlertBannerStyleProps = {
  default: {
    background: colors.dutchie.backgroundGrey,
    textColor: colors.dutchie.almostBlack,
  },
  error: {
    background: colors.dutchie.red10,
    textColor: colors.dutchie.flora,
    displayIcon: <StyledErrorIcon />,
  },
  info: {
    background: colors.dutchie.paleBlue,
    textColor: colors.dutchie.deepBlue,
    displayIcon: <StyledInfoIcon />,
  },
  success: {
    background: colors.dutchie.green20,
    textColor: colors.dutchie.green80,
    displayIcon: <StyledSuccessIcon />,
  },
  warning: {
    background: colors.dutchie.paleYellow,
    textColor: colors.dutchie.crispyUmber,
    displayIcon: <StyledWarningIcon />,
  },
};
