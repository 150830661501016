import { ProductSearchResult } from 'queries/v2/product/types';
import { PreauthInfo } from './Cart';

export enum PaymentType {
  Cash,
  Credit,
  Debit,
  Check,
  'Gift Card',
  Digital,
  Prepayment,
  SplitPayment,
  Dutchie, // Ecomm-based DutchiePay
  DutchiePay, // In-store based DutchiePay
  MMAP,
  RethinkEcomm,
  RethinkSms,
  Hub,
  Manual,
  GeneriPay,
  Electronic = 16,
}

export enum FeePaymentMethod {
  DutchiePay = 1,
  PinDebit = 2,
  Credit = 3,
}

export type PaymentsHubSplitPaymentMethod = {
  name: string;
  amount_cents: number;
};

export type PaymentMethod = {
  id: string;
  name: string;
  type: PaymentType;
  amount: number;
  authCode?: string;
  data?:
    | HypurCheckoutRequest
    | LinxCheckoutRequest
    | CanPayCheckoutRequest
    | PreauthInfo
    | ManualPaymentCheckoutRequest
    | GeneriPayCheckoutRequest
    | PaymentsHubSplitPaymentMethod[];
  tipAmount?: number;
  finalized?: boolean;
  originalAmount?: number;
  PaymentProviderType?: string;
  PaymentProcessor?: string;
};

export type Payments = {
  cash?: number | null;
  debit?: number | null;
  check?: number | null;
  credit?: number | null;
  digital?: number | null;
  mmap?: number | null;
  prepayment?: number | null;
  gift?: number | null;
  paymentSum?: number | null;
  tipAmount?: number;
};

export type ExistingPaymentMethod = PaymentMethod & {
  PollingComplete: boolean;
  PaymentProviderType: string;
  Last4: string;
  RefundSupportedOnCancel: boolean;
};

export type MakePaymentRequest = {
  TransactionAmount: number;
  CashPaid: number;
  DebitPaid: number;
  MMAPPaid: number;
  Change: number;
  ElectronicPaid: string;
  ElectronicPaymentData?: HypurCheckoutRequest | LinxCheckoutRequest | CanPayCheckoutRequest | PreauthInfo;
  ElectronicPaymentType: string;
  TransactionId: number;
  InvoiceTotal: string;
  TotalTax: number;
  TotalItems: number;
};

export type CheckoutRequest = {
  ShipmentId: number;
  MJStateIDNo?: string;
  TotalItems: number;
  DiscountAmt: number;
  TaxAmt: number;
  CheckPaid: number;
  CCPaid: number;
  TipPaid: number;
  UserId?: number;
  PinUserId?: number;
  AuthCode: string;
  TerminalId: number;
  DebitPaid: number;
  GiftPaid: number;
  MMAPPaid: number;
  PreAuth: number;
  TotalCharges: number;
  Paidamt: number;
  DueCustomer: number;
  DueCustomerRounded: number;
  TransType: string;
  TransId: string;
  SubTotal: number;
  ApprovalCode: string;
  NetAmt: number;
  TaxRt: number;
  ElectronicPaid?: string;
  ElectronicPaymentData?: HypurCheckoutRequest | LinxCheckoutRequest | CanPayCheckoutRequest | PreauthInfo;
  ElectronicPaymentType?: string;
  EducationalMaterialsAccepted?: boolean;
  LoyaltyPoints: number;
  ShowDaysSupplyCalculator?: boolean;
  CustomerId?: number;
  Signups?: string[];
  ReactVersion?: string;
  PaymentMethods: PaymentMethod[] | null;
  Data?: PreauthInfo;
};

export type PostCheckoutRequest = {
  ShipmentId: number;
};

export type DigitalPaymentRequest = {
  ElectronicPaid?: string;
  ElectronicPaymentData?:
    | HypurCheckoutRequest
    | LinxCheckoutRequest
    | CanPayCheckoutRequest
    | PreauthInfo;
  ElectronicPaymentType?: string;
  ShipmentId: number;
  RecordAmountAsElectronicPayment: boolean;
  Register: number;
  SupportsPolling: boolean;
};

export type PollingPaymentRequest = {
  ElectronicPaymentType: string;
  ShipmentId: number;
};

export type BiotrackCheckoutRequest = {
  PatientScan?: string;
  ShipmentId: number;
};

export type DeliveryCar = {
  CarLicId: string;
  CarModel: string;
  id: number;
};

export type TaskGeneratedResponse = {
  DeliverySystemTaskGenerated: boolean;
  DeliverySystemTaskUpdated: boolean;
};

export type ReadyForDeliveryResponse = TaskGeneratedResponse & {
  DeliveryManifest: DeliveryManifest;
  DeliveryRoomThresholdViolations: DeliveryRoomThresholdViolations;
};

export type UpdateDeliveryManifestResponse = TaskGeneratedResponse;

export type DeliveryManifest = {
  ArrivalDate: string;
  CarId?: number;
  ClosedDate: string;
  Comments: string;
  DepartureDate: string;
  DriverId?: number;
  DeliveryAddressId: number;
  ExternalDeliveryId: string;
  METRCStateCode: string;
  ManifestId: number;
  ReadyDate: string;
  RouteDetails: string;
  SecondaryDriverId?: number;
  ThirdDriverId?: number;
  FourthDriverId?: number;
  Title: string;
  DeliveryRouteId: number;
};

export type DeliveryRoomThresholdViolations = {
  MaxICTDollarRoomViolation?: string;
  MaxTotalInventoryDollarRoomViolation?: string;
  MaxInventoryWeightRoomViolation?: string;
  MaxNonConcentrateWeightRoomViolation?: string;
  MaxConcentrateWeightRoomViolation?: string;
};

export type GetDeliveryManifestRequest = {
  TransId: number;
};

export type Driver = {
  DriverId: number;
  DriverPosition: number;
};

export type UpdateDeliveryManifestRequest = {
  Arrives: string | null;
  CarId?: number;
  Comments: string;
  Departs: string | null;
  Directions: string;
  AddressId: number;
  Drivers: Driver[];
  Title?: string;
  TransId: number;
  Register: number;
  DistanceToDestination?: number;
};

export type SetReadyForDeliveryRequest = {
  ShipmentId: number;
};

export type BypassStateSystemRequest = {
  ManagerPIN: string;
  ShipmentId: number;
};

export type GetDirectionsRequest = {
  ShipmentId: number;
  DestinationAddress1?: string;
  DestinationAddress2?: string;
  DestinationCity?: string;
  DestinationState?: string;
  DestinationPostalCode?: string;
};

export type HypurClient = {
  CheckInISN: string;
  IndividualISN: string;
  IndividualName: string;
  IndividualBase64Thumbnail: string;
};

export type HypurCheckoutRequest = {
  CheckInISN: string;
  IndividualISN: string;
  Pin?: string;
};

export type LinxCheckoutRequest = {
  CardNumber: string;
  DriversLicenseNumber: string;
  CustomerState: string;
};

export type CanPayCheckoutRequest = {
  Token?: string;
};

export type DutchiePayCheckoutRequest = {
  Id: string;
  Token?: string;
  Amount: number;
};

export type ManualPaymentCheckoutRequest = {
  ManualPaymentProcessorId: number;
  ManualPaymentProcessorName: string;
};

export type GeneriPayCheckoutRequest = Record<string, unknown>;

export type EletronicPaymentRequest = {
  ElectronicPaid: string;
  ElectronicPaymentData?: HypurCheckoutRequest | LinxCheckoutRequest | CanPayCheckoutRequest;
  ElectronicPaymentType: string;
  ShipmentId: number;
  RecordAmountAsElectronicPayment: boolean;
};

export type SaveCheckoutSignatureRequest = {
  ShipmentId: number;
  Signature: string;
};

export type VerifyCartRequest = {
  ShipmentId: number;
  PinCode: number;
};

/** @deprecated Clean up with pos.register.anon-cart-workflow */
export type VerifyBirthdateRequest = {
  Shipmentid?: number;
  AcctId?: number;
  Birthdate: Date;
};

export type SetTransactionNotesRequest = {
  TransactionNote: string;
  GuestId: number;
  TransId: number;
  TransactionId: number;
};

export type UploadDLRequest = {
  ShipmentId: number;
  Image: string;
};

export type GetTransactionNotesRequest = {
  TransactionId: number;
};

export type TransactionNotesResponse = {
  ShipmentId: number;
  TransactionNotes?: string;
};

export type DirectionsReponse = {
  Distance: number;
  Directions: string;
};

export type CancelJoryTransaction = {
  ShipmentId: number;
  SupportsPolling: boolean;
};

export type CancelPaynetworxTransaction = {
  ShipmentId: number;
};

export type CancelPaymentsHubTransaction = {
  ShipmentId: number;
};

export type CancelPlanetPaymentsTransaction = {
  ShipmentId: number;
};

export type BaseElectronicPaymentResponse = {
  TipAmount?: number;
  DebitPaid: number;
  CreditPaid: number;
  ElectronicAmount: number;
  TotalPaid: number;
};

export type ElectronicPaymentResponse = BaseElectronicPaymentResponse & {
  IsPolling: boolean;
  PaymentPollingIntervalseconds: number;
};

export type PollingElectronicPaymentRequest = {
  ElectronicPaymentType: string;
  ShipmentId: number;
};

export type PollingElectronicPaymentResponse = BaseElectronicPaymentResponse & {
  PaymentSuccess: boolean;
  PollingComplete: boolean;
  PollingError: string;
  RefreshCart: boolean;
  PaymentsHubProcessor: string;
};

export type GetLoyaltySignupsRequest = {
  CustomerId: number;
};

export type DutchiePayInvoiceConfirmationNotification = {
  LspId: number;
  LocId: number;
  ShipmentId: number;
  TipAmount: number;
  TotalAmount: number;
  Approved: boolean;
  ErrorCode?: string;
  ErrorMessage?: string;
};

export type BasePusherNotification = {
  LspId: number;
  LocId: number;
  ShipmentId: number;
};

export type PaymentResultPusherNotification = BaseElectronicPaymentResponse &
  BasePusherNotification & {
    PaymentProcessor: string;
    PaymentSuccess: boolean;
    RefreshCart: boolean;
    PrintUsagReceipt: string;
    Message: string;
  };

export type PaymentsHubResetCartDisplay = {
  RegisterId: number;
};

export type PaymentsHubProductUpsellPusherNotification = BasePusherNotification & {
  ProductId: number;
  ProductName: string;
  ProductPrice: number;
};

export type PaymentsHubProductUpsell = {
  ShipmentId: number;
  ProductName: string;
  Product: ProductSearchResult;
};
