import React from 'react';
import { ConnectionType, Peripheral } from '@dutchie/capacitor-hardware';

import { isPrintNodeLabelPrinter } from 'hardware/label-printer';
import { isPrintNodeReceiptPrinter } from 'hardware/receipt-printer';
import { PrintNodePrinterTypes } from 'models/Misc';
import { BluetoothIcon } from 'assets/icons/settings/PeripheralIcons/BluetoothIcon';
import { GlobeIcon } from 'assets/icons/settings/PeripheralIcons/GlobeIcon';
import { USBIcon } from 'assets/icons/settings/PeripheralIcons/USBIcon';

import type { SettingsSelectOption } from './SettingsSelect';

type DeviceSettingsSelectOption = SettingsSelectOption & {
  connectionType: ConnectionType;
};

// Helpers to create the list options

const getDeviceIcon = ({ connectionType }: Peripheral) => {
  switch (connectionType) {
    case ConnectionType.bluetooth:
      return <BluetoothIcon />;
    case ConnectionType.lan:
      return <GlobeIcon />;
    case ConnectionType.hid:
    case ConnectionType.usb:
    case ConnectionType.serial:
      return <USBIcon />;
    default:
      return null;
  }
};

/** Can remove once pos.register.improved-settings-ui is wide and HW lib and iOS are updated */
const getPrimaryLabel = ({ connectionType, metadata, name }: Peripheral) => {
  let label = name;
  // Replace 'Keyboard' with 'Default' for keyboard devices
  if (connectionType === ConnectionType.keyboard) {
    label = label.replace('Keyboard', 'Default');
  }
  // Remove the address from the label if it's already included in secondaryLabel
  if (!metadata.model && metadata.address && label.includes(metadata.address)) {
    label = label.replace(` - ${metadata.address}`, '');
  }
  return label;
};

const getSecondaryLabel = (peripheral: Peripheral) => {
  // PrintNode Receipt Printers have a special description
  if (isPrintNodeReceiptPrinter(peripheral)) {
    let receiptTypeDescription = '';
    switch (peripheral.printNodeType) {
      case PrintNodePrinterTypes.EPSON:
        receiptTypeDescription = 'Espon';
        break;
      case PrintNodePrinterTypes.STAR_GRAPHIC:
        receiptTypeDescription = 'Star Graphic';
        break;
      case PrintNodePrinterTypes.STAR_PRINT:
        receiptTypeDescription = 'Star';
        break;
      case PrintNodePrinterTypes.PDF:
        receiptTypeDescription = 'PDF';
        break;
      default:
        receiptTypeDescription = 'receipt';
    }
    return `Backoffice ${receiptTypeDescription} printer`;
  }

  // PrintNode Label Printers have a special description
  if (isPrintNodeLabelPrinter(peripheral)) {
    const typeDescription = peripheral.printNodeType === PrintNodePrinterTypes.PDF ? 'PDF' : 'label';
    return `Backoffice ${typeDescription} printer`;
  }

  const { connectionType, info, metadata, name } = peripheral;

  let connectionTypeDescription = 'Default';
  switch (connectionType) {
    case ConnectionType.bluetooth:
      connectionTypeDescription = 'Bluetooth';
      break;
    case ConnectionType.usb:
      connectionTypeDescription = 'USB';
      break;
    case ConnectionType.hid:
      connectionTypeDescription = 'HID';
      break;
    case ConnectionType.lan:
      connectionTypeDescription = 'Network';
      break;
    case ConnectionType.serial:
      connectionTypeDescription = 'Serial';
      break;
  }

  if (metadata.model && metadata.model !== name) {
    return metadata.model;
  } else if (metadata.serialNumber) {
    return metadata.serialNumber;
  } else if (metadata.address) {
    return metadata.address;
  } else if (connectionType === ConnectionType.keyboard) {
    return `${info.type} with limited features`;
  } else {
    // Build a description of the device including its connection type
    return `${connectionTypeDescription} ${info.type}`;
  }
};

const peripheralToSettingsOption = (device: Peripheral): DeviceSettingsSelectOption => ({
  connectionType: device.connectionType,
  icon: getDeviceIcon(device),
  label: getPrimaryLabel(device),
  secondaryLabel: getSecondaryLabel(device),
  value: device.id,
});

// Helpers to sort the device list options

const getConnectionTypePriority = ({ connectionType }: DeviceSettingsSelectOption) => {
  switch (connectionType) {
    case ConnectionType.keyboard:
      return 1;
    case ConnectionType.bluetooth:
      return 2;
    case ConnectionType.hid:
    case ConnectionType.serial:
    case ConnectionType.usb:
      return 3;
    case ConnectionType.lan:
      return 4;
    default:
      return 5; // Other types
  }
};

const getSecondaryLabelPriority = ({ secondaryLabel }: DeviceSettingsSelectOption) => {
  const isBackofficeDevice = secondaryLabel?.includes('Backoffice') ?? false;
  return isBackofficeDevice ? 2 : 1;
};

export const getSortedDeviceOptions = (devices: Peripheral[]): SettingsSelectOption[] => {
  const unsortedDeviceOptions: DeviceSettingsSelectOption[] = devices.map(peripheralToSettingsOption);

  const sortDeviceOptions = (a: DeviceSettingsSelectOption, b: DeviceSettingsSelectOption) => {
    if (a.connectionType === b.connectionType) {
      if (a.connectionType === ConnectionType.lan) {
        // Sort all the Backoffice devices at the bottom
        if (getSecondaryLabelPriority(a) === getSecondaryLabelPriority(b)) {
          return a.label.localeCompare(b.label);
        } else {
          return getSecondaryLabelPriority(a) - getSecondaryLabelPriority(b);
        }
      } else {
        // If connection type is the same, sort by label
        return a.label.localeCompare(b.label);
      }
    } else {
      // Sort by connection type
      return getConnectionTypePriority(a) - getConnectionTypePriority(b);
    }
  };

  return unsortedDeviceOptions.sort(sortDeviceOptions);
};
