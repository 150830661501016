import React, { FC } from 'react';
import styled from 'styled-components';

import { colors } from 'css/Theme';

export const variations = {
  green: {
    textColor: colors.dutchie.green80,
    backgroundColor: colors.dutchie.green20,
  },
  red: {
    textColor: colors.dutchie.flora,
    backgroundColor: colors.red10,
  },
  peach: {
    textColor: colors.dutchie.brown,
    backgroundColor: colors.dutchie.peach,
  },
  opal: {
    textColor: colors.dutchie.opal90,
    backgroundColor: colors.dutchie.opal20,
  },
  grey: {
    textColor: colors.dutchie.gray90,
    backgroundColor: colors.dutchie.gray20,
  },
  blue: {
    textColor: colors.dutchie.bluePillText,
    backgroundColor: colors.dutchie.bluePill,
  },
};

export const getVariationForCustomerStatus = (status: string): keyof typeof variations => {
  switch (status) {
    case 'Active':
      return 'green';
    case 'Banned':
      return 'red';
    case 'Hold':
      return 'peach';
    case 'Cancelled':
      return 'opal';
    case 'Inactive':
    default:
      return 'grey';
  }
};

type PillPropsBase = {
  icon?: React.ReactNode;
  text: string;
  automationId?: string;
};

type PillPropsWithVariation = PillPropsBase & {
  variation: keyof typeof variations;
  textColor?: never;
  backgroundColor?: never;
};

type PillPropsWithColors = PillPropsBase & {
  variation?: never;
  textColor: string;
  backgroundColor: string;
};

type PillProps = PillPropsWithVariation | PillPropsWithColors;

export const Pill: FC<PillProps> = ({ icon, text, variation, automationId, textColor, backgroundColor }) => {
  return (
    <PillContainer
      textColor={textColor ?? variations[variation].textColor}
      backgroundColor={backgroundColor ?? variations[variation].backgroundColor}
      data-testid={automationId}
    >
      {icon && <IconContainer>{icon}</IconContainer>}
      {text}
    </PillContainer>
  );
};

const IconContainer = styled.span`
  margin-right: .75rem;
  width: 11px;
  height: 11px;
`;

const PillContainer = styled.div<{ textColor: string; backgroundColor: string }>`
  color: ${(props) => props.textColor};
  display: flex;
  flex-direction: row;
  font-size: 11px;
  padding: 6px 12px;
  font-weight: 600;
  line-height: 12px;
  border-radius: 20px;
  background-color: ${(props) => props.backgroundColor};
  letter-spacing: 0.005em;
  width: fit-content;
`;
