import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { customEventKeys, logger } from 'util/logger';
import { SearchBar } from 'components/layout';
import { setGuestFilterQuery, setGuestSearchQuery } from 'store/actions/GuestListActions';
import { useDebounce } from 'util/hooks';
import { useResponsiveGuestListActionsFlag } from 'util/hooks/launch-darkly/useResponsiveGuestListActionsFlag';

import type { State } from 'store';

export const GuestSearch: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const showSearch = useSelector((state: State) => state.settings.userSettings.showSearch);
  const [searchQuery, setSearchQuery] = useState('');

  const debouncedQuery = useDebounce(searchQuery, 500);
  const isResponsiveGuestListActionsEnabled = useResponsiveGuestListActionsFlag();

  useEffect(() => {
    dispatch(setGuestFilterQuery(debouncedQuery));
  }, [debouncedQuery, dispatch]);

  if (!showSearch) {
    return null;
  }

  const handleSearch = (value: string) => {
    logger.info(`guest list search for "${value}"`, {
      key: customEventKeys.guestlist.search,
      query: value,
    });
    dispatch(setGuestSearchQuery(value));
    history.push('/customers');
  };

  const handleChange = (value: string) => {
    setSearchQuery(value);
  };

  return (
    <SearchBar
      inputAutomationId='action-bar_search-bar_text-input'
      onSearch={handleSearch}
      placeholder='Find guest...'
      onChange={handleChange}
      inputId='guestListSearchBar'
      maxWidth={isResponsiveGuestListActionsEnabled ? '100%' : undefined}
    />
  );
};
