import React from 'react';
import styled, { css } from 'styled-components';

import { AnonymousGuestButton } from './AnonymousGuestButton';
import { ChangeViewButton } from './ChangeViewButton';
import { CheckinButton } from './CheckinButton';
import { CreateGuestButton } from './CreateGuestButton';
import { FilterButton } from './FilterButton';
import { GuestSearch } from './GuestSearch';
import { RefreshButton } from './RefreshButton';
import { ResetFilterButton } from './ResetFilterButton';
import { SortDownButton } from './SortDownButton';
import { Spacer, Toolbar } from 'components/layout';
import { useResponsiveGuestListActionsFlag } from 'util/hooks/launch-darkly/useResponsiveGuestListActionsFlag';

import { breakpoints } from 'css/Theme';

export type ActionBarProps = {
  onSelectFilters: () => unknown;
  onResetFilters: () => unknown;
  onToggleListMode: (mode: string) => void;
  activeFilterCount: number;
  isListMode: boolean;
};

export const ActionBar: React.FC<ActionBarProps> = React.memo(function ActionBar({
  onSelectFilters,
  onResetFilters,
  onToggleListMode,
  isListMode,
  activeFilterCount,
}) {
  const isResponsiveGuestListActionsEnabled = useResponsiveGuestListActionsFlag();

  if (isResponsiveGuestListActionsEnabled)  {
    return (
      <StyledToolbar isResponsiveGuestListActionsEnabled={isResponsiveGuestListActionsEnabled}>
        <SearchWrapper>
          <GuestSearch />
        </SearchWrapper>
        <ActionWrapper>
          <CheckinButton />
          <CreateGuestButton />
          <AnonymousGuestButton />
        </ActionWrapper>
        <FilterWrapper>
          <RefreshButton />
          <FilterButton onSelectFilters={onSelectFilters} />
          <SortDownButton />
          <ResetFilterButton activeFilterCount={activeFilterCount} onResetFilters={onResetFilters} />
          <ChangeViewButton onToggleListMode={onToggleListMode} isListMode={isListMode} />
        </FilterWrapper>
      </StyledToolbar>
    );
  }

  return (
    <StyledToolbar>
      <Wrapper>
        <GuestSearch />
        <CheckinButton />
        <CreateGuestButton />
        <AnonymousGuestButton />
      </Wrapper>
      <Spacer />
      <Wrapper>
        <RefreshButton />
        <FilterButton onSelectFilters={onSelectFilters} />
        <SortDownButton />
        <ResetFilterButton activeFilterCount={activeFilterCount} onResetFilters={onResetFilters} />
        <ChangeViewButton onToggleListMode={onToggleListMode} isListMode={isListMode} />
      </Wrapper>
    </StyledToolbar>
  );
});

const StyledToolbar = styled(Toolbar)<{ isResponsiveGuestListActionsEnabled?: boolean }>`
  ${({ isResponsiveGuestListActionsEnabled }) => isResponsiveGuestListActionsEnabled && css`  
    align-items: center;
    display: flex;
    width: 100%;
    flex-flow: wrap;
    gap: 12px;
  `};
  
  padding: 1.5rem;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const SearchWrapper = styled.div`
  display: flex;
  gap: 12px;
  margin: 0 !important;

  ${breakpoints.wideTablet.maxQuery} {
    width: 100%;
  }

  ${breakpoints.tallTablet.maxQuery} {
    width: unset;
    flex-grow: 1;
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  gap: 12px;
  margin: 0 !important;

  ${breakpoints.tallTablet.maxQuery} {
    order: 3;
    width: 100%;

    a, button {
      flex-grow: 1;
      flex-basis: 0;
    }
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  gap: 12px;
  margin-left: auto !important;

  ${breakpoints.tallTablet.maxQuery} {
    margin-left: 0;
    order: 2;
  }
`;
