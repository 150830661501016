import { ExistingPaymentMethod } from './Checkout';
import { AppliedDiscount } from './Discounts';
import { AppliedFeeDonation, PaymentTypeFee } from './FeeDonation';

import type { PrescriptionData } from './MCDMS';
import type { ProductSearchResult } from 'queries/v2/product/types';

/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export type CartItem = {
  AllowSaleToMinor?: number;
  AutoPrintLabel?: number;
  BatchId?: number;
  BrandId?: number;
  BrandName?: string;
  CannbisProduct?: string;
  Customer?: number;
  DaysSupply?: number;
  DaysSupplyPerItem?: number;
  DefaultLabelId?: number;
  DefaultUnitId?: number;
  DiscountAmt?: number;
  Equivalent?: number;
  Grams?: number;
  Id: number;
  Instructions?: string;
  InventoryId?: number;
  RowInventoryIds?: number[];
  LoyaltyAmt?: number;
  MMURDeviceId?: number;
  MMURFormId?: number;
  MMUROrderId?: number;
  Multiplier?: number;
  OrderDate?: number;
  OrderNo: string;
  PackageItemId?: number;
  PricedQuantity?: number;
  Product: string;
  ProductCategory?: string;
  ProductId: number;
  QtyAllocated: number;
  QtySelected: number;
  SerialNo: string;
  Taxable?: number;
  TaxAmt?: number;
  TotalCost: number;
  WgtCnt: 'Wgt' | 'Qty';
  PackageItemSerialNumber?: string;
};

export const isCartItem = (variableToCheck: CartItem | Preorder): variableToCheck is CartItem =>
  (variableToCheck as CartItem).WgtCnt !== undefined;

export const isCartItemOrProductSearchResult = (item: CartItem | ProductSearchResult): item is CartItem =>
  (item as CartItem).WgtCnt !== undefined;

/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export type Preorder = {
  Id: number;
  OrderId: number;
  OrderItemId: number;
  BrandId?: number;
  BrandName?: string;
  Grower: string;
  Location: string;
  Strain: string;
  Product: string;
  Category: string;
  InventoryDate: Date;
  SerialNo: number;
  Grams: number;
  UnitId: number;
  UnitName: number;
  Abbreviation: string;
  TotalCost: number;
  OrderNo: string;
  OrderDate: Date;
  OrderStatus: string;
  Customer: string;
  QtySelected: number;
  ProductId: number;
  ProductImageURL?: string;
  PackageItemSerialNumber?: string;
};

export type PreOrdersWithPackageIds = Preorder & {
  PackageIds: Array<string>;
};

//type guard to check if it's a CartItem or a ProductSearch Result
export const isPreOrdersWithPackageIds = (
  item: CartItem | Preorder | PreOrdersWithPackageIds
): item is PreOrdersWithPackageIds => (item as PreOrdersWithPackageIds).PackageIds !== undefined;

/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export type PreOrderRedemption = {
  AlpineId: string;
  SpringBigId: string;
  FylloId: number;
  SpringBigType: string;
  LeafLogixCode: string;
  ProductId?: number;
  RedemptionDescription: string;
  OrderRedemptionId: number;
  OrderId: number;
  IsRedeemed: boolean;
  CallbackUrl: string;
};

/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export type Allotment = {
  TotalRemaining: number;
  ValidFrom?: Date;
  ValidUntil?: Date;
  TotalInCart: number;
  TotalLimit: number;
  TotalUsed: number;
  MaxLimit: number;
  CurrentLimit: number;
  Error: boolean;
  ErrorMessage?: string;
  Warning: boolean;
  WarningMessage?: string;
  Details: Array<AllotmentCategory>;
};

/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export type AllotmentCategory = {
  Category: string;
  CustomerTypeId: number;
  InCart: number;
  Limit: number;
  Used: number;
  UnitId?: number;
  OrderId?: number;
  OrderType?: string;
  AllowDeviceDispensation?: boolean;
};

/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export type Loyalty = {
  TransactionId: number;
  AppliedLoyaltyPoints: number;
  AppliedLoyaltyFromDiscounts: number;
};

// this type looks dead - someone please delete this when time permits
/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export type Payment = {
  ElectronicType: string;
  ElectronicAmount: number;
};

/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export type CartTotal = {
  Cart: Array<CartItem>;
  Discounts: Array<AppliedDiscount>;
  Loyalty?: Loyalty;
  FeesDonations: Array<AppliedFeeDonation>;
  SubTotal: number;
  TotalDiscountAndLoyalty: number;
  Tax: number;
  FeesAndDonations: number;
  GrandTotal: number;
  GrandTotalRounded: number;
  TotalDiscount: number;
  RoundingAmount: number;
  Payment: Payment;
  TipAmount?: number;
};

/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export type Cart = {
  Allotment: Allotment;
  Cart: Array<CartItem>;
  Deliverable: boolean;
  Discounts: Array<AppliedDiscount>;
  ExistingPayments: Array<ExistingPaymentMethod>;
  /** The dollar value of all fees and donations applied to a cart */
  FeesAndDonations: number;
  /** List of all fees that have been applied to a cart */
  FeesDonations: Array<AppliedFeeDonation>;
  /** Reference list of all fees that could be applied to cart.  Enables display of available fees and associated values in UI */
  FeesByPaymentType: Array<PaymentTypeFee>;
  GrandTotal: number;
  GrandTotalRounded: number;
  Locked: boolean;
  Loyalty?: Loyalty;
  Payment: Payment;
  PrepaymentPayInfo?: Array<PrepaymentPayInfo>;
  PreOrderRedemptions: Array<PreOrderRedemption>;
  PreauthInfo: PreauthInfo;
  PreOrders: Array<Preorder>;
  PrescriptionData?: PrescriptionData;
  RoundingAmount: number;
  SubTotal: number;
  Tax: number;
  TipAmount?: number;
  TotalDiscount: number;
  TotalDiscountAndLoyalty: number;
  TotalItems: number;
  VerifiedBy?: number;
  ShipmentId: number;
  ScheduleId: string;
  TransactionReference: string;
  TransactionStatus: string;
  CustomerId: number;
  CurrentRoom: string;
  CurrentRegister: number;
};

/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export type CalcCartRequest = {
  ShipmentId?: number;
  Timestamp: number;
  AcctId?: number;
  Register?: number;
  IsCheckout?: boolean;
  UpdateCache?: boolean;
};

export type SaveVisitReasonRequest = {
  ShipmentId?: number;
  Reason?: string;
};

export type CustomerVisitReason = {
  VisitReason?: string;
  ReasonId?: number;
};

/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export type LoadCartRequest = {
  /** This is actually a require prop. Fixed in React Query implementation */
  ShipmentId?: number;
  Timestamp: number;
  /** This is actually a require prop. Fixed in React Query implementation */
  AcctId?: number;
  Register?: number;
  /** @deprecated No longer used */
  UpdateCache?: boolean;
  FeePaymentMethodIds?: number[];
};

export type ShipmentIdRequest = {
  ShipmentId: number;
};

export type ClearCartRequest = {
  ShipmentId: number;
};

export type ApplyLoyaltyPointsRequest = {
  UserId: number;
  TransactionId: number;
  GuestId: number;
  LoyaltyAmt: number;
  Register: number;
  LoyaltyAsDiscount: boolean;
};

export type RemovePreorderCartItemRequest = {
  ProdId: number;
  AcctId: number;
};

export type EditCartItemRequest = {
  ProductId: number;
  SerialNo: string;
  Instructions: string;
  UnitPrice: number;
  DiscountAmt: number;
  DaysSupply?: number;
  ShipmentId: number;
};

export type ReturnPaymentOnlyRequest = {
  ShipmentId: number;
  RegisterId: number;
};

export type ReturnItemRequest = {
  PinUserId?: number;
  CashRet: string;
  PutBackInInventory: string;
  RegisterId: number;
  ReturnReason?: string;
  InventoryId: number;
  ShipmentId: string;
  ReactVersion?: string;
  CashRefundAmount?: number;
  CreditRefundAmount?: number;
  DebitRefundAmount?: number;
  LoyaltySpentRefundAmount?: number;
  LoyaltyAccruedRefundAmount?: number;
};

export type ExpiredItemInfo = {
  SerialNumber: string;
};

export type ScanItem = {
  SerialNumber: string;
  CustomerId: number;
  Register: number;
};

export type ScanResponse = {
  InvId: number;
  ProductId: number;
  ProductDesc: string;
  ProductNo: string;
  TotalAvailable: number;
  TotalGrams?: number;
  TotalOz?: number;
  UnitPrice: number;
  RecUnitPrice: number;
  SerialNo: string;
  ProductType?: string;
  CannabisInventory: string;
  ProductCategory?: string;
  UnitGrams: number;
  Coupon?: string;
  MasterCategory?: string;
  Strain?: string;
  ProductImageURL?: string;
  Description?: string;
  DefaultLabelId?: number;
  ChargeCodeId?: number;
  AutoPrintLabel?: number;
  AllowSaleToMinor?: string;
  PackageItemId?: number;
  ItemLabelId?: number;
  DefaultUnitId: number;
};

export const isScanResponse = (item: ScanResponse | ProductSearchResult): item is ScanResponse =>
  (item as ScanResponse).SerialNo !== undefined;

export const convertScanResponseToProductSearchResult = (scanResponse: ScanResponse): ProductSearchResult => {
  return {
    allowSaleToMinor: scanResponse.AllowSaleToMinor === 'yes',
    autoPrintLabel: scanResponse.AutoPrintLabel === 1,
    batchId: undefined,
    brandName: undefined,
    cannabisInventory: scanResponse.CannabisInventory,
    cbdContent: undefined,
    cbdContentUnitId: undefined,
    chargeCodeId: scanResponse.ChargeCodeId ?? 0,
    coupon: scanResponse.Coupon ?? 'no',
    defaultLabelId: scanResponse.DefaultLabelId,
    defaultUnitId: scanResponse.DefaultUnitId,
    description: scanResponse.Description ?? '',
    invId: scanResponse.InvId,
    itemLabelId: scanResponse.ItemLabelId,
    masterCategory: scanResponse.MasterCategory ?? '',
    packageItemId: scanResponse.PackageItemId,
    packageItemSerialNumber: undefined,
    productCategory: scanResponse.ProductCategory ?? '',
    productDescription: scanResponse.ProductDesc,
    productId: scanResponse.ProductId,
    productImageURL: scanResponse.ProductImageURL ?? '',
    productNo: scanResponse.ProductNo,
    productType: scanResponse.ProductType === 'Wgt' ? 'Wgt' : 'Qty',
    receivedDate: undefined,
    recUnitPrice: scanResponse.RecUnitPrice,
    serialNo: scanResponse.SerialNo,
    strain: scanResponse.Strain ?? '',
    thcContent: undefined,
    thcContentUnitId: undefined,
    totalAvailable: scanResponse.TotalAvailable,
    totalGrams: scanResponse.TotalGrams ?? 0,
    totalOz: scanResponse.TotalOz ?? 0,
    unitGrams: scanResponse.UnitGrams,
    unitPrice: scanResponse.UnitPrice,
    useByDate: undefined,
    vendor: undefined,
  };
};

export type BackdateTransaction = {
  ShipmentId: number;
  ActualDate: string;
};

export type GetCartDisplayRequest = {
  Register: number;
};

export type ClearCartDisplayRequest = {
  Register: number;
};

export type DeliveryTypeEnum = 'Delivery' | 'Pickup';

export type CompletePreOrder = {
  AcctId: number;
  DeliveryType: string;
  DeliveryNotes: string;
  RequestedDeliveryDate: string;
};

export type RemoveRedemptionFromPreOrder = {
  OrderRedemptionId: number;
  OrderId: number;
};

export type MarkRedemptionRedeemed = {
  OrderRedemptionId: number;
  OrderId: number;
};

/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export type PrepaymentPayInfo = {
  Amount: number;
  OrderId: number;
  ShipmentId: number;
  PrepaidOn?: Date;
  PrepaymentId: string;
  PrepaymentType: string;
};

/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export type PreauthInfo = {
  PreauthAmount: number;
  FinalAmount?: number;
  OrderId: number;
  ShipmentId: number;
  PreauthId: string;
  PaymentType: string;
  PreauthDate?: Date;
  Currency: string;
};

export type ReturnItemNotification = {
  Success: boolean;
  LspId: number;
  LocId: number;
  ShipmentId: number;
  InventoryId: number;
  ReturnShipmentId?: number;
  Message?: string;
};

export type ReturnItemPollingRequest = {
  InventoryId: number;
  LspId: string;
  LocId: string;
};

export type ReturnItemPollingResponse = {
  IsFinal: boolean;
  ReturnItemNotification: ReturnItemNotification;
};

export type FundWalletRequest = {
  phoneNumber: string;
};
