import Pusher, * as PusherTypes from 'pusher-js';
import { ChannelAuthorizationRequestParams } from 'pusher-js/types/src/core/auth/options';
import { useEffect, useState } from 'react';
import { useQueryClient, useQuery, useMutation, UseQueryOptions, QueryKey } from '@tanstack/react-query';
import { authPusherChannel, getPusherChannelConfig } from 'api/PusherApi';
import {
  PusherChannelType,
  PusherAuthRequest,
  PusherChannelConfiguration,
  PusherConnectionStates,
} from 'models/Pusher';

export type PusherChannelProps = {
  enabled: boolean;
  channelType: PusherChannelType;
  onChannelMessage: (eventName: string, data: string) => void;
  onConnected?: () => void;
  onDisconnected?: () => void;
};
export type UsePusherChannelHook = {
  pusherChannel: PusherTypes.Channel;
  pusherClient: Pusher;
  channelConfig: PusherChannelConfiguration;
};

export type UsePusherQueryOptions = Omit<
  UseQueryOptions<PusherChannelConfiguration, Error, PusherChannelConfiguration, QueryKey>,
  'queryKey' | 'queryFn'
>;

// React-Query data fetching hooks
export function usePusherChannelConfigQuery(channelType: PusherChannelType, options: UsePusherQueryOptions) {
  return useQuery<PusherChannelConfiguration, Error>(
    ['pusher', 'config', channelType],
    () => getPusherChannelConfig(channelType),
    options
  );
}

export function usePusherAuthMutation(channelType: PusherChannelType) {
  return useMutation({
    mutationFn: (body: PusherAuthRequest) => authPusherChannel(channelType, body),
    mutationKey: ['pusher', 'auth', channelType],
  });
}

// Core feature that listens for messages on the requested channel
export function usePusherChannel({
  channelType,
  enabled,
  onChannelMessage,
  onConnected,
  onDisconnected,
}: PusherChannelProps) {
  const queryClient = useQueryClient();
  const { pusherClient, channelConfig } = usePusherClient({ channelType, enabled, onConnected, onDisconnected });
  const [pusherChannel, setPusherChannel] = useState<PusherTypes.Channel>();

  useEffect(() => {
    if (!enabled) {
      return;
    }
    if (!pusherClient || !channelConfig) {
      return;
    }
    const channel = pusherClient.subscribe(channelConfig.ChannelName);

    if (onChannelMessage) {
      channel.bind_global(onChannelMessage);
    }

    setPusherChannel(channel);
    return () => {
      channel.unbind();
      pusherClient.unsubscribe(channelConfig.ChannelName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pusherClient, enabled, queryClient, channelConfig]);
  return {
    pusherChannel,
    pusherClient,
    channelConfig,
  };
}

// Custom channel auth handler
export function useCustomChannelAuth(channelType: PusherChannelType) {
  const { mutateAsync: authenticatePusher } = usePusherAuthMutation(channelType);
  return async (params: ChannelAuthorizationRequestParams, callback: PusherTypes.AuthorizerCallback) => {
    const body: PusherAuthRequest = {
      SocketId: params.socketId,
    };
    const response = await authenticatePusher(body);
    if (response?.auth) {
      callback(null, { auth: response.auth });
    } else {
      callback(new Error('Pusher auth failed'), null);
    }
  };
}

// Generates the pusher client
export function createPusherClient(
  channelConfig: PusherChannelConfiguration,
  channelType: PusherChannelType,
  customChannelAuthHandler: PusherTypes.ChannelAuthorizationHandler
) {
  return new Pusher(channelConfig?.PusherKey, {
    cluster: channelConfig?.PusherCluster,
    channelAuthorization: {
      customHandler: customChannelAuthHandler,
      transport: 'jsonp',
      endpoint: `/api/pusher/auth/${channelType}`,
    },
  });
}

// Creates the Pusher client with the custom channel auth handler and lifecycle hooks
export function usePusherClient({
  channelType,
  enabled,
  onConnected,
  onDisconnected,
}: Omit<PusherChannelProps, 'onChannelMessage'>) {
  const { data: channelConfig } = usePusherChannelConfigQuery(channelType, {
    enabled,
  });
  const customChannelAuthHandler = useCustomChannelAuth(channelType);
  const [pusherClient, setPusherClient] = useState<Pusher>();

  const handleStateChange = (states: PusherConnectionStates) => {
    if (states.current === 'connected') {
      onConnected?.();
    } else {
      onDisconnected?.();
    }
  };

  useEffect(() => {
    if (!enabled || !channelConfig || !channelType) {
      return;
    }
    const pusher = createPusherClient(channelConfig, channelType, customChannelAuthHandler);
    pusher.connection.bind('state_change', handleStateChange);
    setPusherClient(pusher);

    return () => {
      pusher.disconnect();
      pusher.unbind('state_change', handleStateChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelConfig, channelType, enabled]);

  return {
    pusherClient,
    channelConfig,
  };
}
