import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button } from 'components/buttons';
import { isHidScanner, useScanners, Scanner } from '@dutchie/capacitor-hardware';
import { SimpleModal } from 'components/modals/SimpleModal';
import { AuthorizeStep } from './AuthorizeStep';
import { ConfigureStep } from './ConfigureStep';
import { ConnectStep } from './ConnectStep';
import { StepBar } from './StepBar';
import { ScannerTestStep } from './ScannerTestStep';
import { ChevronIcon } from 'assets/icons/settings/ChevronIcon';
import { useHardwareServices } from '../../../hooks/useHardwareServices';
import { useScannerSettings } from '../../hooks/useScannerSettings';
import { ModalVariation } from 'components/modals/Modal';

enum Step {
  connect,
  reset,
  authorize,
  configure,
  test,
}

export const SetupWizardModal = ({ hide }: { hide: () => void }) => {
  const { selectScanner } = useScannerSettings();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [testedScanner, setTestedScanner] = useState<Scanner | undefined>(undefined);
  const { scanners } = useScanners();
  const { isWebHidSupported } = useHardwareServices();

  const authorizedScanners = scanners.filter(isHidScanner);
  const hasAuthorizedScanner = authorizedScanners.length > 0;

  const steps = [Step.connect, Step.configure, ...(isWebHidSupported ? [Step.authorize] : []), Step.test];

  const stepNames = steps.map((it) => {
    switch (it) {
      case Step.authorize:
        return 'Authorize';
      case Step.configure:
        return 'Configure';
      case Step.connect:
        return 'Connect';
      case Step.reset:
        return 'Reset';
      case Step.test:
        return 'Test';
      default:
        return '';
    }
  });

  const authorizeIndex = steps.indexOf(Step.authorize);
  const hasAuthorizeStep = authorizeIndex !== -1;

  const step = steps[currentIndex];
  const isFirstStep = currentIndex === 0;
  const isLastStep = currentIndex === steps.length - 1;
  const isNextEnabled =
    step === Step.test ? testedScanner : !hasAuthorizeStep || currentIndex < authorizeIndex || hasAuthorizedScanner;

  const back = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      return;
    }
  };

  const next = () => {
    if (currentIndex >= steps.length - 1) {
      hide();
      return;
    }

    setCurrentIndex(currentIndex + 1);
  };

  const setScannerAndClose = () => {
    if (testedScanner) {
      selectScanner(testedScanner.id, true);
    }

    hide();
  };

  useEffect(() => {
    if (step === Step.test) {
      setTestedScanner(undefined);
    }
  }, [currentIndex, step]);

  return (
    <SimpleModal
      modalName='scanner-setup'
      header='Barcode scanner setup'
      variation={ModalVariation.SettingModal}
      hide={hide}
      footer={
        <>
          {isFirstStep && (
            <Button cta tertiary onClick={hide}>
              Cancel
            </Button>
          )}
          {!isFirstStep && (
            <Button cta tertiary onClick={back}>
              Back
            </Button>
          )}
          {!isLastStep && (
            <ButtonWithIcon cta onClick={next} disabled={!isNextEnabled}>
              Next <ChevronIcon size={16} />
            </ButtonWithIcon>
          )}
          {isLastStep && (
            <Button cta onClick={setScannerAndClose} disabled={!isNextEnabled}>
              Complete setup
            </Button>
          )}
        </>
      }
    >
      <StepBar currentIndex={currentIndex} steps={stepNames} />
      <Content>
        {step === Step.authorize && <AuthorizeStep />}
        {step === Step.configure && <ConfigureStep />}
        {step === Step.connect && <ConnectStep />}
        {step === Step.test && <ScannerTestStep onSuccess={(scanner) => setTestedScanner(scanner)} />}
      </Content>
    </SimpleModal>
  );
};

const Content = styled.div`
  padding: 24px 32px;
`;

const ButtonWithIcon = styled(Button)`
  display: flex;
  gap: 0.5rem;
`;
