import React from 'react';
import styled from 'styled-components';
import { Row } from 'components/layout';
import { colors } from 'css/Theme';
import { ReactComponent as Check } from 'assets/icons/checkmark-icon.svg';
import { ChevronIcon } from 'assets/icons/settings/ChevronIcon';

export const StepBar = ({ currentIndex, steps }: { currentIndex: number; steps: string[] }) => {
  return (
    <StyledBarContainer>
      <StyledBarInnerRow>
        {steps.map((step, idx) => {
          const isCurrent = idx === currentIndex;
          const isComplete = currentIndex > idx;
          const isLastStep = idx === steps.length - 1;

          return (
            <React.Fragment key={`step_${idx}`}>
              <Row gap={8}>
                <CompletedTabStatus completed={isComplete} disabled={idx > currentIndex}>
                  {isComplete && <Check />}
                  {!isComplete && idx + 1}
                </CompletedTabStatus>
                <StepText current={isCurrent}>{step}</StepText>
              </Row>
              {!isLastStep && <ChevronIcon color={colors.dutchie.gray40} size={16} />}
            </React.Fragment>
          );
        })}
      </StyledBarInnerRow>
    </StyledBarContainer>
  );
};

const CompletedTabStatus = styled.div<{ completed: boolean; disabled: boolean }>`
  align-items: center;
  background-color: ${(props) =>
    props.completed ? colors.dutchie.green : props.disabled ? colors.dutchie.gray40 : colors.dutchie.blue};
  border-radius: 50%;
  color: ${colors.white};
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 24px;
  justify-content: center;
  line-height: 24px;
  width: 24px;
`;

const StepText = styled.span<{ current: boolean }>`
  color: ${(props) => (props.current ? colors.dutchie.black : colors.dutchie.gray70)};
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
`;

const StyledBarContainer = styled(Row)`
  align-items: center;
  border-bottom: 1px solid ${colors.dutchie.gray30};
  border-top: 1px solid ${colors.dutchie.gray30};
  justify-content: center;
  padding: 16px 20px;
`;

const StyledBarInnerRow = styled(Row)`
  align-items: center;
  gap: 16px;
`;
