import React, { useCallback, useMemo } from 'react';

import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { SettingsCard } from 'pages/SettingsPage/SettingsCard';
import { SettingsDivider } from 'pages/SettingsPage/SettingsDivider';
import { SaveSettingsFooter } from '../../SaveSettingsFooter';
import { ScaleIcon } from 'assets/icons/settings/ScaleIcon';
import { ScaleSettingsProvider } from './components/ScaleSettingsProvider';
import { useHardwareSettingsDrawer } from '../hooks/useHardwareSettingsDrawer';
import { ScaleTestListItem } from './components/ScaleTestListItem';
import { ScaleStatusBanner } from './components/ScaleStatusBanner';
import { ScaleStatusPill } from './components/ScaleStatusPill';
import { ScaleSelectListItem } from './components/ScaleSelectListItem';
import { SettingsBottomDrawerForm } from '../SettingsBottomDrawerForm';
import { PeripheralType, SerialScale, isSerialScale, useScales } from '@dutchie/capacitor-hardware';
import { ScaleConfigureSerialListItem } from './components/ScaleConfigureSerialListItem';
import { AddDeviceListItem } from '../components/AddDeviceListItem/AddDeviceListItem';

export const ScaleSettingsListItem = () => {
  const { showDrawer, hideDrawer, isDrawerOpen } = useHardwareSettingsDrawer();

  const { scales, search } = useScales();

  const hasSerialScales = useMemo(() => {
    return scales.filter(isSerialScale).length > 0;
  }, [scales]);

  const handleAuthorize = useCallback(() => {
    search({ requestNewDevices: true });
  }, [search]);

  /** @deprecated should only be sending the selected scale to configure */
  const serialScales = scales.filter((it) => isSerialScale(it)) as SerialScale[];

  return (
    <>
      <SettingsListItem
        automationId='scale_settings'
        icon={<ScaleIcon />}
        title='Scale'
        subtitle='Settings for a scale'
        onClick={showDrawer}
        actions={[<ScaleStatusPill />]}
      />
      <SettingsBottomDrawerForm
        automationId='scale_settings-drawer'
        title='Scale'
        open={isDrawerOpen}
        hide={hideDrawer}
      >
        <ScaleSettingsProvider>
          <SettingsCard footer={<SaveSettingsFooter />}>
            <ScaleStatusBanner />
            <ScaleSelectListItem />
            <SettingsDivider />
            <ScaleTestListItem />
            {serialScales.map((scale) => {
              // TODO: Have the ScaleConfigureSerialListItem look up the selected scale
              // instead of passing in the scale object of all serial scales
              return (
                <React.Fragment key={scale.id}>
                  <SettingsDivider />
                  <ScaleConfigureSerialListItem scale={scale} />
                </React.Fragment>
              );
            })}
          </SettingsCard>
          <AddDeviceListItem
            deviceType={PeripheralType.scale}
            isDeviceDetected={hasSerialScales}
            onAuthorize={handleAuthorize}
          />
        </ScaleSettingsProvider>
      </SettingsBottomDrawerForm>
    </>
  );
};
