import React, { useCallback, useMemo } from 'react';
import { HardwareService, isSerialScale, PeripheralType } from '@dutchie/capacitor-hardware';

import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { useScaleSettings } from '../hooks/useScaleSettings';
import { SettingsSelect, SettingsSelectOption } from 'pages/SettingsPage/Select/SettingsSelect';
import { useAddNewDevice } from '../../components/AddDeviceListItem/useAddNewDevice';
import { useHardwareServices } from '../../hooks/useHardwareServices';
import { useImprovedSettingsUI } from 'util/hooks/launch-darkly/useImprovedSettingsUI';

export const ScaleSelectListItem = () => {
  const { formValues, options, scales, search, selectScale } = useScaleSettings();
  const { isWebSerialSupported } = useHardwareServices();

  const hasSerialScales = useMemo(() => {
    return scales.filter(isSerialScale).length > 0;
  }, [scales]);

  const handleAuthorize = useCallback(() => {
    search({ requestNewDevices: true });
  }, [search]);

  const handleChange = (option?: SettingsSelectOption) => {
    selectScale(option?.value);
  };

  const handleRefresh = async () => {
    HardwareService.scale.filterEnabled = true;
    search({ requestNewDevices: true });
  };

  const handleShowAll = async () => {
    HardwareService.scale.filterEnabled = false;
    search({ requestNewDevices: true });
  };

  const { handleShowAddNewDeviceModal } = useAddNewDevice({
    deviceType: PeripheralType.scale,
    isDeviceDetected: hasSerialScales,
    onAuthorize: handleAuthorize,
  });

  const { isImprovedSettingsUIEnabled } = useImprovedSettingsUI();

  return (
    <SettingsListItem
      title={isImprovedSettingsUIEnabled ? 'Select scale' : 'Scale'}
      subtitle={isImprovedSettingsUIEnabled ? undefined : 'Select scale for all weight-based actions'}
      actions={[
        <SettingsSelect
          automationId='scale_settings-select'
          devices={scales}
          value={formValues.scaleId}
          placeholder='Select scale'
          onChange={handleChange}
          onRefresh={handleRefresh}
          onShowAll={isWebSerialSupported ? handleShowAll : undefined}
          options={options}
          secondaryAction={
            isWebSerialSupported ? { label: 'Add new scale', onClick: handleShowAddNewDeviceModal } : undefined
          }
        />,
      ]}
    />
  );
};
