import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { ActionButton, AddItemsButton } from 'components/cart/CartComponents.styles';
import { colors } from 'css/Theme';
import { Item } from 'pages/CreatePreOrderPage/PreCartSummary/Item';
import { ItemPrice, PreorderCartItem } from 'pages/CreatePreOrderPage';
import { OverflowMenu, OverflowMenuOption } from 'components/menus/OverflowMenu';
import { PreOrderInfoHeader } from './PreOrderInfoHeader';
import { ReactComponent as ProductScan } from 'assets/icons/add-item-large.svg';
import { ReactComponent as AddItemsIcon } from 'assets/icons/add-items-icon.svg';
import { ReactComponent as ElipsisIcon } from 'assets/icons/elipsis-icon.svg';
import { useCartPopups } from 'components/CartPopups';
import { useMaxContentScrollFixFlag } from 'util/hooks/launch-darkly/useMaxContentScrollFixFlag';

import type { State } from 'store';

type PreCartProps = {
  cartItems: PreorderCartItem[];
  setAddItemPanelIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCartItems: React.Dispatch<React.SetStateAction<PreorderCartItem[]>>;
  itemPrices: Record<number, ItemPrice>;
};

export const PreCartSummary = React.memo(PreCartSummaryImpl);

function PreCartSummaryImpl({ cartItems, setAddItemPanelIsOpen, setCartItems, itemPrices }: PreCartProps): JSX.Element {
  const history = useHistory();
  const guest = useSelector((state: State) => state.customer.details);
  const cartPopups = useCartPopups();

  const isMaxContentScrollFixEnabled = useMaxContentScrollFixFlag();

  const overflowOptions: OverflowMenuOption[] = [
    { text: 'Journal', onClick: () => cartPopups.showJournalPopup(guest?.Guest_id) },
    { divider: true },
    { text: 'Cancel order', danger: true, onClick: () => history.push('/') },
  ];

  return (
    <ExpandingSummary>
      <PreCartHeaderContainer>
        <PreOrderInfoHeader cartItems={cartItems} />
        <CartActionsWrapper>
          <AddItemsButton secondary icon={AddItemsIcon} onClick={() => setAddItemPanelIsOpen(true)}>
            Add items
          </AddItemsButton>
          <OverflowMenu
            anchor={
              <ActionButton secondary>
                <ElipsisIcon />
              </ActionButton>
            }
            menuOptions={overflowOptions}
          />
        </CartActionsWrapper>
      </PreCartHeaderContainer>
      {cartItems.length === 0 && (
        <ProductScanContainer>
          <ProductScan />
          <EmptyMessageWrapper>
            <EmptyMessageHeader>Add items to begin</EmptyMessageHeader>
            <EmptyMessage>There are no items in this cart. Add items to get started.</EmptyMessage>
          </EmptyMessageWrapper>
        </ProductScanContainer>
      )}
      {cartItems.length > 0 && (
        <CartItems data-testid='precart_summary' isMaxContentScrollFixEnabled={isMaxContentScrollFixEnabled}>
          {cartItems.map((item) => (
            <Item
              key={item.productId}
              automationId='precart_summary_preorder-item'
              item={item}
              setCartItems={setCartItems}
              price={itemPrices[item.productId]}
            />
          ))}
        </CartItems>
      )}
    </ExpandingSummary>
  );
}

const ExpandingSummary = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1rem;
`;

const CartItems = styled.div<{ isMaxContentScrollFixEnabled: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  overflow-y: auto;
  min-height: ${({ isMaxContentScrollFixEnabled }) => (isMaxContentScrollFixEnabled ? 'unset' : 'max-content')};
  gap: 0.5rem;
`;

const ProductScanContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #a0a8ab;
  border-radius: 12px;
  background-color: ${colors.dutchie.backgroundGrey};
  height: 100%;
`;

const EmptyMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
`;
const EmptyMessage = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.25rem;
  color: #5e696e;
`;

const EmptyMessageHeader = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.375rem;
  color: #5e696e;
`;

const PreCartHeaderContainer = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: space-between;
`;

const CartActionsWrapper = styled.div`
  display: flex;
  gap: 12px;
`;
