import { useLDClient } from 'launchdarkly-react-client-sdk';

export const useEnforceLast4OnQtyScan = () => {
  const ldClient = useLDClient();

  const isEnforceLast4OnQtyScanEnabled: boolean = ldClient?.variation(
    'pos.register.enforce-last-4-on-qty-scan',
    false
  );

  return isEnforceLast4OnQtyScanEnabled;
};
