import React from 'react';

import { AddItemsFilterDropdown } from 'components/menus/FilterMenu/FilterDropdown';
import { ProductSearchResults } from './components/ProductSearchResults';
import { FlexContainer, ResetFilters, StyledSearchBar } from './ProductSearchFilter.styles';
import { ProductSearchFilterSkeleton } from './ProductSearchFilterSkeleton';

import { useProductSearchFilter } from './useProductSearchFilter';

import type { ProductSearchResult } from 'queries/v2/product/types';
import { useInventoryTagFilteringInCart } from 'util/hooks/launch-darkly/useInventoryTagFilteringInCart';
import { useResponsiveGuestListActionsFlag } from 'util/hooks/launch-darkly/useResponsiveGuestListActionsFlag';

export type ProductSearchFilterProps = {
  selectedProduct?: ProductSearchResult;
  setSelectedProduct: (product?: ProductSearchResult) => void;
  /** This property will wait for the customer to finish loading before fetching the products  */
  waitForCustomerToLoad?: boolean;
};

const ProductSearchFilterImpl = ({
  selectedProduct,
  setSelectedProduct,
  waitForCustomerToLoad = false,
}: ProductSearchFilterProps): JSX.Element => {
  const {
    areInventoryTagsLoading,
    displayProducts,
    isShowSearchBarEnabled,
    filterOptions,
    filterSelections,
    noInventoryFound,
    noMatchingProducts,
    onChangeProductSearch,
    onSelectFilters,
    onResetFilters,
    productSearchInput,
    refetchProducts,
    selectedFilterCount,
    showLoadingSkeleton,
    tableColumns,
    wasErrorLoadingProducts,
  } = useProductSearchFilter({ waitForCustomerToLoad });

  const { isInventoryTagFilteringInCartEnabled } = useInventoryTagFilteringInCart();
  const isResponsiveGuestListActionsEnabled = useResponsiveGuestListActionsFlag();

  return (
    <div data-testid='product-search-filter_div_container'>
      {isShowSearchBarEnabled && (
        <FlexContainer>
          <StyledSearchBar
            initialValue={productSearchInput}
            maxWidth='inherit'
            onSearch={onChangeProductSearch}
            onChange={onChangeProductSearch}
            placeholder='Product search...'
            inputId='productSearchBar'
            inputAutomationId='index_search-bar_search-product'
            isResponsiveGuestListActionsEnabled={isResponsiveGuestListActionsEnabled}
          />
        </FlexContainer>
      )}

      {showLoadingSkeleton ? (
        <ProductSearchFilterSkeleton />
      ) : (
        <>
          {!!filterOptions.length && (
            <FlexContainer>
              <AddItemsFilterDropdown
                title='Categories'
                appliedFilters={filterSelections}
                filterOptions={filterOptions}
                filterKey='productCategory'
                onApply={onSelectFilters}
              />
              <AddItemsFilterDropdown
                title='Strains'
                appliedFilters={filterSelections}
                filterOptions={filterOptions}
                filterKey='strain'
                onApply={onSelectFilters}
              />
              <AddItemsFilterDropdown
                title='Brands'
                appliedFilters={filterSelections}
                filterOptions={filterOptions}
                filterKey='brandName'
                onApply={onSelectFilters}
              />
              <AddItemsFilterDropdown
                title='Vendors'
                appliedFilters={filterSelections}
                filterOptions={filterOptions}
                filterKey='vendor'
                onApply={onSelectFilters}
              />
              {isInventoryTagFilteringInCartEnabled && (
                <AddItemsFilterDropdown
                  title='Tags'
                  appliedFilters={filterSelections}
                  filterOptions={filterOptions}
                  filterKey='inventoryTags'
                  loading={areInventoryTagsLoading}
                  onApply={onSelectFilters}
                />
              )}
              {selectedFilterCount > 0 && <ResetFilters onClick={onResetFilters}>Clear Filters</ResetFilters>}
            </FlexContainer>
          )}
          <ProductSearchResults
            displayProducts={displayProducts}
            noInventoryFound={noInventoryFound}
            noMatchingProducts={noMatchingProducts}
            refetchProducts={refetchProducts}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            tableColumns={tableColumns}
            wasErrorLoadingProducts={wasErrorLoadingProducts}
          />
        </>
      )}
    </div>
  );
};

export const ProductSearchFilter = React.memo(ProductSearchFilterImpl);
