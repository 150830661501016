import React, { useCallback, useMemo } from 'react';
import { isUsbLabelPrinter, PeripheralType, useLabelPrinters } from '@dutchie/capacitor-hardware';

import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { SettingsBottomDrawerForm } from '../SettingsBottomDrawerForm';
import { SettingsCard } from 'pages/SettingsPage/SettingsCard';
import { SettingsDivider } from 'pages/SettingsPage/SettingsDivider';
import { SaveSettingsFooter } from '../../SaveSettingsFooter';
import { LabelPrinterSettingsProvider } from './components/LabelPrinterSettingsProvider';
import { useHardwareSettingsDrawer } from '../hooks/useHardwareSettingsDrawer';
import { LabelPrinterStatusPill } from './components/LabelPrinterStatusPill';
import { LabelPrinterStatusBanner } from './components/LabelPrinterStatusBanner';
import { LabelPrinterSelectListItem } from './components/LabelPrinterSelectListItem';
import { DefaultLabelSelectListItem } from './components/DefaultLabelSelectListItem';
import { LabelPrinterAutoPrintListItem } from './components/LabelPrinterAutoPrintListItem';
import { LabelPrinterTestPrintListItem } from './components/LabelPrinterTestPrintListItem';
import { LabelPrinterIcon } from 'assets/icons/settings/LabelPrinterIcon';
import { AddDeviceListItem } from '../components/AddDeviceListItem/AddDeviceListItem';

export const LabelPrinterSettingsListItem = () => {
  const { showDrawer, hideDrawer, isDrawerOpen } = useHardwareSettingsDrawer();

  const { labelPrinters, search } = useLabelPrinters();

  const hasUsbPrinters = useMemo(() => {
    return labelPrinters.filter(isUsbLabelPrinter).length > 0;
  }, [labelPrinters]);

  const handleAuthorize = useCallback(() => {
    search({ requestNewDevices: true });
  }, [search]);

  return (
    <>
      <SettingsListItem
        automationId='label_printer_settings'
        icon={<LabelPrinterIcon />}
        title='Label printer'
        subtitle='Settings for label printer'
        onClick={showDrawer}
        actions={[<LabelPrinterStatusPill />]}
      />
      <SettingsBottomDrawerForm
        automationId='label_printer_settings-drawer'
        title='Label printer'
        open={isDrawerOpen}
        hide={hideDrawer}
      >
        <LabelPrinterSettingsProvider>
          <SettingsCard footer={<SaveSettingsFooter />}>
            <LabelPrinterStatusBanner />
            <LabelPrinterSelectListItem />
            <SettingsDivider />
            <DefaultLabelSelectListItem />
            <SettingsDivider />
            <LabelPrinterAutoPrintListItem />
            <SettingsDivider />
            <LabelPrinterTestPrintListItem />
          </SettingsCard>
          <AddDeviceListItem
            deviceType={PeripheralType.labelPrinter}
            isDeviceDetected={hasUsbPrinters}
            onAuthorize={handleAuthorize}
          />
        </LabelPrinterSettingsProvider>
      </SettingsBottomDrawerForm>
    </>
  );
};
