import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { SearchBar } from 'components/layout';
import { colors } from 'css/Theme';

export const FlexContainer = styled.div`
  align-items: center;
  display: flex;
  grid-gap: 10px;
  padding: 0.1rem 0.7rem 1rem;
`;

export const StyledSearchBar = styled(SearchBar)<{isResponsiveGuestListActionsEnabled?: boolean}>`
  background-color: ${colors.dutchie.lightGrey};

  ${({ isResponsiveGuestListActionsEnabled }) =>
    !isResponsiveGuestListActionsEnabled &&
    css`
      height: 44px;
  `};
`;

export const SearchResultsContainer = styled.div`
  flex: 1 0 auto;
`;

export const TableContainer = styled.div`
  tr.clickable:hover {
    background-color: ${rgba(colors.dutchie.blue, 0.025)};
  }
  tr.selected,
  tr.selected:hover {
    background-color: ${rgba(colors.dutchie.blue, 0.1)};
  }
`;

export const ResetFilters = styled.p`
  color: ${colors.dutchie.grey};
  margin-left: 1rem;
  cursor: pointer;
`;
