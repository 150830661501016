import { useCallback } from 'react';
import { useCartPopups } from 'components/CartPopups';
import { CartItem, ExpiredItemInfo, isCartItemOrProductSearchResult } from 'models/Cart';
import * as CartApi from 'api/CartApi';
import { errorNotification } from 'store/actions/NotificationsActions';
import { useShouldWarnItemNotInPreorder } from 'util/Helpers';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { getProductId } from 'util/helpers/products/getProductId';
import { showLast4Popup } from 'store/actions/PopupsActions';
import { useAnonymousCart } from 'pages/CartPage/hooks/useAnonymousCart';
import { useEnforceLast4OnQtyScan } from './launch-darkly/useEnforceLast4OnQtyScan';
import { useVerifyLastFourIfNecessary } from './useVerifyLastFourIfNecessary';

import { useCartItemActions } from './cart-items/useCartItemActions';
import { useCartItems } from './cart/useCartItems';
import { useProducts } from './products/useProducts';
import { useProductsById } from './products/useProductsById';

import type { ProductSearchResult } from 'queries/v2/product/types';

export type ChangeCartQtyParam = {
  batchId?: number;
  cannabisInventory?: string;
  defaultLabelId?: number;
  defaultUnitId?: number;
  inventoryId?: number;
  productDescription: string;
  productId: number;
  productType: 'Wgt' | 'Qty';
  qtyInCart: number;
  recUnitPrice: number;
  serialNumber: string;
  totalAvailable: number;
  totalGrams: number;
  unitPrice: number;
  wgtInCart: number;
};

export const useChangeCartQtyFromProductSearchResult = () => {
  const utilities = useCartModificationUtilities();
  return utilities.changeCartQtyFromProduct;
};

export const useChangeCartQtyFromCartItem = () => {
  const utilities = useCartModificationUtilities();
  return utilities.changeCartQtyFromCartItem;
};

export const useAddQtyFromProduct = () => {
  const utilities = useCartModificationUtilities();
  return utilities.addQtyFromProduct;
};

export const useAddQtyFromCartItem = () => {
  const utilities = useCartModificationUtilities();
  return utilities.addQtyFromCartItem;
};

const useParamFromProductSearchResult = () => {
  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();

  const cartItemsByIdFromRQ = useCartItems();
  const cartItemsByIdFromRedux = useAppSelector((state) => state.cart.itemsById);
  const cartItemsById = isAnonymousCartLDFlagEnabled ? cartItemsByIdFromRQ : cartItemsByIdFromRedux;

  return useCallback(
    (product: ProductSearchResult) => {
      const id = getProductId(product);
      const cartItem = cartItemsById?.[id];

      return {
        serialNumber: product.serialNo,
        productId: product.productId,
        inventoryId: product.invId,
        unitPrice: product.unitPrice,
        recUnitPrice: product.recUnitPrice,
        totalAvailable: product.totalAvailable,
        totalGrams: product.totalGrams,
        productType: product.productType,
        productDescription: product.productDescription,
        defaultUnitId: product.defaultUnitId,
        defaultLabelId: product.defaultLabelId,
        cannabisInventory: product.cannabisInventory,
        batchId: product.batchId,
        qtyInCart: cartItem?.QtySelected ?? 0,
        wgtInCart: cartItem?.Grams ?? 0,
      };
    },
    [cartItemsById]
  );
};

const useParamFromCartItem = () => {
  const { isAnonymousCartLDFlagEnabled } = useAnonymousCart();

  const productsByIdNew = useProducts();
  const { productsById: productsByIdOld } = useProductsById();
  const productsById = isAnonymousCartLDFlagEnabled ? productsByIdNew : productsByIdOld;

  return useCallback(
    (item: CartItem) => {
      const id = getProductId(item);
      const productItem = productsById?.[id];

      return {
        serialNumber: item.SerialNo,
        productId: item.ProductId,
        inventoryId: item.InventoryId,
        unitPrice: productItem?.unitPrice ?? 0,
        recUnitPrice: productItem?.recUnitPrice ?? 0,
        totalAvailable: productItem?.totalAvailable ?? 0,
        totalGrams: productItem?.totalGrams ?? 0,
        productType: item.WgtCnt,
        productDescription: item.Product,
        defaultUnitId: item.DefaultUnitId,
        defaultLabelId: item.DefaultLabelId,
        cannabisInventory: item.CannbisProduct,
        batchId: item.BatchId,
        qtyInCart: item.QtySelected,
        wgtInCart: item.Grams ?? 0,
      };
    },
    [productsById]
  );
};

const isChangeCartQty = (item: ChangeCartQtyParam | CartItem | ProductSearchResult): item is ChangeCartQtyParam =>
  (item as ChangeCartQtyParam).serialNumber !== undefined;

export const useChangeCartQty = () => {
  const getParamsFromCartItem = useParamFromCartItem();
  const getParamsFromProduct = useParamFromProductSearchResult();
  return (item: ChangeCartQtyParam | CartItem | ProductSearchResult) => {
    if (isChangeCartQty(item)) {
      return item;
    }
    return isCartItemOrProductSearchResult(item) ? getParamsFromCartItem(item) : getParamsFromProduct(item);
  };
};

const useCartModificationUtilities = () => {
  const isCheckExpirationDateEnabled = useAppSelector((state) => state.settings.features.CheckExpirationDate);
  const isLast4Enabled = useAppSelector((state) => state.settings.features.Last4);
  const getParamFromCartItem = useParamFromCartItem();
  const getParamFromProduct = useParamFromProductSearchResult();

  // Import actions under LD flag for React Query
  const { addItemToCart, addBulkItemToCart, removeItemFromCart } = useCartItemActions();

  // Create the product/cartItem id and look up both

  const cartPopups = useCartPopups();
  const dispatch = useAppDispatch();
  const shouldWarnItemNotInPreorder = useShouldWarnItemNotInPreorder();
  const isEnforceLast4OnQtyScanEnabled = useEnforceLast4OnQtyScan();
  const verifyLastFourIfNecessaryNew = useVerifyLastFourIfNecessary();

  const verifyManagerPinIfNecessary = useCallback(
    async (product: ChangeCartQtyParam) => {
      if (isCheckExpirationDateEnabled) {
        const serialNumbers = [product.serialNumber];
        const expiredItems = (await CartApi.getExpiredItemsInCart(serialNumbers)) as Array<ExpiredItemInfo>;
        if (expiredItems?.length > 0 ?? false) {
          return new Promise<void>((resolve, reject) => {
            cartPopups.showManagerPinPopup(
              () => {
                resolve();
              },
              'Add Expired Item',
              undefined,
              undefined,
              undefined,
              () => {
                reject();
              }
            );
          });
        }
      }

      return Promise.resolve();
    },
    [cartPopups, isCheckExpirationDateEnabled]
  );

  const verifyLastFourIfNecessary = useCallback(
    (product: ChangeCartQtyParam) => {
      return new Promise<void>((resolve, reject) => {
        if (isLast4Enabled && product.cannabisInventory === 'Yes') {
          dispatch(
            showLast4Popup({
              onSuccess: () => {
                resolve();
              },
              serialNumber: product.serialNumber,
              onCancel: () => {
                reject();
              },
            })
          );
        } else {
          resolve();
        }
      });
    },
    [dispatch, isLast4Enabled]
  );

  const warnItemInPreorderIfNecessary = useCallback(
    (product: ChangeCartQtyParam) => {
      return new Promise<void>((resolve, reject) => {
        if (shouldWarnItemNotInPreorder(product.productId)) {
          cartPopups.showAddMoreItemsInPreOrderPopup(
            () => {
              resolve();
            },
            () => {
              reject();
            }
          );
        } else {
          resolve();
        }
      });
    },
    [cartPopups, shouldWarnItemNotInPreorder]
  );

  const addProductToCart = useCallback(
    async (product: ChangeCartQtyParam, count: number) => {
      if (product.productType === 'Qty') {
        if (product.totalAvailable - count < 0) {
          dispatch(errorNotification(`The max quantity available is ${product.totalAvailable + product.qtyInCart}`));
          throw new Error();
        }

        if (isEnforceLast4OnQtyScanEnabled) {
          await verifyLastFourIfNecessaryNew({ cannabisInventory: product.cannabisInventory ?? '', serialNo: product.serialNumber });
        } else {
          await verifyLastFourIfNecessary(product);
        }

        await verifyManagerPinIfNecessary(product);
        await warnItemInPreorderIfNecessary(product);
        return addItemToCart(product, count);
      } else if (product.productType === 'Wgt') {
        if (product.totalGrams - count < 0) {
          dispatch(errorNotification(`The max weight available is ${product.totalGrams + product.wgtInCart}`));
          throw new Error();
        }

        if (isEnforceLast4OnQtyScanEnabled) {
          await verifyLastFourIfNecessaryNew({ cannabisInventory: product.cannabisInventory ?? '', serialNo: product.serialNumber });
        } else {
          await verifyLastFourIfNecessary(product);
        }

        await verifyManagerPinIfNecessary(product);
        await warnItemInPreorderIfNecessary(product);
        return addBulkItemToCart(product, count);
      }
    },
    [isEnforceLast4OnQtyScanEnabled, verifyManagerPinIfNecessary, warnItemInPreorderIfNecessary, addItemToCart, dispatch, verifyLastFourIfNecessaryNew, verifyLastFourIfNecessary, addBulkItemToCart]
  );

  const removeProductFromCart = useCallback(
    async (product: ChangeCartQtyParam, count: number) => {
      if (product.productType === 'Qty') {
        if (isEnforceLast4OnQtyScanEnabled) {
          await verifyLastFourIfNecessaryNew({ cannabisInventory: product.cannabisInventory ?? '', serialNo: product.serialNumber });
        } else {
          await verifyLastFourIfNecessary(product);
        }

        if (product.qtyInCart - count < 0) {
          return;
        }
        removeItemFromCart(product, count);
      } else if (product.productType === 'Wgt') {
        if (isEnforceLast4OnQtyScanEnabled) {
          await verifyLastFourIfNecessaryNew({ cannabisInventory: product.cannabisInventory ?? '', serialNo: product.serialNumber });
        } else {
          await verifyLastFourIfNecessary(product);
        }

        if (product.wgtInCart - count < 0) {
          return;
        }
        removeItemFromCart(product, count);
      }
    },
    [isEnforceLast4OnQtyScanEnabled, removeItemFromCart, verifyLastFourIfNecessaryNew, verifyLastFourIfNecessary]
  );

  const changeCartQty = useCallback(
    async (product: ChangeCartQtyParam, count: number) => {
      const changeInCount = count - product.qtyInCart;
      const changeInWgt = count - product.wgtInCart;

      if (product.productType === 'Qty') {
        if (changeInCount > 0) {
          await addProductToCart(product, changeInCount);
        } else if (changeInCount < 0) {
          await removeProductFromCart(product, changeInCount * -1);
        }
      } else if (product.productType === 'Wgt') {
        if (changeInWgt > 0) {
          await addProductToCart(product, changeInWgt);
        } else if (changeInWgt < 0) {
          await removeProductFromCart(product, changeInWgt * -1);
        }
      }
    },
    [addProductToCart, removeProductFromCart]
  );

  const changeCartQtyFromCartItem = useCallback(
    async (item: CartItem, count: number) => {
      const param = getParamFromCartItem(item);
      return changeCartQty(param, count);
    },
    [changeCartQty, getParamFromCartItem]
  );

  const changeCartQtyFromProduct = useCallback(
    async (product: ProductSearchResult, count: number) => {
      const param = getParamFromProduct(product);
      return changeCartQty(param, count);
    },
    [changeCartQty, getParamFromProduct]
  );

  const addQtyFromCartItem = useCallback(
    async (item: CartItem, count: number) => {
      const param = getParamFromCartItem(item);
      return addProductToCart(param, count);
    },
    [addProductToCart, getParamFromCartItem]
  );

  const addQtyFromProduct = useCallback(
    async (product: ProductSearchResult, count: number) => {
      const param = getParamFromProduct(product);
      return addProductToCart(param, count);
    },
    [addProductToCart, getParamFromProduct]
  );

  return {
    changeCartQtyFromCartItem,
    changeCartQtyFromProduct,
    addQtyFromCartItem,
    addQtyFromProduct,
  };
};
