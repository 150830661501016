import { DutchiePayInvoiceConfirmationNotification } from 'models/Checkout';
import { useCallback } from 'react';

export const useOnDutchiePayPusherMessage = (
  onPusherMessageCallback: (notification: DutchiePayInvoiceConfirmationNotification | null) => Promise<void>
) => {
  return useCallback(
    (eventName: string, data: string) => {
      let notification = null;
      if (eventName === 'invoice-confirmed') {
        try {
          notification = JSON.parse(data);
        } catch (error) {
          notification = data;
        }
        onPusherMessageCallback(notification);
      }
    },
    [onPusherMessageCallback]
  );
};
