import React from 'react';

import { SettingsSelect as NewSettingsSelect } from './SettingsSelect';
import { SettingsSelect as OldSettingsSelect } from './SettingsSelect-deprecated';
import { useImprovedSettingsUI } from 'util/hooks/launch-darkly/useImprovedSettingsUI';

type NewSettingsSelectProps = React.ComponentProps<typeof NewSettingsSelect>;
type OldSettingsSelectProps = React.ComponentProps<typeof OldSettingsSelect>;

type SettingsSelectProps = NewSettingsSelectProps | OldSettingsSelectProps;

export const SettingsSelect = (props: SettingsSelectProps) => {
  const { isImprovedSettingsUIEnabled } = useImprovedSettingsUI();

  const oldProps: OldSettingsSelectProps = { ...(props as OldSettingsSelectProps) };
  const newProps: NewSettingsSelectProps = { ...(props as NewSettingsSelectProps) };

  return isImprovedSettingsUIEnabled ? (
    <NewSettingsSelect
      {...newProps}
      // Enforces compliance with the type definitions until we clean up the old code
      {...(newProps.devices !== undefined
        ? { devices: newProps.devices, options: undefined }
        : { devices: undefined, options: newProps.options })}
    />
  ) : (
    <OldSettingsSelect {...oldProps} />
  );
};

export type { SettingsSelectOption } from './SettingsSelect';
