import React from 'react';
import { isKeyboardScanner } from '@dutchie/capacitor-hardware';
import {
  ConnectionStatusBanner,
  PeripheralSetupConnectionStatus,
} from 'pages/SettingsPage/Status/ConnectionStatusBanner';
import { useConnectedScanner } from 'util/hooks/hardware/useConnectedHardware';
import { isNativeScanner } from '@dutchie/capacitor-peripheral';

export const ScannerStatusBanner = () => {
  const { device, hasPreferredDevice } = useConnectedScanner();

  if (!hasPreferredDevice) {
    return null;
  }

  let status =
    device?.isConnected === true || (device && isNativeScanner(device)) // native scanner is not reporting status correctly, but it always auto-connects
      ? PeripheralSetupConnectionStatus.connected
      : PeripheralSetupConnectionStatus.unavailable;

  // if all scanners are the default setup, show the additional setup banner
  if (device && isKeyboardScanner(device)) {
    status = PeripheralSetupConnectionStatus.additionalSetupRequired;
  }

  return <ConnectionStatusBanner deviceTypeName='Scanner' deviceName={device?.name} status={status} />;
};
