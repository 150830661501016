import { useCallback } from 'react';
import { PusherChannelType } from 'models/Pusher';
import { usePusherChannel } from 'pusher/usePusherChannel';
import { useSmartCheckinActive } from 'util/hooks/guestlist/useSmartCheckinActive';
import { CheckedInGuest } from 'models/Guest';
import {
  UpdateGuestListRequest,
  addOrUpdateGuest,
  notifyConectionLost,
  notifyPusherConnected,
  removeGuestByTransactionIdFromList,
  removeGuestFromList,
} from 'store/actions/GuestListActions';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';

const useOnNewGuestListPusherMessages = () => {
  const settings = useSelector((state: State) => state.settings);
  const dispatch = useDispatch();

  const onNewPusherMessages = useCallback(
    (eventName: string, data: string) => {
      if (eventName === 'checkin') {
        const parsedGuest: CheckedInGuest = JSON.parse(data);
        const guestUpdatedType: UpdateGuestListRequest = {
          guestUpdated: parsedGuest,
          settings: settings,
        };
        dispatch(addOrUpdateGuest(guestUpdatedType));
      } else if (eventName === 'checkout') {
        dispatch(removeGuestFromList({ guestId: parseInt(data), settings }));
      } else if (eventName === 'checkoutByTransactionId') {
        dispatch(removeGuestByTransactionIdFromList({ transactionId: parseInt(data), settings }));
      } else if (eventName === 'updateGuest') {
        const parsedGuest: CheckedInGuest = JSON.parse(data);
        const guestUpdatedType: UpdateGuestListRequest = {
          guestUpdated: parsedGuest,
          settings: settings,
        };
        dispatch(addOrUpdateGuest(guestUpdatedType));
      }
    },
    [settings, dispatch]
  );
  return onNewPusherMessages;
};

export const useGuestListPusherChannel = () => {
  const isSmartCheckinActive = useSmartCheckinActive();
  const onChannelMessage = useOnNewGuestListPusherMessages();
  const dispatch = useDispatch();

  return usePusherChannel({
    channelType: PusherChannelType.Guest,
    enabled: isSmartCheckinActive,
    onChannelMessage,
    onConnected: () => dispatch(notifyPusherConnected()),
    onDisconnected: () => dispatch(notifyConectionLost()),
  });
};
