import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { SelectChangeEvent } from '@mui/material';

import { Select } from 'components/backoffice/select';
import { MenuItem } from 'components/backoffice/menu-item';
import { colors } from 'css/Theme';
import { Row } from 'components/layout';

export type SettingsSelectOption = {
  label: string;
  value: string;
};

type SettingsSelectProps = {
  automationId?: string;
  emptyOptionsMessage?: string;
  value?: string;
  options: SettingsSelectOption[];
  onChange?: (option?: { label: string; value: string }) => void;
  onRefresh: () => Promise<void>;
  onShowAll?: () => Promise<void>;
  placeholder: string;
  ddActionName?: string;
} & {
  // Defining new props here to avoid TypeScript errors
  devices?: unknown;
  secondaryAction?: unknown;
};

export const SettingsSelect = ({
  automationId,
  ddActionName,
  emptyOptionsMessage = 'No options available',
  value,
  onChange,
  onRefresh,
  onShowAll,
  options: unsortedOptions,
  placeholder,
}: SettingsSelectProps) => {
  const [refreshing, setRefreshing] = useState(false);
  const options = unsortedOptions.sort((a, b) => a.label.localeCompare(b.label));

  // Ensure value is one of the available options
  const availableValue = options.find((option) => option.value === value)?.value ?? '';

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = String(event.target.value);

    const option = options.find((option) => option.value === value);
    if (options) {
      onChange?.(option);
    }
  };

  const handleRefresh = async () => {
    try {
      setRefreshing(true);
      await onRefresh?.();
    } finally {
      setRefreshing(false);
    }
  };

  const handleShowAll = async () => {
    try {
      setRefreshing(true);
      await onShowAll?.();
    } finally {
      setRefreshing(false);
    }
  };

  return (
    <StyledSelect
      automationId={automationId}
      ddActionName={ddActionName ?? placeholder}
      placeholder={placeholder}
      value={availableValue}
      onChange={handleChange}
      renderValue={(selected: unknown) => {
        const preferredDevice = options.find((option) => option.value === selected);
        const hasPreferredDevice = !!`${selected}`.trim().length;
        const isPreferredDeviceAvailable = hasPreferredDevice && !!preferredDevice;
        const showPlaceholder = hasPreferredDevice && !isPreferredDeviceAvailable;
        return (
          <>
            {!hasPreferredDevice && <StyledPlaceholder>{placeholder}</StyledPlaceholder>}
            {hasPreferredDevice && showPlaceholder && (
              <StyledPlaceholder>Preferred device unavailable</StyledPlaceholder>
            )}
            {hasPreferredDevice && !showPlaceholder && (
              <StyledSelectedValue>{preferredDevice?.label ?? 'Missing label'}</StyledSelectedValue>
            )}
          </>
        );
      }}
    >
      {options.length === 0 && <NoOptionsAvailable>{emptyOptionsMessage}</NoOptionsAvailable>}
      {options.map((option) => (
        <StyledMenuItem key={option.value} selected={value === option.value} value={option.value}>
          {option.label}
        </StyledMenuItem>
      ))}
      <Footer>
        <StyledRow>
          <StyledLink data-testid='settings_select-refresh_button' refreshing={refreshing} onClick={handleRefresh}>
            Refresh
          </StyledLink>
          {onShowAll && (
            <StyledLink data-testid='settings_select-showall_button' refreshing={refreshing} onClick={handleShowAll}>
              Show all
            </StyledLink>
          )}
        </StyledRow>
      </Footer>
    </StyledSelect>
  );
};

const NoOptionsAvailable = styled.div`
  color: ${colors.dutchie.grey50};
  padding: 1em;
  text-align: center;
`;

const StyledRow = styled(Row)`
  justify-content: space-between;
  width: 100%;
`;
const StyledLink = styled.a<{ refreshing: boolean }>`
  color: ${colors.dutchie.blue};
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  text-decoration: none;

  ${(ref) =>
    ref.refreshing &&
    css`
      pointer-events: none;
      color: ${colors.dutchie.grey50};
    `}
`;

const StyledMenuItem = styled(MenuItem)<{ selected: boolean }>`
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
`;

const Footer = styled.div`
  border-top: 1px solid ${colors.dutchie.grey90};
  font-weight: bold;
  padding: 12px 20px 5px 20px;
`;

const StyledSelectedValue = styled.div`
  padding: 0.5rem 0rem;
`;

const StyledPlaceholder = styled(StyledSelectedValue)`
  color: var(--color-greyscale-grey-50);
`;

const StyledSelect = styled(Select)`
  min-width: 300px;
`;
