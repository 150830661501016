import { useLDClient } from 'launchdarkly-react-client-sdk';

import { useAllResponsiveDesigns } from './useAllResponsiveDesigns';

export const useDeliveryPageEnhancements = () => {
  const ldClient = useLDClient();
  const { isAllResponsiveDesignsFlagEnabled } = useAllResponsiveDesigns();

  const isUseDeliveryPageEnhancements: boolean = ldClient?.variation(
    'pos.delivery.delivery-page-enhancements.rollout',
    false
  );

  return isUseDeliveryPageEnhancements || isAllResponsiveDesignsFlagEnabled;
};
