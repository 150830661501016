import { initialize } from 'launchdarkly-js-client-sdk';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { getDDClient } from 'util/logger/logging-providers/datadog';
import type { LDClient } from 'launchdarkly-js-client-sdk';
import { cleanLdKey } from 'util/clean-ld-key';

let GLOBAL_LD_CLIENT: LDClient | undefined;

export const initializeLDClient = async (key: string, region: string) => {
  GLOBAL_LD_CLIENT = initialize(key, {
    email: 'engineering@dutchie.com',
    key: 'eng-internal',
    custom: {
      region: region || '',
      host: window.location.hostname,
    },
  },
  {
    inspectors: [
      {
        type: 'flag-used',
        name: 'dd-inspector',
        method: (key, detail) => {
          getDDClient()?.addFeatureFlagEvaluation(cleanLdKey(key), detail.value);
        },
      },
    ],
  });

  await GLOBAL_LD_CLIENT.waitUntilReady();

  return GLOBAL_LD_CLIENT;
};

export const getLDClient = () => GLOBAL_LD_CLIENT;

export const useTwoPanelCustomerFlag = () => useBooleanLDFlag('leaflogix-two-panel-customer', false);
export const useTwoPanelProductFlag = () => useBooleanLDFlag('leaflogix-two-panel-product', false);

export const useBooleanLDFlag = (name: string, defaultValue: boolean) => {
  const flags = useFlags();
  const val = flags[name];
  if (val === undefined) {
    return defaultValue;
  }

  return Boolean(val);
};

export const useBooleanLDVariant = (name: string, defaultValue: boolean = false) => {
  const ldClient = useLDClient();
  const value = ldClient?.variation(name, defaultValue);
  return Boolean(value);
};
