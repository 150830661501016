import React, { useState } from 'react';

import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { SetupWizardModal } from './setup/SetupWizardModal';

export const ScannerSetupListItem = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <SettingsListItem
        title='Set up scanner'
        subtitle='Configure scanner to register'
        onClick={() => setShowModal(true)}
      />
      {showModal && <SetupWizardModal hide={() => setShowModal(false)} />}
    </>
  );
};
