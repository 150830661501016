
import { useCallback } from 'react';
import { featuresUpdated, settingsUpdated } from 'store/actions/SettingsActions';
import { useAppDispatch } from 'util/hooks';

export const useOnFeatureFlagPusherMessage = () => {
  const dispatch = useAppDispatch();
  return useCallback(
    (eventName: string, data: string) => {
      let notification = null;
      try {
        notification = JSON.parse(data);
      } catch (error) {
        notification = data;
      }
      if (eventName === 'features-updated') {
        dispatch(featuresUpdated(notification));
      } else if (eventName === 'all-settings-updated') {
        dispatch(settingsUpdated(notification));
      }
    },
    [dispatch]
  );
};
