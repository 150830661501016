import { useLDClient } from 'launchdarkly-react-client-sdk';

import { isWebViewApp } from '../webviewApp';

type AllResponsiveDesignsFlagState = {
  isAllResponsiveDesignsFlagEnabled: boolean;
};

export const useAllResponsiveDesigns = (): AllResponsiveDesignsFlagState => {
  const ldClient = useLDClient();
  const isAllResponsiveDesignsFlagEnabled =
    ldClient?.variation('pos.register.use-all-responsive-designs', false) === true;
  return { isAllResponsiveDesignsFlagEnabled: isAllResponsiveDesignsFlagEnabled || isWebViewApp };
};
