import { previewLabels } from './requests';
import { logger, customEventKeys } from 'util/logger';
import { parseErrorMessage } from 'util/helpers/parseErrorMessage';

import type { PrintJobContext } from 'util/logger/types/printing';

import { successNotification, errorNotification } from 'store/actions/NotificationsActions';
import { setPrintPreviewStatus } from 'store/actions/PrintJobsActions';

import { PrintPreviewJob, PrintPreviewStatus } from 'models/Printing';

import type { CartItem } from 'models/Cart';
import { useAppDispatch } from 'util/hooks';
import { useAppSelector } from 'util/hooks/useAppDispatch';

type UsePreviewLabelsParams = {
  items: CartItem[];
  labelId?: number;
  printAll: boolean;
  shipmentId: number;
};

export const usePreviewLabels = () => {
  const dispatch = useAppDispatch();

  const defaultLabelId = useAppSelector((state) => state.settings.userSettings.selectedLabel?.id);
  const allowsProductLabels = useAppSelector((state) => state.settings.features.AllowDefaultProductLabels);

  return async (params: UsePreviewLabelsParams) => {
    try {
      dispatch(successNotification('Downloading labels...'));
      dispatch(
        setPrintPreviewStatus({ printPreviewJob: PrintPreviewJob.LABELS, status: PrintPreviewStatus.GENERATING })
      );
      logger.info<PrintJobContext>('download labels', {
        key: customEventKeys.printing.jobStarted,
        job: PrintPreviewJob.LABELS,
        requestParams: params,
        printer: { id: 'n/a', name: 'n/a', service: 'n/a', vendorId: undefined, productId: undefined },
      });

      await previewLabels({
        ...params,
        labelId: params.labelId ?? defaultLabelId,
        allowsProductLabels,
      });

      dispatch(successNotification('Labels generated'));
      dispatch(
        setPrintPreviewStatus({ printPreviewJob: PrintPreviewJob.LABELS, status: PrintPreviewStatus.SUCCESSFUL })
      );
    } catch (e) {
      const message = parseErrorMessage(e);
      dispatch(errorNotification(`Error generating labels: ${message}`));
      dispatch(setPrintPreviewStatus({ printPreviewJob: PrintPreviewJob.LABELS, status: PrintPreviewStatus.FAILED }));
      logger.error(e, { message: 'Failed to preview labels', requestParams: params });
    }
  };
};
