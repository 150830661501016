import styled from 'styled-components';
import { colors } from 'css/Theme';

export const SetupTitle = styled.div`
  color: ${colors.dutchie.gray70};
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
`;

export const SetupNote = styled.div`
  color: ${colors.dutchie.gray70};
  font-size: 14px;
  line-height: 20px;

  a {
    font-weight: 600;
  }
`;
