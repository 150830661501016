import { saveAs } from 'file-saver';
import { Http } from '@capacitor-community/http';
import {
  LabelResponse,
  PrinterResponse,
  RegisterResponse,
  UserInfoResponse,
  PinCheckResponse,
  EnvironmentDetails,
  ConsumeLoginTokenResponse,
  CFDCustomIdleImageResponse,
  LogoutRequest,
  EloUserInfoResponse,
  PrintNodePrinterType,
} from 'models/Misc';
import { BuildNumberResponse, HelpDeskLinkResponse, LocationSettingsResponse, Lsp, LspLocation } from 'models/Location';
import { createAuthBody, post, getReq, getBaseUrl, combineUrl } from './HttpHelpers';
import wretch from 'wretch';
import { UserState } from 'store/reducers/UserReducer';

export const externalApi = wretch().url(getBaseUrl()).options({ credentials: 'include', mode: 'cors' });

//TODO: convert this to capacitor http to support downloading history on android
//I had a bad time trying to get the blob to download properly so good luck
export const download = (url: string, filename: string, Guest_id?: number) => {
  return externalApi
    .url(url)
    .formUrl(createAuthBody({}, Guest_id))
    .post()
    .blob()
    .then((data) => saveAs(data, filename));
};

export const downloadTransactionHistory = (guestId?: number) => {
  download('v2/product/Export_Transaction_History', 'Transaction History.xlsx', guestId);
};

export const downloadProductHistory = (guestId?: number) => {
  download('v2/product/Export_Product_History', 'ProductHistory.xlsx', guestId);
};

export const logIn = (username: string, password: string, isLoginImprovementsEnabled?: boolean) => {
  const url = isLoginImprovementsEnabled ? 'login' : 'posv3/user/EmployeeLogin';
  return Http.post({
    url: combineUrl(getBaseUrl(), url),
    data: { username, password, url: getBaseUrl() },
    headers: {
      'content-type': 'application/json',
    },
    webFetchExtra: {
      credentials: 'include',
    },
  });
};

export const logOut = (logoutRequest: LogoutRequest) => {
  return Http.post({
    url: combineUrl(getBaseUrl(), '/posv3/user/Logout'),
    data: logoutRequest,
    headers: {
      'content-type': 'application/json',
    },
    webFetchExtra: {
      credentials: 'include',
    },
  });
};

export const ssoLogin = async (username: string, isResetPinLogin: boolean = false) => {
  const url = isResetPinLogin ? 'saml/SSOLoginRedirect?isResetPinLogin=true' : 'saml/SSOLoginRedirect';
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result = await post<any>(url, { username }, { ignoreFormat: true });

  if (result.Result) {
    // match functionality at src/api/HttpHelpers.ts:89
    return result.Data;
  } else {
    return Promise.reject({ message: result.Message, data: result.Data });
  }
};

export const ssoLoginEntity = (entity: string) => {
  return Http.get({
    url: combineUrl(getBaseUrl(), `/saml/Login/${entity}`),
    headers: { 'x-redirect': 'false', 'content-type': 'application/json' },
    webFetchExtra: {
      credentials: 'include',
    },
  });
};

export const ssoLoginEnabled = () => {
  return Http.get({
    url: combineUrl(getBaseUrl(), `/saml/SSOLoginEnabled`),
    headers: { 'content-type': 'application/json' },
    webFetchExtra: {
      credentials: 'include',
    },
  });
};

export const getPasswordSettings = (resetToken: string) => {
  return Http.get({
    url: combineUrl(getBaseUrl(), `/security/password/settings?resetToken=${resetToken}`),
    headers: { 'content-type': 'application/json' },
    webFetchExtra: {
      credentials: 'include',
    },
  });
};

export const getCurrentServer = () => {
  return getReq<string>('/admin/current-server');
};

export const forgotPassword = (username: string) => {
  return post('posv3/user/ForgotPassword', { username });
};

export const resetPinLogin = (username: string, password: string, orgId: number, samlSessionId: string = '') => {
  return Http.put({
    url: combineUrl(getBaseUrl(), '/pinlogin'),
    data: {
      UserName: username,
      Password: password,
      OrgId: orgId,
      SamlSessionId: samlSessionId,
      SessionId: window.sessionStorage.getItem('SessionGId') || '',
      LspId: window.sessionStorage.getItem('LspId') || 0,
      LocId: window.sessionStorage.getItem('LocId') || 0,
    },
    headers: {
      'content-type': 'application/json',
    },
    webFetchExtra: {
      credentials: 'include',
    },
  });
};

export const resetPassword = (password: string, token: string) => {
  return post('posv3/user/ResetPassword', { password, passwordToken: token });
};

export const generateLoginToken = (registerId?: number, hardwareId?: string) => {
  return post<string>('posv3/user/login-token/generate', { registerId, hardwareId });
};
export const consumeLoginToken = (token: string) => {
  return post<ConsumeLoginTokenResponse>('posv3/user/login-token/consume', { token });
};

export const getUserDetails = () => {
  return post<UserInfoResponse>('posv3/user/POSLoginDetails');
};

export const getEloUserDetails = () => {
  return post<EloUserInfoResponse>('posv3/user/POSEloLoginDetails');
};

export const getLabelPrintersList = () => {
  return post<Array<PrinterResponse>>('posv3/print/list-label-printers');
};

export const getReceiptPrintersList = () => {
  return post<Array<PrinterResponse>>('posv3/print/list-receipt-printers');
};

export const getLabelsList = () => {
  return post<Array<LabelResponse>>('posv3/label/Get_POS_AvailableLabel');
};

export const getRegistersList = (hardwareId?: string | null) => {
  return post<Array<RegisterResponse>>('posv3/registers/get', { HardwareId: hardwareId });
};

export const getLocationSettings = () => {
  return post<LocationSettingsResponse>('posv3/maintenance/get_loc_settings');
};

export const getCFDCustomIdleImage = () => {
  return post<CFDCustomIdleImageResponse>('v2/devices/get-cfd-custom-idle-image');
};

export const getPermissions = () => {
  return post<Array<String>>('permissions/getV2');
};

export const getUsersWithPermission = (PermissionId: number) => {
  return post<Array<UserState>>('permissions/get-users-with-permission', { PermissionId });
};

export const checkEmployeePin = (PinCode: string, ShipmentId?: number | null) => {
  return post<Array<PinCheckResponse>>('posv3/maintenance/EmployeePinCode', { PinCode, ShipmentId });
};

export const getBuildNumber = () => {
  return getReq<BuildNumberResponse>('admin/get-build-number', { ignoreFormat: true });
};

export const getLsps = () => {
  return post<Array<Lsp>>('posv3/maintenance/GetLsp');
};

export const updateDefaultLsp = (LspId: number) => {
  return post('posv3/maintenance/UpdateDefaultLsp', { LspId });
};

export const getLspLocations = () => {
  return post<Array<LspLocation>>('posv3/maintenance/GetLspLocations');
};

export const updateDefaultLocation = (LocId: number) => {
  return post('posv3/maintenance/UpdateDefaultLspLocation', { LocId });
};

export const getHelpDeskLink = () => {
  return post<HelpDeskLinkResponse>('posv3/lsplocation/get-lsp-help-desk');
};

export const getEnvironmentDetails = () => {
  return getReq<EnvironmentDetails>('admin/environment-details');
};

type PrintBytesRequest = { printerId: number; bytes: Uint8Array; printerType: PrintNodePrinterType; title?: string };
export const printBytes = ({ bytes, printerId, printerType, title }: PrintBytesRequest): Promise<{ JobId: number }> => {
  return post<{ JobId: number }>('posv3/print/print-bytes', {
    PrinterId: printerId,
    Bytes: Array.from(bytes),
    PrinterType: printerType,
    Title: title,
  });
};
