import React from 'react';
import { ConnectionStatusPill } from 'pages/SettingsPage/Status/ConnectionStatusPill';
import { useConnectedScanner } from 'util/hooks/hardware/useConnectedHardware';
import { isNativeScanner } from '@dutchie/capacitor-peripheral';

export const ScannerStatusPill = () => {
  const { device } = useConnectedScanner();

  return (
    <ConnectionStatusPill
      automationId='scanner_settings-connection_status'
      deviceName={device?.name}
      connected={device?.isConnected === true || (device && isNativeScanner(device)) === true}
    />
  );
};
