import { useSelector } from 'react-redux';
import { logger, customEventKeys } from 'util/logger';
import { parseErrorMessage } from 'util/helpers/parseErrorMessage';

import type { PrintJobContext } from 'util/logger/types/printing';

import { successNotification, errorNotification, warningNotification } from 'store/actions/NotificationsActions';
import { setPrintJobStatus } from 'store/actions/PrintJobsActions';
import { PrintJob, PrintStatus } from 'models/Printing';
import type { State, AppDispatch } from 'store';
import type { CartItem } from 'models/Cart';
import {
  getStoredHardware,
  peripheralInfo,
  GetPrintJobResponse,
  processHardwarePrintJob,
} from 'util/hardwareLibrary/hardware-library-utils';
import { useAppDispatch } from 'util/hooks';
import { post } from 'api/HttpHelpers';
import { isPrintNodeLabelPrinter } from 'hardware/label-printer';
import { PrintNodePrinterTypes } from 'models/Misc';
import { LabelPrinter, HardwareService } from '@dutchie/capacitor-hardware';

type UsePrintLabelsParams = {
  items: CartItem[] | CartItem;
  printAll: boolean;
  shipmentId: number;
  transactionId?: string;
};

export type PrintLabelsParams = UsePrintLabelsParams & {
  labelId: number | undefined;
  registerId?: number;
  dispatch: AppDispatch;
  isAllowDefaultProductLabelsEnabled: boolean;
  isPrintNonCannabisLabelsEnabled: boolean;
};

type HardwarePrintLabelsParams = Omit<PrintLabelsParams, 'items'> & { items: CartItem[] };

const hardwarePrintLabels = async ({
  items,
  labelId,
  overrideLabelPrinter,
  printAll,
  shipmentId,
  transactionId,
}: HardwarePrintLabelsParams & { overrideLabelPrinter?: LabelPrinter }) => {
  const { labelPrinter: storedLabelPrinter } = getStoredHardware();

  const labelPrinter = overrideLabelPrinter ?? storedLabelPrinter;
  if (!labelPrinter) {
    throw new Error('No printer selected');
  }

  if (!labelId) {
    throw new Error('No label selected');
  }

  const job = await post<GetPrintJobResponse>('v2/print-jobs/get-label-job', {
    BatchId: shipmentId,
    LabelId: labelId,
    LabelType: 'ProductProd',
    PackagesToPrint: items.map((item) => ({
      AllocatedInventoryId: item.InventoryId,
      BatchId: item.BatchId,
      Count: printAll ? item.QtySelected : 1,
      PackageItemId: item.PackageItemId,
      PackageQuantity: item.WgtCnt === 'Wgt' ? (item.Grams ?? 0) / item.QtyAllocated : 1,
      SerialNumber: item.SerialNo,
      ShipmentId: transactionId ?? shipmentId,
    })),
    PrinterId: 0, // This is not used by the endpoint
    PrinterType: isPrintNodeLabelPrinter(labelPrinter) ? labelPrinter.printNodeType : PrintNodePrinterTypes.ZPL,
    ShipmentId: shipmentId,
  });
  await processHardwarePrintJob(job, labelPrinter);
};

const hardwarePrintLabelsWithProductDefaults = async (props: HardwarePrintLabelsParams) => {
  const { items, labelId, registerId } = props;

  const printLabelRequests = items.map(async (item) => {
    const itemLabelId = item.DefaultLabelId ?? labelId ?? 1;

    const printNodePrinterId = await post<number>('posv3/print/get-label-printer-for-terminal', {
      LabelId: itemLabelId,
      TerminalId: registerId,
    });

    let overridePrinter: LabelPrinter | undefined;
    if (printNodePrinterId !== null) {
      overridePrinter = HardwareService.labelPrinter.devices.find(
        (it) => isPrintNodeLabelPrinter(it) && it.printNodeId === printNodePrinterId
      );
    }

    await hardwarePrintLabels({
      ...props,
      overrideLabelPrinter: overridePrinter,
      items: [item], // Only 1 product can be sent at a time when using product/category labels. see get-label-job endpoint.
      labelId: itemLabelId,
    });
  });

  const results = await Promise.allSettled(printLabelRequests);
  const failed = results.some((result) => result.status === 'rejected');
  if (failed) {
    return Promise.reject('One or more labels failed to print');
  }
};

const printLabels = async (params: PrintLabelsParams) => {
  const { dispatch, isAllowDefaultProductLabelsEnabled, isPrintNonCannabisLabelsEnabled, items, labelId, registerId } =
    params;

  const { labelPrinter } = getStoredHardware();

  try {
    if (!registerId) {
      throw new Error('No register selected');
    }

    const itemsArray = Array.isArray(items) ? items : [items];

    const itemsToPrint = !isPrintNonCannabisLabelsEnabled
      ? itemsArray.filter((item) => item.CannbisProduct !== 'No')
      : itemsArray;

    if (itemsToPrint.length === 0) {
      dispatch(warningNotification(`Labels print for cannabis items only`));
      return;
    }

    const printRequest = { ...params, items: itemsToPrint, labelId };

    dispatch(successNotification('Printing labels...'));
    dispatch(setPrintJobStatus({ printJob: PrintJob.LABELS, status: PrintStatus.PRINTING }));

    logger.info<PrintJobContext>('print labels', {
      key: customEventKeys.printing.jobStarted,
      job: PrintJob.LABELS,
      requestParams: printRequest,
      printer: peripheralInfo(labelPrinter),
    });

    if (isAllowDefaultProductLabelsEnabled) {
      await hardwarePrintLabelsWithProductDefaults(printRequest);
    } else {
      await hardwarePrintLabels(printRequest);
    }
    dispatch(successNotification('Labels printed'));
    dispatch(setPrintJobStatus({ printJob: PrintJob.LABELS, status: PrintStatus.SUCCESSFUL }));
  } catch (e) {
    const message = parseErrorMessage(e);
    dispatch(errorNotification(`Error printing label: ${message}`));
    dispatch(setPrintJobStatus({ printJob: PrintJob.LABELS, status: PrintStatus.FAILED }));
    logger.error(e, {
      message: 'Failed to print labels',
      requestParams: params,
      printer: peripheralInfo(labelPrinter),
    });
  }
};

export const usePrintLabels = () => {
  const dispatch = useAppDispatch();
  const registerId = useSelector((state: State) => state.settings.selectedRegister?.value);
  const labelId = useSelector((state: State) => state.settings.userSettings.selectedLabel?.id);
  const isPrintNonCannabisLabelsEnabled = useSelector((state: State) => state.settings.features.PrintNonCannabisLabels);
  const isAllowDefaultProductLabelsEnabled = useSelector(
    (state: State) => state.settings.features.AllowDefaultProductLabels
  );

  return async (params: UsePrintLabelsParams) => {
    return printLabels({
      dispatch,
      isAllowDefaultProductLabelsEnabled,
      isPrintNonCannabisLabelsEnabled,
      labelId,
      registerId,
      ...params,
    });
  };
};
