import { useAppSelector } from 'util/hooks';
import { State } from 'store';
import { useBooleanLDFlag } from 'util/launchDarkly';

// Determine if the Biotrack settings feature is enabled for determining component visibility.
export const useBiotrackSettingsFeature = (): boolean => {
  const isFlagEnabled = useBooleanLDFlag('pos.register.settings-ui.biotrack-settings.rollout', false);
  const integrations = useAppSelector((state: State) => state.settings.integrations);
  const locationSettings = useAppSelector((state: State) => state.settings.locationSettings);
  const showBioTrackCredentialsMgmt = integrations?.UseBioTrack && locationSettings?.State.toLowerCase() === 'fl';
  return isFlagEnabled && !!showBioTrackCredentialsMgmt;
};
