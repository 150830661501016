import { useLDClient } from 'launchdarkly-react-client-sdk';

export const useImprovedSettingsUI = () => {
  const ldClient = useLDClient();

  const isImprovedSettingsUIEnabled = ldClient?.variation('pos.register.improved-settings-ui', false) === true;

  return {
    /** pos.register.improved-settings-ui */
    isImprovedSettingsUIEnabled,
  };
};
