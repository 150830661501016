import React from 'react';
import styled, { css } from 'styled-components';

import { breakpoints, colors } from 'css/Theme';
import { NavigationHint } from 'components/layout/NavigationHint';
import { NavigationHeader } from 'components/layout/NavigationHeader';
import { NavigationDrawer } from 'components/layout/NavigationDrawer';
import { NavigationSidebar } from 'components/layout/NavigationSidebar';
import { useNavigationWrapper } from 'components/layout/NavigationWrapper/useNavigationWrapper';
import { useResponsiveNavigationFlag } from 'util/hooks/launch-darkly/useResponsiveNavigationFlag';

import type { ReactNode } from 'react';

export type NavigationWrapperProps = {
  children: ReactNode;
};

export function NavigationWrapper(props: NavigationWrapperProps) {
  const { children } = props;

  const { isHintOpen, isDrawerOpen, openHint, closeHint, openDrawer, closeDrawer } = useNavigationWrapper();

  const isResponsiveNavigationEnabled = useResponsiveNavigationFlag();

  return (
    <Container data-testid='sidebar-navigation-container'>
      <NavigationSidebar onOpenDrawer={openDrawer} />
      <Frame isResponsiveNavigationEnabled={isResponsiveNavigationEnabled}>
        <NavigationHeader onOpenDrawer={openDrawer} onOpenHint={openHint} />
        <Content>
          <ScrollContainer>{children}</ScrollContainer>
        </Content>
      </Frame>
      <NavigationDrawer isOpen={isDrawerOpen} onCloseDrawer={closeDrawer} />
      <NavigationHint isOpen={isHintOpen} onCloseHint={closeHint} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  height: 100%;
`;

const Frame = styled.div<{ isResponsiveNavigationEnabled: boolean }>`
  background: ${colors.dutchie.opal90};
  padding: 0 16px 16px 0;
  height: 100%;
  width: 100%;

  overflow: hidden;
  display: flex;
  flex-direction: column;

  ${({ isResponsiveNavigationEnabled }) => isResponsiveNavigationEnabled && css`
    ${breakpoints.smallTablet.maxQuery} {
      padding: 0 16px 16px 16px;
    }
  `}
`;

const Content = styled.div`
  border-radius: 18px;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;

  background: white;
`;

const ScrollContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
`;
