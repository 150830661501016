import React, { FC } from 'react';
import {
  PusherChannelConfiguration,
  PusherChannelType,
} from 'models/Pusher';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PusherChannel as PusherChannelDeprecated } from './PusherChannel-deprecated';
import { usePusherChannel } from 'pusher/usePusherChannel';

export type PusherChannelProps = {
  enabled: boolean;
  channelType: PusherChannelType;
  channelConfig: PusherChannelConfiguration;
  onChannelMessage: (eventName: string, data: string) => void;
  onConnected?: () => void;
  onDisconnected?: () => void;
};

const UpdatedPusherChannelWrapper = (props: PusherChannelProps) => {
  const { enabled, onChannelMessage, onDisconnected, onConnected, channelType } = props;
  usePusherChannel({
    channelType,
    enabled,
    onChannelMessage,
    onDisconnected,
    onConnected,
  });
  return <></>;
};

/** @deprecated Clean up with usePusherChannel hook */
export const PusherChannel: FC<PusherChannelProps> = (props: PusherChannelProps) => {
  const { 'pos.register.pusher-version-8.rollout': posRegisterPusherVersion8Rollout } = useFlags();

  if (posRegisterPusherVersion8Rollout === true) {
    return <UpdatedPusherChannelWrapper {...props} />;
  }

  return <PusherChannelDeprecated {...props} />;
};
