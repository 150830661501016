import React from 'react';
import { capitalize } from 'lodash';
import styled, { css } from 'styled-components';

import { AlertBannerStyles } from 'components/misc';
import { Button } from 'components/buttons';
import { colors } from 'css/Theme';
import { ModalVariation } from 'components/modals/Modal';
import { Row } from 'components/layout';
import { SettingsAlertBanner } from 'pages/SettingsPage/Status/SettingsAlertBanner';
import { SimpleModal } from 'components/modals/SimpleModal';
import { useAddNewDevice } from './useAddNewDevice';

import type { AddNewDeviceProps } from './useAddNewDevice';

export const AddNewDeviceModal = ({ deviceType, isDeviceDetected, onAuthorize }: AddNewDeviceProps) => {
  const { deviceDescription, handleHideAddNewDeviceModal } = useAddNewDevice({
    deviceType,
    isDeviceDetected,
    onAuthorize,
  });

  return (
    <SimpleModal
      modalName='add-new-device'
      header={`Add new ${deviceDescription}`}
      variation={ModalVariation.SettingModal}
      overlayStyles={css`
        z-index: 1400;
      `}
      hide={handleHideAddNewDeviceModal}
      footer={
        <>
          <Button cta tertiary onClick={handleHideAddNewDeviceModal}>
            Cancel
          </Button>
          <Button cta onClick={handleHideAddNewDeviceModal} disabled={!isDeviceDetected}>
            Done
          </Button>
        </>
      }
    >
      <Content>
        {isDeviceDetected ? (
          <SettingsAlertBanner text={`${capitalize(deviceDescription)} detected`} style={AlertBannerStyles.success} />
        ) : (
          <SettingsAlertBanner text={`No ${deviceDescription} detected`} style={AlertBannerStyles.error} />
        )}
        <SetupInstructions>
          <SetupTitle>Instructions</SetupTitle>
          <SetupSteps>
            <li>Click "Authorize {deviceDescription}" button below.</li>
            <li>
              Authorize your connected device when prompted by your browser. You will only need to do this the first
              time you connect a new {deviceDescription}.
            </li>
          </SetupSteps>
        </SetupInstructions>
        <Row>
          <Button secondary onClick={onAuthorize}>
            Authorize {deviceDescription}
          </Button>
        </Row>
      </Content>
    </SimpleModal>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  padding: 1.5rem 2rem;
  border-top: 1px solid ${colors.dutchie.gray20};
`;

const SetupInstructions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const SetupTitle = styled.div`
  color: ${colors.dutchie.gray70};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
`;

const SetupSteps = styled.ol`
  padding-left: 1.5rem;
`;
