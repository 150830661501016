import { HardwareService, ReceiptPrinter } from '@dutchie/capacitor-hardware';
import { useAppDispatch, useAppSelector } from '../useAppDispatch';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { useHardwareLibrary } from '../launch-darkly/useHardwareLibrary';
import moment from 'moment';
import { getIsStarGraphicSupportEnabled } from '../launch-darkly/useStarGraphicSupport';
import { setPrintJobStatus } from 'store/actions/PrintJobsActions';
import { PrintJob, PrintStatus } from 'models/Printing';
import { logger, customEventKeys } from 'util/logger';
import { PrintJobContext } from 'util/logger/types/printing';
import {
  peripheralInfo,
  GetPrintJobResponse,
  selectHardwarePrinterType,
  processHardwarePrintJob,
} from 'util/hardwareLibrary/hardware-library-utils';
import { post } from 'api/HttpHelpers';
import { PrintNodePrinterTypes, PrintNodePrinterType } from 'models/Misc';
import { parseErrorMessage } from 'util/helpers/parseErrorMessage';

export const useTestReceipts = () => {
  const isHardwareLibraryActive = useHardwareLibrary();
  const dispatch = useAppDispatch();

  const locationName = useAppSelector((state) => state.user.selectedLocation?.location_name) ?? 'Location';
  const registerName = useAppSelector((state) => state.settings.selectedRegister?.label) ?? 'Register';
  const userName = useAppSelector((state) => state.user.FullName) ?? 'User';

  const buildTestPrint = (printer: ReceiptPrinter, content: string): Uint8Array => {
    const { encoder } = printer;
    let bytes: number[] = [...encoder.initialize];

    const byteLength = content.length;
    for (let x = 0; x < byteLength; x++) {
      bytes.push(content.charCodeAt(x));
    }

    bytes = bytes.concat([
      ...encoder.newline,
      ...encoder.newline,
      ...encoder.newline,
      ...encoder.newline,
      ...encoder.newline,
      ...encoder.newline,
      ...encoder.lines,
      ...encoder.partialCut,
      ...encoder.endStream,
    ]);

    return Uint8Array.from(bytes);
  };

  const testPrint = async (printerId: string, content: string) => {
    if (!isHardwareLibraryActive) {
      dispatch(errorNotification('Hardware library must be active'));
    }

    const printer = HardwareService.receiptPrinter.deviceById(printerId);
    if (!printer) {
      dispatch(errorNotification('Printer not found'));
      return;
    }

    try {
      const bytes = buildTestPrint(printer, content);
      const success = await printer.print(bytes);

      if (!success) {
        throw Error('Print failed');
      }
    } catch (e) {
      dispatch(errorNotification('Test print failed'));
    }
  };

  const formatDate = (date: Date, format = 'M/D/YYYY h:mm:ssA') => {
    return moment(date).format(format);
  };

  const getUseNewTestPrintCommands = (printerId: string) => {
    const newPrinterTypes: PrintNodePrinterType[] = [PrintNodePrinterTypes.STAR_PRINT, PrintNodePrinterTypes.EPSON];

    if (getIsStarGraphicSupportEnabled()) {
      newPrinterTypes.push(PrintNodePrinterTypes.STAR_GRAPHIC);
    }

    const printer = HardwareService.receiptPrinter.deviceById(printerId);
    if (!printer) {
      return false;
    }

    const printerType = selectHardwarePrinterType(printer);
    return newPrinterTypes.includes(printerType);
  };

  const printTestReceiptThroughApi = async (id: string | undefined) => {
    let printer: ReceiptPrinter | undefined;
    try {
      if (!id) {
        throw new Error('No printer set');
      }

      printer = HardwareService.receiptPrinter.deviceById(id);
      if (!printer) {
        throw new Error('Printer configuration not found');
      }

      dispatch(successNotification('Printing receipt...'));
      dispatch(setPrintJobStatus({ printJob: PrintJob.TEST_PRINT, status: PrintStatus.PRINTING }));

      logger.info<PrintJobContext>('print receipt', {
        key: customEventKeys.printing.jobStarted,
        job: PrintJob.TEST_PRINT,
        requestParams: {},
        printer: peripheralInfo(printer),
      });

      const job = await post<GetPrintJobResponse>('register/print/get-test-print-job', {
        PopCashDrawer: false, // Test prints should never pop the drawer. This is also hardcoded in the endpoint.
        PrinterId: 0, // This is not used by the endpoint, but satisfies the base request type.
        PrinterType: selectHardwarePrinterType(printer),
      });
      await processHardwarePrintJob(job, printer);

      dispatch(successNotification('Receipt printed'));
      dispatch(setPrintJobStatus({ printJob: PrintJob.RECEIPT, status: PrintStatus.SUCCESSFUL }));
    } catch (e) {
      const message = parseErrorMessage(e);
      dispatch(errorNotification(`Error printing receipt: ${message}`));
      dispatch(setPrintJobStatus({ printJob: PrintJob.RECEIPT, status: PrintStatus.FAILED }));
      logger.error(e, {
        message: 'Failed to print receipt',
        printer: peripheralInfo(printer),
      });
    }
  };

  const printPickList = async (printerId: string) => {
    if (getUseNewTestPrintCommands(printerId)) {
      printTestReceiptThroughApi(printerId);
      return;
    }
    const date = formatDate(new Date(), 'M/D/YYYY');
    const TEST_PICK_LIST = `OrderNo: 2545941\nCustomer: 1557550\nNew Pickup Order\nStartWindow: ${date}\nEndWindow: ${date}\n\nItem \n_________________________\nProduct Name - 784a87\n1.0000 Gram \n_________________________\nProduct Flower - 762c51\n1.0000 Quantity \n_________________________\nEdible product - 9cl473\n1.0000 Quantity \n_________________________\n`;
    testPrint(printerId, TEST_PICK_LIST);
  };

  const printReceipt = async (printerId: string) => {
    if (getUseNewTestPrintCommands(printerId)) {
      printTestReceiptThroughApi(printerId);
      return;
    }
    const TEST_RECEIPT = `Example Receipt\n${locationName}\nCity, State\n\n${formatDate(
      new Date()
    )}\nOrder: 2545937\nCashier: ${userName}\nPatient: 1557545\n\n\n\nProduct Name - 123456 (2.00g)\n  Batch: Package ID - 654321\nUnit Price                               20.00\n\nProduct Name - flower (3.00g)\n  Batch: Batch name\nUnit Price                               10.00\n\n\n\n\nSubtotal: $30.00\nSales Tax: $0.00\nTotal Discount: $0.00\n_________________________\nTotal: $30.00\n\n\nDue Customer: $0\n\nTotal Items: 2\n\nLoyalty Points Earned: 0.00\nLoyalty Points Used: 0.00\nLoyalty Points Total: 0.00000\n\n\n\n\nSignature: ___________________________________\n\n`;
    testPrint(printerId, TEST_RECEIPT);
  };

  const printZReport = async (printerId: string) => {
    if (getUseNewTestPrintCommands(printerId)) {
      printTestReceiptThroughApi(printerId);
      return;
    }

    const date2 = new Date();
    // subtract an hour
    const date1 = new Date(date2.getTime() - 1000 * 60 * 60 * 6);

    const TEST_ZREPORT = `Example Z Report\n${locationName}\n${registerName}\nCashier ID: 0\n${formatDate(
      date1
    )} - ${formatDate(
      date2
    )}\n\n\n_______________________________________________\n\nIncome summary\n\n  Gross sales: $10.00\n  Discounts: ($0.00)\n  Loyalty: ($0.00)\n  Returns: ($0.00)\n  Revenue fees: $0.00\n  ____________________\n  Net sales: $10.00\n  Total tax: $0.00\n  Non-revenue donations: $0.00\n  ____________________\n  Returns: $0.00\n  Subtotal: $10.00\n  Rounding: ($0.00)\n  ____________________\n  Total: $10.00\n\nProfit and loss summary\n  Net sales: $10.00\n  Cost of goods: ($0.00)\n  ____________________\n  Gross profit: $10.00\n  Gross margin: 100.00%\n\n_______________________________________________\n\nPayment summary\n\n  Cash: $10.00\n  Total Payments: $10.00\n\nCash activity\n  Starting balance: $0.00\n  Cash payments: $10.00\n  Cash deposits: ($10.00)\n  Adjustments (+/-): $0.00\n  Over/Short (+/-): ($0.00)\n  ____________________\n  Ending balance: $0.00\n\n_______________________________________________\n\nOther summaries\n\nCustomer summary\n  Recreational: $10.00\n     Cannabis: $10.00\n     Non-cannabis: $0.00\n\nTransaction summary\n  Cannabis net sales: $10.00\n  Non-cannabis net sales: $0.00\n  Transactions: 1\n  Single-item Transactions: 1\n  Multiple-item Transactions: 0\n  Customers: 1\n  New customers: 0\n  Items sold: 1\n  Avg $ / Cart: $10.00\n  Voids: 0`;
    testPrint(printerId, TEST_ZREPORT);
  };

  return {
    printPickList,
    printReceipt,
    printZReport,
  };
};
