import React from 'react';

import { AddNewDeviceModal } from './AddNewDeviceModal';
import { SettingsCard } from 'pages/SettingsPage/SettingsCard';
import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { useAddNewDevice } from './useAddNewDevice';
import { useImprovedSettingsUI } from 'util/hooks/launch-darkly/useImprovedSettingsUI';

import type { AddNewDeviceProps } from './useAddNewDevice';

export const AddDeviceListItem = (props: AddNewDeviceProps) => {
  const { addNewDeviceModalProps, deviceDescription, handleShowAddNewDeviceModal, isSupported } =
    useAddNewDevice(props);

  const { isImprovedSettingsUIEnabled } = useImprovedSettingsUI();

  if (!isSupported || !isImprovedSettingsUIEnabled) {
    return null;
  }

  return (
    <SettingsCard>
      <SettingsListItem
        title={`Add new ${deviceDescription}`}
        subtitle={`Authorize a new ${deviceDescription} to connect to the register`}
        onClick={handleShowAddNewDeviceModal}
      />
      {addNewDeviceModalProps && <AddNewDeviceModal {...addNewDeviceModalProps} />}
    </SettingsCard>
  );
};
