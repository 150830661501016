import { SyntheticEvent, useState } from 'react';
import { useHistory } from 'react-router';
import { compareAsc } from 'date-fns';

import { errorNotification, successNotification, warningNotification } from 'store/actions/NotificationsActions';
import { useAppDispatch } from 'util/hooks';
import { useVerifyBirthdateMutation } from 'queries/v2/cart/verify-customer-birthdate';

import type { VerifyBirthdateModalProps } from './VerifyBirthdateModal';

export const useVerifyBirthdateModal = ({
  guestDob,
  guestId,
  hide,
  isGuestAnonymous = true,
  onSuccess,
  shipmentId,
}: VerifyBirthdateModalProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const isCartPage = history.location.pathname.includes('/cart');

  const [birthdate, setBirthdate] = useState<Date | null>(null);

  const { mutateAsync: verifyBirthdate, isLoading: isVerifyingBirthdate } = useVerifyBirthdateMutation();

  const validateBirthdate = () => {
    const guestDobDate = guestDob ? new Date(guestDob) : undefined;

    if (guestDobDate) {
      guestDobDate.setHours(0, 0, 0, 0);
    }

    if (isGuestAnonymous) {
      // We can't validate the birthdate against an anonymous guest
      // since there is not profile to compare it to
      return true;
    } else if (!birthdate) {
      dispatch(warningNotification('Please enter a birthdate'));
      return false;
    } else if (!guestDob) {
      dispatch(warningNotification('No birthdate set on customer profile'));
      return false;
    } else if (guestDobDate && compareAsc(birthdate, guestDobDate) !== 0) {
      dispatch(errorNotification('Birthdate does not match customer profile'));
      return false;
    } else {
      return true;
    }
  };

  // Handlers

  const handleChangeBirthdate = (date: Date | null, event: SyntheticEvent<unknown>) => setBirthdate(date);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && birthdate && !isVerifyingBirthdate) {
      onSubmit();
    }
  };

  const onCancel = () => {
    hide();
    // If the user is on the cart page when they cancel entering a birthdate,
    // redirect them back to the guest list
    if (isCartPage) {
      history.push('/guestlist');
    }
  };

  const onSubmit = async () => {
    if (!birthdate || !validateBirthdate()) {
      return;
    }

    try {
      await verifyBirthdate({ birthdate, guestId, shipmentId });
      hide();
      onSuccess(birthdate);
      dispatch(successNotification('Birthdate successfully verified'));
    } catch (e) {
      dispatch(errorNotification('Customer does not meet age requirements'));
    }
  };

  return {
    birthdate,
    handleChangeBirthdate,
    handleKeyDown,
    isSubmitDisabled: !birthdate || isVerifyingBirthdate,
    onCancel,
    onSubmit,
  };
};
