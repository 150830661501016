import React from 'react';
import styled from 'styled-components';

import { breakpoints, colors } from 'css/Theme';
import { SwitchIcon } from 'assets/icons/navigation/switch';
import { LogoutIcon } from 'assets/icons/navigation/logout';
import { HamburgerIcon } from 'assets/icons/navigation/hamburger';

import { useNavigationHeader } from 'components/layout/NavigationHeader/useNavigationHeader';
import { useResponsiveNavigationFlag } from 'util/hooks/launch-darkly/useResponsiveNavigationFlag';

export type NavigationHeaderProps = {
  onOpenDrawer: () => void;
  onOpenHint: () => void;
};

export function NavigationHeader(props: NavigationHeaderProps) {
  const { onOpenDrawer, onOpenHint } = props;
  const { ref, isPinLoginActive, companyName, locationName, registerName, userName, handleMenuButtonClick, handleLogOutClick } =
    useNavigationHeader({
      onOpenDrawer,
      onOpenHint,
    });
    const isResponsiveNavigationEnabled = useResponsiveNavigationFlag();

  return (
    <Container data-testid='navigation-header-container'>
      {isResponsiveNavigationEnabled && <MenuButton onClick={handleMenuButtonClick}><HamburgerIcon /></MenuButton>}
      <ContextContainer ref={ref}>
        <ContextLabel data-testid='navigation-header-company-name'>{companyName}</ContextLabel>
        <ContextLabel data-testid='navigation-header-location-name'>{locationName}</ContextLabel>
        <ContextLabel data-testid='navigation-header-register-name'>{registerName}</ContextLabel>
      </ContextContainer>
      <LogoutButton data-testid='navigation-header-logout-button'>
        {isPinLoginActive ? (
          <SwitchIcon automationId='navigation-header-switch-icon' />
        ) : (
          <LogoutIcon automationId='navigation-header-logout-icon' />
        )}
        <LogoutButtonText onClick={handleLogOutClick}>{userName}</LogoutButtonText>
      </LogoutButton>
    </Container>
  );
}

const Container = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  background: ${colors.dutchie.opal90};
`;

const MenuButton = styled.button`
  appearance: none;
  padding: 0;
  border: 0;
  background: none;
  margin-right: auto;
  cursor: pointer;
  display: none;

  ${breakpoints.smallTablet.maxQuery} {
    display: block;
  }
`;

const ContextContainer = styled.div`
  display: flex;
`;

const ContextLabel = styled.span`
  color: ${colors.dutchie.lightGrey};
  font-size: 13px;
  font-weight: 600;

  &:after {
    color: ${colors.dutchie.purple};
    margin: 0 12px;
    content: '|';
  }
`;

const LogoutButton = styled.button`
  appearance: none;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: none;
  padding: 0 8px;
  border: none;
  border-radius: 6px;
  background: ${colors.dutchie.opal60};
  color: ${colors.dutchie.lightGrey};
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
`;

const LogoutButtonText = styled.span`
  margin: 0 0 3px 8px;
`;
