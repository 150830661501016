import React, { useCallback, useMemo } from 'react';
import { HardwareService, isHidScanner, PeripheralType } from '@dutchie/capacitor-hardware';

import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { useScannerSettings } from '../hooks/useScannerSettings';
import { SettingsSelect } from 'pages/SettingsPage/Select/SettingsSelect';
import { useAddNewDevice } from '../../components/AddDeviceListItem/useAddNewDevice';
import { useHardwareServices } from '../../hooks/useHardwareServices';
import { useImprovedSettingsUI } from 'util/hooks/launch-darkly/useImprovedSettingsUI';

import type { SettingsSelectOption } from 'pages/SettingsPage/Select/SettingsSelect';

export const ScannerSelectListItem = () => {
  const { formValues, options, scanners, search, selectScanner } = useScannerSettings();
  const { isWebHidSupported } = useHardwareServices();

  const handleChange = (option?: SettingsSelectOption) => {
    selectScanner(option?.value);
  };

  const hasHidScanners = useMemo(() => {
    return scanners.filter(isHidScanner).length > 0;
  }, [scanners]);

  const handleAuthorize = useCallback(() => {
    search({ requestNewDevices: true });
  }, [search]);

  const handleRefresh = async () => {
    HardwareService.scanner.filterEnabled = true;
    search({ requestNewDevices: true });
  };

  const handleShowAll = async () => {
    HardwareService.scanner.filterEnabled = false;
    search({ requestNewDevices: true });
  };

  const { handleShowAddNewDeviceModal } = useAddNewDevice({
    deviceType: PeripheralType.scanner,
    isDeviceDetected: hasHidScanners,
    onAuthorize: handleAuthorize,
  });

  const { isImprovedSettingsUIEnabled } = useImprovedSettingsUI();

  return (
    <SettingsListItem
      title={isImprovedSettingsUIEnabled ? 'Select barcode scanner' : 'Barcode scanner'}
      subtitle={isImprovedSettingsUIEnabled ? undefined : 'Select barcode scanner for all scans'}
      actions={[
        <SettingsSelect
          automationId='scanner_settings-select'
          devices={scanners}
          value={formValues.scannerId}
          placeholder='Select scanner'
          onChange={handleChange}
          onRefresh={handleRefresh}
          onShowAll={isWebHidSupported ? handleShowAll : undefined}
          options={options}
          secondaryAction={
            isWebHidSupported ? { label: 'Add new scanner', onClick: handleShowAddNewDeviceModal } : undefined
          }
        />,
      ]}
    />
  );
};
