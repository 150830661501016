export enum PrintJob {
  CART = 'cart',
  LABELS = 'labels',
  PICK_TICKET = 'pickTicket',
  RECEIPT = 'receipt',
  REGALA_RECEIPT = 'regalaReceipt',
  TEST_PRINT = 'test-print',
  Z_REPORT = 'zReport',
}

export enum PrintStatus {
  IDLE = 'idle',
  PRINTING = 'printing',
  SUCCESSFUL = 'successful',
  FAILED = 'failed',
}

export enum PrintPreviewJob {
  LABELS = 'labels',
  MANIFEST = 'manifest',
}

export enum PrintPreviewStatus {
  IDLE = 'idle',
  GENERATING = 'generating',
  SUCCESSFUL = 'successful',
  FAILED = 'failed',
}
