import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import hidSetupBarcode from 'assets/images/zebra/zebra-hid-ibm.jpg';
import zebraFactoryReset from 'assets/images/zebra/zebra-factory-reset.jpg';
import zebraUsbSnapi from 'assets/images/zebra/zebra-usb-snapi.jpg';
import zebraDisableKeypadEmulation from 'assets/images/zebra/zebra-disable-keypad-emulation.png';
import { useHardwareServices } from '../../../hooks/useHardwareServices';
import { isAndroid, isWebViewApp } from 'util/hooks';
import { getZebraScannerBluetoothPairingImage } from 'util/hooks/webviewApp';

export enum ScannerBarcodeType {
  reset,
  configure,
}

type ScannerBarcodeProps = {
  aspectRatio?: number;
  maxHeight?: React.CSSProperties['maxHeight'];
  src: string;
  title?: string;
};

export const ConfigurationBarcode = ({ type }: { type: ScannerBarcodeType }) => {
  const { isWindows, isWebHidSupported } = useHardwareServices();
  const [info, setInfo] = useState<{ src: string; title: string } | undefined>();

  useEffect(() => {
    if (isWebViewApp && type === ScannerBarcodeType.configure) {
      getZebraScannerBluetoothPairingImage().then((src) => {
        if (!src) {
          return;
        }

        setInfo({
          title: 'Bluetooth Pairing',
          src,
        });
        return;
      });
    }

    switch (type) {
      case ScannerBarcodeType.reset:
        setInfo({
          title: 'Factory Reset',
          src: zebraFactoryReset,
        });
        break;
      case ScannerBarcodeType.configure:
        if (isAndroid) {
          setInfo({
            title: 'Native USB',
            src: zebraUsbSnapi,
          });
        } else if (isWebHidSupported) {
          setInfo({
            title: 'Web HID',
            src: hidSetupBarcode,
          });
        } else if (!isWindows) {
          setInfo({
            title: 'Disable Keypad Emulation',
            src: zebraDisableKeypadEmulation,
          });
        }

        break;
    }
  }, [isWebHidSupported, isWindows, type]);

  if (!info) {
    return null;
  }

  return <ScannerBarcode maxHeight={61} src={info.src} title={info.title} />;
};

export const ScannerBarcode = ({ aspectRatio, maxHeight, src, title }: ScannerBarcodeProps) => {
  return (
    <Container title={title}>
      <Code src={src} aspectRatio={aspectRatio} maxHeight={maxHeight} />
    </Container>
  );
};

const Container = styled.div`
  display: inline-block;
`;

const Code = styled.div<ScannerBarcodeProps>`
  aspect-ratio: ${(props) => props.aspectRatio ?? 3};
  background: url(${(props) => props.src}) no-repeat left top;
  background-size: contain;
  overflow: hidden;
  width: ${(props) => (props.aspectRatio === 1 ? '200px' : '300px')};

  ${(props) =>
    props.maxHeight &&
    `
    max-height: ${props.maxHeight}px;
  `}
`;
