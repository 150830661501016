import React from 'react';
import { SetupInstructions } from './SetupInstructions';

export const ConnectStep = () => {
  return (
    <SetupInstructions
      steps={[
        <div>Ensure cable is inserted into the scanner.</div>,
        <div>Plug the cable into a USB port into the register device.</div>,
      ]}
    />
  );
};
