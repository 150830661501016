import { useState, useMemo, useCallback } from 'react';
import { find } from 'lodash';
import { useFormikContext } from 'formik';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { CustomerTypeIds } from 'models/Settings';
import { EditAllotmentTypeEnum, ReferralSourceIds } from 'models/Customer';
import { GenderOptions, UpdatedGenderOptions } from 'components/inputs/defaults';
import { medicalCustomerTypes } from 'util/Helpers';
import { removeAddressBookItem } from 'store/actions/CustomerActions';
import { successNotification } from 'store/actions/NotificationsActions';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { useCuraleafLoyaltyTier } from 'util/hooks/launch-darkly/useCuraleafLoyaltyTier';
import { useIsDaysSupplyFeatureEnabled } from 'util/hooks/features/useIsDaysSupplyFeatureEnabled';
import { useDynamicYearRange } from 'util/helpers/date-helpers/getDynamicYearRange';
import { useGetCartDetailsQuery } from 'queries/v2/cart/load';
import { useNewGenderOptionsEnabled } from 'util/hooks/launch-darkly/useNewGenderOptionsEnabled';
import { usePopup } from 'components/popups';
import { useSecondaryIdentificationQuery } from 'queries/v2/guest/secondary-identification';
import { validateBiotrackUser } from 'api/GuestApi';

import type { CustomerAddress, CustomerDetails } from 'models/Customer';
import type { PersonalTabProps } from './PersonalTab';
import type { SelectOption } from 'components/inputs';

// Constants
const maxAddresses = 4;
const maxDiscountGroups = 6;

export const usePersonalTab = ({ customer, fieldOptions, setCustomerTypeId, validationSchema }: PersonalTabProps) => {
  const dispatch = useAppDispatch();
  const ldClient = useLDClient();

  // LD flags

  const isCuraleafLoyaltyTierEnabled = useCuraleafLoyaltyTier();
  const isMMCEUEnabled = ldClient?.variation('pos.register.enable-mmceu-calculator.rollout', false);
  const isUpdatedGenderOptionsEnabled = useNewGenderOptionsEnabled();

  // Local state

  const [allotmentEditType, setAllotmentEditType] = useState<EditAllotmentTypeEnum>(EditAllotmentTypeEnum.Max);
  const [deletedAddressIndex, setDeletedAddressIndex] = useState<number | null>(null);

  // Global state

  const areReferralSourcesLoading = useAppSelector((state) => state.customer.options.referralSourcesLoading);
  const customerTypes = useAppSelector((state) => state.settings.customerTypes);
  const discountGroups = useAppSelector((state) => state.customer.availableGroups);
  const isAllowAdjustCaregiverLoyaltyPointsEnabled = useAppSelector(
    (state) => state.settings.features.AllowAdjustCaregiverLoyaltyPoints
  );
  const isBioTrackEnabled = useAppSelector((state) => state.settings.integrations?.UseBioTrackPOS);
  const isChangeCustomerTypeInCartEnabled = useAppSelector((state) => state.settings.features.ChangeCustomerTypeInCart);
  const isCollectAnonymousDemoEnabled = useAppSelector((state) => state.settings.features.CollectAnonymousDemo);
  const isConnecticutLocation = useAppSelector(
    (state) => state.settings.locationSettings?.State.toLowerCase() === 'ct'
  );
  const isCRMEnabled = useAppSelector((state) => state.settings.features.UseCRM);
  const isDaysSupplyCalculatorEnabled = useIsDaysSupplyFeatureEnabled();
  const isMETRCUpdatePatientsEnabled = useAppSelector((state) => state.settings.features.EnableMETRCUpdatePatients);
  const isExternalLoyaltyEnabled = useAppSelector((state) => state.settings.features.ShowExternalLoyalty);
  const isLeafLogixLoyaltyEnabled = useAppSelector((state) => state.settings.features.ShowLeafLogixLoyalty);
  const isMaskDriversLicenseIDEnabled = useAppSelector((state) => state.settings.features.MaskDriversLicenseID);
  const isMetrcEnabled = useAppSelector((state) => state.settings.integrations?.UseMETRC);
  const isMississippiLocation = useAppSelector(
    (state) => state.settings.locationSettings?.State.toLowerCase() === 'ms'
  );
  const isShowAllotmentValidDatesEnabled = useAppSelector((state) => state.settings.features.ShowAllotmentValidDates);
  const isThroughMMJIdExpiryEnabled = useAppSelector((state) => state.settings.features.ThroughMMJIdExpiry);
  const referralSources = useAppSelector((state) => state.customer.options.referralSources);

  // Permissions

  const hasEditCustomerPermission = useAppSelector((state) => state.settings.permissions.EditCustomers);
  const hasEditDiscountGroupPermission = useAppSelector(
    (state) => state.settings.permissions.EditDiscountGroupCustomers
  );

  // Hooks

  const driversLicenseYearRange = useDynamicYearRange({ numberOfYearsAhead: 20 });
  const { data: isOrderInProgress } = useGetCartDetailsQuery(
    { guestId: customer?.Guest_id ?? 0, shipmentId: customer?.ShipmentId ?? 0 },
    { select: (cartDetails) => cartDetails.cartItems.length > 0 }
  );
  const { data: secondaryIdentifications } = useSecondaryIdentificationQuery({ guestId: customer?.Guest_id ?? 0 });

  // Formik

  const { values, setFieldValue } = useFormikContext<CustomerDetails>();

  const isFieldRequired = useCallback(
    (fieldName: string) => {
      const fieldProperties = validationSchema?.fields[fieldName];
      if (fieldProperties && 'tests' in fieldProperties) {
        return fieldProperties.tests.some(({ name }) => name === 'required');
      }
      return false;
    },
    [validationSchema]
  );

  const isNestedFieldRequired = useCallback(
    (parentFieldName: string, childFieldName: string) => {
      const parentFieldProperties = validationSchema?.fields[parentFieldName];
      if (parentFieldProperties && 'fields' in parentFieldProperties) {
        const childFieldProperties = parentFieldProperties.fields[childFieldName];
        if (childFieldProperties && 'tests' in childFieldProperties) {
          return childFieldProperties.tests.some(({ name }) => name === 'required');
        }
      }
      return false;
    },
    [validationSchema]
  );

  const isFieldHidden = useCallback(
    (fieldName: string) => {
      return fieldOptions?.[fieldName]?.Hidden ?? false;
    },
    [fieldOptions]
  );

  // Popups

  const { isVisible: isLoyaltyPopupVisible, toggle: toggleLoyaltyPopup } = usePopup();
  const { isVisible: isAllotmentPopupVisible, toggle: toggleAllotmentPopup } = usePopup();
  const { isVisible: isOverridePopupVisible, toggle: toggleOverridePopup } = usePopup();
  const { isVisible: isExternalPatientAllotmentPopupVisible, toggle: toggleExternalPatientAllotmentPopup } = usePopup();
  const { isVisible: isIdentificationPopupVisible, toggle: toggleIdentificationPopup } = usePopup();
  const { isVisible: isDaysSupplyCalcVisible, toggle: toggleDaysSupplyCalc } = usePopup();
  const { isVisible: isDeleteAddressPopupVisible, toggle: toggleDeleteAddressPopup } = usePopup();

  // Memoized values

  const canAdjustLoyalty = useMemo(() => {
    if (customer?.IsAnonymous) {
      return false;
    }

    // check if the medical ID is prefixed with 'cg' indicating it is a caregiver ID
    const caregiverIDRegex = /^cg.*/i;
    if (caregiverIDRegex.test(customer?.MJStateIDNo ?? '') && !isAllowAdjustCaregiverLoyaltyPointsEnabled) {
      return false;
    }

    return true;
  }, [customer, isAllowAdjustCaregiverLoyaltyPointsEnabled]);

  const isMMJOutOfState = useMemo(
    () => Number(values.CustomerTypeId) === CustomerTypeIds.MedicalOutOfState,
    [values.CustomerTypeId]
  );
  const isOtherReferralSourceSelected = useMemo(
    () => Number(values.ReferralSourceId) === ReferralSourceIds.Other,
    [values.ReferralSourceId]
  );
  const showMedicalInfo = useMemo(() => Number(values.CustomerTypeId) in medicalCustomerTypes, [values.CustomerTypeId]);

  // Computed values

  const addresses = values.AddressBook ?? [];
  const customerImage = find(values.identifications, 'image_url')?.image_url;
  const customerTypeOptions =
    customerTypes?.reduce((acc, customerType) => {
      if (customerType.IsRetail && !customerType.IsMedical) {
        acc.push({ label: customerType.CustomerType, value: String(customerType.CustomerTypeId) });
      } else if (!customer?.IsAnonymous) {
        acc.push({ label: customerType.CustomerType, value: String(customerType.CustomerTypeId) });
      }
      return acc;
    }, [] as SelectOption[]) ?? [];
  const discountGroupOptions: SelectOption[] = discountGroups.map((group) => ({
    label: group.DiscountDescription,
    value: String(group.Id),
  }));
  const genderOptions = isUpdatedGenderOptionsEnabled ? UpdatedGenderOptions : GenderOptions;
  const isAddNewAddressDisabled = addresses.length >= maxAddresses;
  const isAddNewDiscountGroupDisabled = !hasEditDiscountGroupPermission || values.discounts.length >= maxDiscountGroups;
  const isCanadaLocation = values.address.Country_Code === 'CA';
  const isCustomerTypeDisabled = isOrderInProgress || (customer?.IsAnonymous && !isCollectAnonymousDemoEnabled);
  const customerTypeMessage = isCustomerTypeDisabled
    ? isChangeCustomerTypeInCartEnabled
      ? 'Changing the customer type while a cart is in progress can only be done in the cart.'
      : 'Enable "change customer type in cart" in location settings to change customer type in the cart while an order is in progress.'
    : undefined;
  const mjExpirationToolTip = `Valid through ${
    isThroughMMJIdExpiryEnabled ? '11:50pm' : '12:01am'
  } the date of expiration`;
  const referralSourceOptions: SelectOption[] = referralSources.map((source) => ({
    label: source.ReferralSource,
    value: String(source.ReferralSourceId),
  }));
  const selectedDiscountGroups = values.discounts;
  const showNewAllotmentFields =
    isConnecticutLocation && showMedicalInfo && !customer?.AllotmentData.CurrentAllotmentOverriden;
  const useMississippiUnits = isMississippiLocation && isMMCEUEnabled;

  // Handlers

  const addAddressBookItem = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    const blankAddress: CustomerAddress = {
      street: '',
      city: '',
      state: '',
      postal_code: '',
      Country_Code: '',
    };
    setFieldValue('AddressBook', values.AddressBook ? [...values.AddressBook, blankAddress] : [blankAddress]);
  };

  const deleteAddressBookItem = (index: number | null) => async () => {
    if (index === null) {
      return;
    }

    const nextAddressBook = [...values.AddressBook];
    const [deletedItem] = nextAddressBook.splice(index, 1);
    const AddressId = deletedItem?.AddressId;
    const GuestId = customer?.Guest_id;

    if (AddressId && GuestId) {
      await dispatch(removeAddressBookItem({ AddressId, GuestId }));
    }

    setFieldValue('AddressBook', nextAddressBook);
  };

  const addDiscountGroup = () => {
    setFieldValue('discounts', [...values.discounts, {}]);
  };

  const deleteDiscountGroup = (index: number) => {
    const nextDiscounts = values.discounts.filter((_item, itemIdx) => itemIdx !== index);
    setFieldValue('discounts', nextDiscounts);
  };

  const checkBiotrack = async () => {
    try {
      await validateBiotrackUser({
        PatientScan: customer?.MJStateIDNo ?? '',
      });
      dispatch(successNotification('Validated'));
    } catch {
      dispatch(successNotification('Validation failed'));
    }
  };

  const internalDispensed = () => {
    // TotalUsed = internal + external, so subtract external
    return (customer?.AllotmentData?.TotalUsed ?? 0) - (customer?.AllotmentData?.ExternalDispensed ?? 0);
  };

  const showAllotmentPopup = (type: EditAllotmentTypeEnum) => {
    if (!isAllotmentPopupVisible) {
      setAllotmentEditType(type);
      toggleAllotmentPopup();
    }
  };

  const showClearOverridePopup = (type: EditAllotmentTypeEnum) => {
    if (!isOverridePopupVisible) {
      setAllotmentEditType(type);
      toggleOverridePopup();
    }
  };

  const showDeleteAddressPopup = (index: number) => {
    if (!isDeleteAddressPopupVisible) {
      setDeletedAddressIndex(index);
      toggleDeleteAddressPopup();
    }
  };

  const handleChangeCustomerType = (newValue: string) => {
    setCustomerTypeId(newValue);
  };

  return {
    addAddressBookItem,
    addDiscountGroup,
    addresses,
    allotmentEditType,
    areReferralSourcesLoading,
    canAdjustLoyalty,
    checkBiotrack,
    customerImage,
    customerTypeMessage,
    customerTypeOptions,
    deleteAddressBookItem,
    deletedAddressIndex,
    deleteDiscountGroup,
    discountGroupOptions,
    driversLicenseYearRange,
    genderOptions,
    handleChangeCustomerType,
    hasEditCustomerPermission,
    hasEditDiscountGroupPermission,
    internalDispensed,
    isAddNewAddressDisabled,
    isAddNewDiscountGroupDisabled,
    isAllotmentPopupVisible,
    isBioTrackEnabled,
    isCanadaLocation,
    isCollectAnonymousDemoEnabled,
    isCRMEnabled,
    isCuraleafLoyaltyTierEnabled,
    isCustomerTypeDisabled,
    isDaysSupplyCalculatorEnabled,
    isDaysSupplyCalcVisible,
    isDeleteAddressPopupVisible,
    isExternalLoyaltyEnabled,
    isExternalPatientAllotmentPopupVisible,
    isFieldHidden,
    isFieldRequired,
    isIdentificationPopupVisible,
    isLeafLogixLoyaltyEnabled,
    isLoyaltyPopupVisible,
    isMaskDriversLicenseIDEnabled,
    isMetrcEnabled,
    isMETRCUpdatePatientsEnabled,
    isMMJOutOfState,
    isNestedFieldRequired,
    isOtherReferralSourceSelected,
    isOverridePopupVisible,
    isShowAllotmentValidDatesEnabled,
    mjExpirationToolTip,
    referralSourceOptions,
    secondaryIdentifications,
    selectedDiscountGroups,
    showAllotmentPopup,
    showClearOverridePopup,
    showDeleteAddressPopup,
    showMedicalInfo,
    showNewAllotmentFields,
    toggleAllotmentPopup,
    toggleDaysSupplyCalc,
    toggleDeleteAddressPopup,
    toggleExternalPatientAllotmentPopup,
    toggleIdentificationPopup,
    toggleLoyaltyPopup,
    toggleOverridePopup,
    useMississippiUnits,
  };
};
