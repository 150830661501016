import { Peripheral } from '@dutchie/capacitor-peripheral';
import { logger, customEventKeys } from 'util/logger';
import { parseErrorMessage } from 'util/helpers/parseErrorMessage';

import type { PrintJobContext } from 'util/logger/types/printing';

import { successNotification, errorNotification } from 'store/actions/NotificationsActions';
import { setPrintJobStatus } from 'store/actions/PrintJobsActions';

import { PrintJob, PrintStatus } from 'models/Printing';

import {
  getStoredHardware,
  peripheralInfo,
  GetPrintJobResponse,
  processHardwarePrintJob,
  selectHardwarePrinterType,
} from 'util/hardwareLibrary/hardware-library-utils';
import { useAppDispatch, isAndroid } from 'util/hooks';
import { post } from 'api/HttpHelpers';
import { AppDispatch } from 'store';

export type UsePrintReceiptParams = {
  popCashDrawer: boolean;
  printerType?: string;
  receiptParameters: number;
  receiptType: string;
  showDeliveryDetails?: boolean;
  subtotal?: number;
  total?: number;
};

export const usePrintReceipt = () => {
  const dispatch = useAppDispatch();

  return async (params: UsePrintReceiptParams) => {
    return printReceipt({ dispatch, ...params });
  };
};

export const printReceipt = async ({ dispatch, ...params }: UsePrintReceiptParams & { dispatch: AppDispatch }) => {
  const { receiptPrinter } = getStoredHardware();

  try {
    if (!receiptPrinter) {
      throw new Error('Printer configuration not found');
    }

    dispatch(successNotification('Printing receipt...'));
    dispatch(setPrintJobStatus({ printJob: PrintJob.RECEIPT, status: PrintStatus.PRINTING }));

    logger.info<PrintJobContext>('print receipt', {
      key: customEventKeys.printing.jobStarted,
      job: PrintJob.RECEIPT,
      requestParams: params,
      printer: peripheralInfo(receiptPrinter),
    });

    const job = await post<GetPrintJobResponse>('v2/print-jobs/get-receipt-job', {
      ForDelivery: params.showDeliveryDetails,
      PopCashDrawer: params.popCashDrawer,
      PrinterId: 0, // This does not appears to be used by the endpoint
      PrinterType: selectHardwarePrinterType(receiptPrinter),
      ReceiptType: params.receiptType,
      ReceiptParameters: params.receiptParameters,
      SubTotal: params.subtotal,
      Total: params.total,
    });
    await processHardwarePrintJob(job, receiptPrinter);

    if (params.popCashDrawer && isAndroid) {
      await Peripheral.openCashDrawer();
    }

    dispatch(successNotification('Receipt printed'));
    dispatch(setPrintJobStatus({ printJob: PrintJob.RECEIPT, status: PrintStatus.SUCCESSFUL }));
  } catch (e) {
    const message = parseErrorMessage(e);
    dispatch(errorNotification(`Error printing receipt: ${message}`));
    dispatch(setPrintJobStatus({ printJob: PrintJob.RECEIPT, status: PrintStatus.FAILED }));
    logger.error(e, {
      message: 'Failed to print receipt',
      requestParams: params,
      printer: peripheralInfo(receiptPrinter),
    });
  }
};
