import { isAndroid } from 'util/hooks/android/useAndroidPeripherals';
import { useAppSelector } from 'util/hooks/useAppDispatch';

/**
 * Determines if the Smart Checkin (Pusher for guestlist) feature should be used
 * @note Can be used if FF is on and the device is not Android
 */
export const getIsSmartCheckinActive = (isUseSmartCheckinEnabled: boolean) => {
  return isUseSmartCheckinEnabled && !isAndroid;
};

/**
 * Determines if the Smart Checkin (Pusher for guestlist) feature should be used
 * @note Can be used if FF is on and the device is not Android
 */
export const useSmartCheckinActive = () => {
  const isUseSmartCheckinEnabled = useAppSelector((state) => state.settings.features.UseSmartCheckin);
  return getIsSmartCheckinActive(isUseSmartCheckinEnabled);
};
