import React, { PropsWithChildren, useEffect } from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

import { PopupOverlay } from 'components/popups';
import { ModalContainer, ModalCloseButton, ModalVariation } from './Modal';
import { colors } from 'css/Theme';
import { customEventKeys, logger } from 'util/logger';
import { Row } from 'components/layout';

export type SimpleModalProps = PropsWithChildren<{
  header: string | JSX.Element;
  hide: () => void;
  variation?: ModalVariation;
  modalName: string; // Used for tracking
  footer?: JSX.Element;
  overlayStyles?: FlattenSimpleInterpolation;
  showFooterBorder?: boolean;
  showCloseButton?: boolean;
}>;

export const SimpleModal = ({
  children,
  footer,
  header,
  hide,
  variation,
  modalName,
  overlayStyles,
  showFooterBorder = true,
  showCloseButton = true,
}: SimpleModalProps): JSX.Element => {
  useEffect(() => {
    logger.info(`${modalName} mounted`, { key: customEventKeys.modalMounted });
  }, [modalName]);

  return (
    <Overlay styles={overlayStyles} hide={() => {}}>
      <StyledModalContainer data-testid='simple-modal' variation={variation}>
        {showCloseButton && (
          <CloseButtonContainer>
            <ModalCloseButton onClick={hide} data-testid='simple-modal-close-button' />
          </CloseButtonContainer>
        )}
        <Header>
          <HeaderText data-testid='simple-modal-header'>{header}</HeaderText>
        </Header>
        <Body>{children}</Body>
        {footer && <Footer showBorderTop={showFooterBorder}>{footer}</Footer>}
      </StyledModalContainer>
    </Overlay>
  );
};

const Overlay = styled(PopupOverlay)<{ styles?: FlattenSimpleInterpolation }>`
  ${({ styles }) => styles};
`;

const StyledModalContainer = styled(ModalContainer)`
  position: relative;
`;

const Body = styled.div`
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
`;

const Header = styled.div`
  padding: 32px 60px;
`;

const HeaderText = styled.div`
  color: ${colors.dutchie.almostBlack};
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: 700;
  font-size: 24px;
`;

const Footer = styled(Row)<{ showBorderTop?: boolean }>`
  border-top: ${({ showBorderTop }) => (showBorderTop ? `1px solid ${colors.dutchie.gray20}` : 'none')};
  padding: 32px;
  gap: 16px;
  width: 100%;
`;
