import {
  HardwareService,
  LabelPrinter,
  ReceiptPrinter,
  Peripheral,
  Scale,
  Scanner,
  ReceiptLanguages,
  ReceiptLanguage,
} from '@dutchie/capacitor-hardware';
import { isPrintNodeReceiptPrinter } from 'hardware/receipt-printer';
import { PrintNodePrinterTypes, PrintNodePrinterType } from 'models/Misc';
import { isPrintNodeLabelPrinter } from 'hardware/label-printer';
import {
  getIsStarGraphicSupportEnabled,
  getIsSeparatePrintCommandsEnabled,
} from 'util/hooks/launch-darkly/useStarGraphicSupport';
import { isAndroid } from 'util/hooks';

export const HARDWARE_LIBRARY_STORAGE_KEYS = {
  FULFILLMENT_PRINTER_ID: 'hardware.fulfillmentPrinterId',
  LABEL_PRINTER_ID: 'hardware.labelPrinterId',
  RECEIPT_PRINTER_ID: 'hardware.receiptPrinterId',
  Z_REPORT_PRINTER_ID: 'hardware.zReportPrinterId',
  SCALE_ID: 'hardware.scaleId',
  SCANNER_ID: 'hardware.scannerId',
};

export type PeripheralInfo = {
  id: string;
  language?: ReceiptLanguage;
  name: string;
  productId: number | undefined;
  service: string;
  vendorId: number | undefined;
};
export const peripheralInfo = (peripheral: Peripheral | undefined): PeripheralInfo => {
  return {
    id: peripheral?.id ?? 'n/a',
    name: peripheral?.name ?? 'n/a',
    productId: peripheral?.productId,
    service: peripheral?.serviceName ?? 'n/a',
    vendorId: peripheral?.vendorId,
    language: peripheral instanceof ReceiptPrinter ? peripheral?.language : undefined,
  };
};

export type GetPrintJobResponse = {
  printerId: number;
  content: string;
  numToPrint: number;
  contentType: string;
  source: string;
  title: string;
};

const isLabelPrinter = (printer: LabelPrinter | ReceiptPrinter): printer is LabelPrinter => {
  return 'print' in printer && !('popCashDrawer' in printer);
};

const isReceiptPrinter = (printer: LabelPrinter | ReceiptPrinter): printer is ReceiptPrinter => {
  return 'print' in printer && 'popCashDrawer' in printer;
};

// Send PDF commands to the printer as separate requests
const handlePdfJob = async (
  printer: LabelPrinter | ReceiptPrinter,
  job: GetPrintJobResponse,
  bytes: number[]
): Promise<boolean> => {
  let result = true;
  const byteArray = Uint8Array.from(bytes);
  for (let i = 0; i < job.numToPrint; i++) {
    result = result && (await printer.print(byteArray));
  }
  return result;
};

export const processHardwarePrintJob = async (
  job: GetPrintJobResponse,
  printer: LabelPrinter | ReceiptPrinter
): Promise<boolean> => {
  try {
    const bytes: number[] = [];
    const jobStr = atob(job.content);

    for (let i = 0; i < jobStr.length; i++) {
      bytes.push(jobStr.charCodeAt(i));
    }

    if (isReceiptPrinter(printer) && isPrintNodeReceiptPrinter(printer)) {
      printer.job = job;

      if (printer.printNodeType === PrintNodePrinterTypes.PDF) {
        return handlePdfJob(printer, job, bytes);
      }
    }

    if (isLabelPrinter(printer) && isPrintNodeLabelPrinter(printer)) {
      printer.job = job;

      if (printer.printNodeType === PrintNodePrinterTypes.PDF) {
        return handlePdfJob(printer, job, bytes);
      }
    }

    // Combine ESC/ZPL commands and send a single print request
    const numToPrint = job.numToPrint ?? 1;

    let combinedBytes: number[] = [];
    for (let i = 0; i < numToPrint; i++) {
      combinedBytes = combinedBytes.concat(bytes);
    }

    return await printer.print(Uint8Array.from(combinedBytes));
  } finally {
    // Reset PrintNode job info
    if (isLabelPrinter(printer) && isPrintNodeLabelPrinter(printer)) {
      printer.job = undefined;
    }
    if (isReceiptPrinter(printer) && isPrintNodeReceiptPrinter(printer)) {
      printer.job = undefined;
    }
  }
};

type GetStoredHardwareResult = {
  fulfillmentPrinter: ReceiptPrinter | undefined;
  labelPrinter: LabelPrinter | undefined;
  receiptPrinter: ReceiptPrinter | undefined;
  scale: Scale | undefined;
  scanner: Scanner | undefined;
  zReportPrinter: ReceiptPrinter | undefined;
};

type GetStoredHardwareIdsResult = {
  fulfillmentPrinterId: string | undefined;
  labelPrinterId: string | undefined;
  receiptPrinterId: string | undefined;
  scaleId: string | undefined;
  scannerId: string | undefined;
  zReportPrinterId: string | undefined;
};

/**
 * @returns Available printers as the HardwareService objects needed for printing
 */
export const getStoredHardware = (): GetStoredHardwareResult => {
  const { fulfillmentPrinterId, labelPrinterId, receiptPrinterId, scaleId, scannerId, zReportPrinterId } =
    getStoredHardwareIds();

  const result = {
    fulfillmentPrinter: fulfillmentPrinterId
      ? HardwareService.receiptPrinter.deviceById(fulfillmentPrinterId)
      : undefined,
    labelPrinter: labelPrinterId ? HardwareService.labelPrinter.deviceById(labelPrinterId) : undefined,
    receiptPrinter: receiptPrinterId ? HardwareService.receiptPrinter.deviceById(receiptPrinterId) : undefined,
    zReportPrinter: zReportPrinterId ? HardwareService.receiptPrinter.deviceById(zReportPrinterId) : undefined,
    scale: scaleId ? HardwareService.scale.deviceById(scaleId) : undefined,
    scanner: scannerId ? HardwareService.scanner.deviceById(scannerId) : undefined,
  };

  return result;
};

export const getStoredHardwareIds = (): GetStoredHardwareIdsResult => {
  const fulfillmentPrinterId = localStorage.getItem(HARDWARE_LIBRARY_STORAGE_KEYS.FULFILLMENT_PRINTER_ID) ?? undefined;
  const labelPrinterId = localStorage.getItem(HARDWARE_LIBRARY_STORAGE_KEYS.LABEL_PRINTER_ID) ?? undefined;
  const receiptPrinterId = localStorage.getItem(HARDWARE_LIBRARY_STORAGE_KEYS.RECEIPT_PRINTER_ID) ?? undefined;
  const scaleId = localStorage.getItem(HARDWARE_LIBRARY_STORAGE_KEYS.SCALE_ID) ?? undefined;
  const scannerId = localStorage.getItem(HARDWARE_LIBRARY_STORAGE_KEYS.SCANNER_ID) ?? undefined;
  const zReportPrinterId = localStorage.getItem(HARDWARE_LIBRARY_STORAGE_KEYS.Z_REPORT_PRINTER_ID) ?? undefined;

  return {
    fulfillmentPrinterId,
    labelPrinterId,
    receiptPrinterId,
    zReportPrinterId,
    scaleId,
    scannerId,
  };
};

// Called by all print methods to determine the proper PrinterType param based on OS and feature rollouts
export const selectHardwarePrinterType = (printer: ReceiptPrinter): PrintNodePrinterType => {
  // Respect the PrintNode type
  if (isPrintNodeReceiptPrinter(printer)) {
    return printer.printNodeType ?? PrintNodePrinterTypes.ESC;
  }

  // Android does not yet support the new separate commands path.
  if (!getIsSeparatePrintCommandsEnabled() || isAndroid) {
    return PrintNodePrinterTypes.ESC;
  }

  // Use the language value provided by the hardware library for WebUSB and iOS
  if (getIsStarGraphicSupportEnabled() && printer.language === ReceiptLanguages.starGraphic) {
    return PrintNodePrinterTypes.STAR_GRAPHIC;
  } else if (printer.language === ReceiptLanguages.starPrint) {
    return PrintNodePrinterTypes.STAR_PRINT;
  }

  return PrintNodePrinterTypes.EPSON;
};
