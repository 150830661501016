import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import {
  LoadCartRequest,
  ClearCartRequest,
  ApplyLoyaltyPointsRequest,
  ReturnItemRequest,
  EditCartItemRequest,
  SaveVisitReasonRequest,
  Cart,
  ReturnItemNotification,
  ReturnPaymentOnlyRequest,
  CartTotal,
  CalcCartRequest,
} from 'models/Cart';
import { BaseElectronicPaymentResponse, PaymentType } from 'models/Checkout';
import { AddSpringBigRewardRequest, AddSpringBigOfferRequest } from 'models/SpringBig';
import * as CartApi from 'api/CartApi';
import * as SpringBigApi from 'api/SpringBigApi';
import { errorNotification, successNotification, warningNotification } from 'store/actions/NotificationsActions';
import { getCustomerDetails, loadProductHistory } from 'store/actions/CustomerActions';
import {
  refreshCheckout,
  removePaymentType,
  setExistingPayments,
  stopPolling,
  addPaymentMethod,
} from './CheckoutActions';
import { AppDispatch, State } from 'store';
import { checkManagerPin } from 'util/Helpers';
import { getProductHistoryDetail } from '../actions/TransactionsActions';
import React from 'react';
import { CheckedInGuest } from 'models/Guest';
import { updateCFDCartTotals, updateCFDData } from './CFDActions';
import { handleLoadCartError } from 'store/helpers/cartHelpers/handleLoadCartError';
import { logger, customEventKeys } from 'util/logger';
import { refetchCartDetails } from 'pages/CartPage/hooks/useRefetchCartDetails';
import { updateCartTotals } from 'queries/v2/cart/calculate-cart-total';
import { getIsAnonymousCartLDFlagEnabled } from 'pages/CartPage/hooks/useAnonymousCart';
import { CustomerHistoryPanelTabs } from 'components/cart/CustomerHistoryPanel/CustomerHistoryPanel';
import { getIsDaysSupplyFeatureEnabled } from 'util/hooks/features/useIsDaysSupplyFeatureEnabled';
import { getLoadedTransactionInfo } from 'pages/CartPage/hooks/useTransactionManager';
import { getPreventAllotmentWarningBeforeGuestLoads } from 'util/hooks/launch-darkly/usePreventAllotmentWarningBeforeGuestLoads';

export const setIsCartInitialized = createAction('setIsCartInitialized', (payload: boolean) => ({ payload }));

/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export const setCartTotalsLoading = createAction('setCartTotalsLoading', (payload: boolean) => ({ payload }));

export const showDiscountPanel = createAction('showDiscountPanel');
export const hideDiscountPanel = createAction('hideDiscountPanel');

export const showLoyaltyPanel = createAction('showLoyaltyPanel');
export const hideLoyaltyPanel = createAction('hideLoyaltyPanel');

export const showPrescriptionPanel = createAction('showPrescriptionPanel');
export const hidePrescriptionPanel = createAction('hidePrescriptionPanel');

/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export const setCheckedInGuest = createAction('setCheckedInGuest', (payload: Partial<CheckedInGuest>) => ({ payload }));
/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export const clearCheckedInGuest = createAction('clearCheckedInGuest');

/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export const resetCart = createAction('initializeCart');

// This is a jank method to load payments missing from the in-memory
// cart on the POS, and merge them into the in-memory cart. These
// "Missing payments" for exist in the API, but not in memory. This
// is due to some known issues with PIN Debit. ("False Negatives")
export const loadMissingPayments = createAsyncThunk(
  'loadMissingPayments',
  async (params: LoadCartRequest, { dispatch, getState }) => {
    const { checkout: currentCart } = getState() as State;

    const apiCart = await CartApi.getCartV2(params).catch(() => {
      return Promise.reject();
    });

    // This is the list of methods current in memory, We'll need this to make sure
    // we don't "double up" payments by adding ones already in memory.
    const currentMethods = [...(currentCart.payment?.methods || [])];

    // Lets loop through the list of payments from the API, and
    // add them if they are "Missing"
    apiCart.ExistingPayments.forEach((apiPaymentMethod) => {
      // We need to make sure we don't "double add" payments from the API
      // that already exist in the cart. This logic will deduplicate them.
      //
      // Here is an example -
      //  1. A budtender fails to add a debit charge to a cart due to a false negative (In API/DB, but not in memory)
      //  2. Budtender successfully adds a second debit charge to the cart (In memory AND API/DB)
      //  3. Budtender attempts checkout and we reload (both) payments from the DB
      // Then,
      //  1. The charge from step 1 needs to be added (It has an ID of 'paynetworx_123')
      //  2. The charge from step 2 is already in memory and needs to NOT be added (It has an ID of 'GUID...')
      const currentPaymentThatIsAlsoInApiPaymentsIndex = currentMethods.findIndex(
        (m) =>
          m.name === apiPaymentMethod.name &&
          m.amount === apiPaymentMethod.amount &&
          m.tipAmount === apiPaymentMethod.tipAmount
      );

      // Now that we matched - we need to remove that item so we don't match again
      if (currentPaymentThatIsAlsoInApiPaymentsIndex > -1) {
        currentMethods.splice(currentPaymentThatIsAlsoInApiPaymentsIndex, 1);
        return;
      }

      // Cruft here - Anything from the API that's not polling is finalized. Ideally
      // the API reflects this. We are doing it here instead to keep the footprint of
      // this hotfix small
      apiPaymentMethod.finalized = true;
      dispatch(addPaymentMethod(apiPaymentMethod));
    });
  }
);

/**
 * @deprecated Use the new hook 'pages/CartPage/hooks/useGetCartDetails' or
 * 'pages/CartPage/hooks/useRefetchCartDetails' instead of loadCart action
 * ANY CHANGE TO THIS ACTION WILL NEED TO BE MADE TO REACT QUERY LOGIC AS WELL
 * RQ implementation is in 'queries/v2/cart/helpers/useProcessCartResponse'
 * Clean up with pos.register.anon-cart-workflow.rollout
 */
export const loadCart = createAsyncThunk<Cart, LoadCartRequest, { state: State }>(
  'loadCart',
  async (params: LoadCartRequest, { dispatch, getState }) => {
    const { settings, customer, cart: oldCart } = getState();
    params.UpdateCache = settings.features.UseCartDisplay;

    const cart = await CartApi.getCartV2(params).catch((e) => {
      handleLoadCartError(dispatch, e);
      logger.error(e, { message: 'Unable to load V2 cart' });

      return Promise.reject();
    });

    const { isPreventAllotmentWarningsBeforeGuestLoadsEnabled } = getPreventAllotmentWarningBeforeGuestLoads();
    const shouldShowAllotmentWarnings = isPreventAllotmentWarningsBeforeGuestLoadsEnabled
      ? !!customer.details
      : true;

    // Handle allotment warnings
    if (shouldShowAllotmentWarnings) {
      const hasCannabisItemInCart = cart.Cart.some((cartItem) => cartItem.CannbisProduct === 'Yes');
      const isDaysSupplyEnabled = getIsDaysSupplyFeatureEnabled({
        isDaysSupplyFFEnabled: settings.features.ShowDaysSupplyCalculator,
        isMedicalCustomer: customer.details?.IsMedical === true,
      });
      const isMississippi = settings.locationSettings?.State.toLowerCase() === 'ms';
      if (!isDaysSupplyEnabled && !isMississippi) {
        if (cart.Allotment && cart.Allotment.Error) {
          dispatch(errorNotification(`Error loading allotment: ${cart.Allotment.ErrorMessage}`));
        } else if (hasCannabisItemInCart && cart.Allotment.TotalRemaining < 0) {
          dispatch(warningNotification(`Patient allotment exceeded by ${Math.abs(cart.Allotment.TotalRemaining)}g.`));
        }
      } else if (isDaysSupplyEnabled) {
        const daysSupplyInCart = cart.Cart.reduce(
          (value, cartItem) =>
            value + cartItem.QtySelected * (Number(cartItem.DaysSupply) !== 0 ? Number(cartItem.DaysSupply) : 0),
          0.0
        );

        if (Number.isFinite(daysSupplyInCart) && Number.isFinite(customer.details?.DaysSupplyRemaining)) {
          if (customer.details) {
            if (daysSupplyInCart > customer.details?.DaysSupplyRemaining) {
              dispatch(
                warningNotification(
                  `Patient days supply exceeded by ${Math.abs(
                    daysSupplyInCart - customer.details?.DaysSupplyRemaining
                  )}.`
                )
              );
            }
          }
        }
      }
    }

    // For some reason, updating the state does not affect oldCart, so we use a local variable
    let dutchiePayError = oldCart.isDutchiePayError;

    if (oldCart.details.ShipmentId !== cart.ShipmentId) {
      // If there are any existing payments on this shipment, and they are not Dutchie Pay,
      // we need to hide the Dutchie Pay panel. We check the payments and set the 'error'
      // flag appropriately
      if (cart.ExistingPayments.findIndex((m) => m.type !== PaymentType.Dutchie) !== -1) {
        dutchiePayError = true;
        await dispatch(isDutchiePayError(true));
      } else {
        dutchiePayError = false;
        await dispatch(isDutchiePayError(false));
      }

      await dispatch(stopPolling());
      await dispatch(setExistingPayments({ ExistingPayments: cart.ExistingPayments, ShipmentId: cart.ShipmentId }));
    }

    // If we are in a state of dutchie pay error, we need to check on each cart reload to ensure
    // the preauth is reset before returning the cart, or else it wil show up in the summary
    if (dutchiePayError) {
      dispatch(removePaymentType(PaymentType.Dutchie));
    }

    await dispatch(refreshCheckout(cart.GrandTotalRounded));

    dispatch(updateCFDData({ cart }));

    logger.debug(`[REDUX ACTION] loadCart completed`, {
      key: customEventKeys.reduxAction,
      payload: cart,
    });

    return cart;
  }
);

/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export const setCartLoading = createAction('setCartLoading', (payload: boolean) => ({ payload }));

/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export const setCartFromRQ = createAction('setCartFromRQ', (payload: Cart) => ({ payload }));

/**
 * @deprecated Use the new hook 'pages/CartPage/hooks/useUpdateCartTotals' instead
 * Any changes made to this action will need to be made to the new hook as well
 * Clean up with pos.register.anon-cart-workflow.rollout
 */
export const calcCartTotal = createAsyncThunk(
  'calcCartTotal',
  async (params: CalcCartRequest, { dispatch, getState }) => {
    const { settings } = getState() as State;
    params.UpdateCache = settings.features.UseCartDisplay;
    try {
      const cart = await CartApi.calcCartTotal(params);
      await dispatch(refreshCheckout(cart.GrandTotalRounded));
      dispatch(updateCFDCartTotals(cart));

      logger.debug(`[REDUX ACTION] calcCartTotal completed`, {
        key: customEventKeys.reduxAction,
        payload: cart,
      });

      return cart;
    } catch (error) {
      const errorMessage = 'There was an error removing the item from the cart';
      logger.error(error, { message: errorMessage });
      throw dispatch(warningNotification(error ? `${error}` : errorMessage));
    }
  }
);

/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export const setCartTotalsFromRQ = createAction('setCartTotalsFromRQ', (payload: CartTotal) => ({ payload }));

/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export const clearCartFromRQ = createAction('clearCartFromRQ');

/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export const clearCart = createAsyncThunk('clearCart', async (params: ClearCartRequest) => {
  return await CartApi.cleanCart(params);
});

export const clearPreOrder = createAsyncThunk('clearPreOrder', async (ShipmentId: number) => {
  // const response = await post('v2/cart/clear', args)
  // await dispatch(loadAllProducts())
  // return response
});

export const applyLoyaltyPoints = createAsyncThunk(
  'applyLoyaltyPoints',
  async (params: ApplyLoyaltyPointsRequest, { dispatch, getState }) => {
    const { settings, ldFlags } = getState() as State;
    try {
      const response = await CartApi.applyPointsOnCart(params);
      dispatch(successNotification('Loyalty points updated'));
      if (ldFlags.twoPanelCart) {
        dispatch(hideLoyaltyPanel());
      }

      await dispatch(getCustomerDetails({ guestId: params.GuestId, noLoading: true }));

      if (settings.features.StatefulCheckout) {
        const { isAnonymousCartLDFlagEnabled } = getIsAnonymousCartLDFlagEnabled();
        if (isAnonymousCartLDFlagEnabled) {
          await updateCartTotals(dispatch as AppDispatch, {
            guestId: params.GuestId,
            shipmentId: params.TransactionId,
            registerId: params.Register,
          });
        } else {
          dispatch(
            calcCartTotal({
              ShipmentId: params.TransactionId,
              Timestamp: +new Date(),
              AcctId: params.GuestId,
              Register: params.Register,
            })
          );
        }
      } else {
        await refetchCartDetails(dispatch as AppDispatch, {
          guestId: params.GuestId,
          registerId: params.Register,
          shipmentId: params.TransactionId,
        });
      }

      return response;
    } catch (e) {
      dispatch(errorNotification(`Unable to update loyalty points: ${e}`));
      logger.error(e, { message: 'Unable to update loyalty points' });
    }
  }
);

export const returnItem = createAsyncThunk(
  'returnItem',
  async (params: { item: ReturnItemRequest; pinCode?: string }, { dispatch, getState }) => {
    try {
      const { customer, settings } = getState() as State;
      if (settings.features.MgrPwForReturns) {
        const checkData = await checkManagerPin(params.pinCode);
        params.item.PinUserId = checkData[0].UserId;
        params.item.ReactVersion = React.version;
        await CartApi.returnItem(params.item);
        if (customer.details) {
          await dispatch(loadProductHistory(customer.details.Guest_id));
          await dispatch(
            getProductHistoryDetail({
              TransactionId: params.item.ShipmentId,
              Register: settings.selectedRegister?.value,
            })
          );
        }
        dispatch(successNotification('Item Returned Successfully'));
      } else {
        params.item.ReactVersion = React.version;
        await CartApi.returnItem(params.item);
        if (customer.details) {
          await dispatch(loadProductHistory(customer.details.Guest_id));
          await dispatch(
            getProductHistoryDetail({
              TransactionId: params.item.ShipmentId,
              Register: settings.selectedRegister?.value,
            })
          );
        }
        dispatch(successNotification('Item Returned Successfully'));
      }
    } catch (e) {
      dispatch(errorNotification(`${e}`));
    }
  }
);

export const returnItemV2 = createAsyncThunk(
  'returnItemV2',
  async (params: { item: ReturnItemRequest; pinCode?: string }, { dispatch, getState }) => {
    try {
      const { settings } = getState() as State;
      if (settings.features.MgrPwForReturns) {
        const checkData = await checkManagerPin(params.pinCode);
        params.item.PinUserId = checkData[0].UserId;
      }
      params.item.ReactVersion = React.version;
      await CartApi.returnItemV2(params.item);
    } catch (e) {
      dispatch(errorNotification(`${e}`));
      return Promise.reject();
    }
  }
);

export const processPaymentOnlyReturn = createAsyncThunk(
  'processPaymentOnlyReturn',
  async (params: { item: ReturnPaymentOnlyRequest }, { dispatch }) => {
    try {
      await CartApi.processPaymentOnlyReturn(params.item);
    } catch (e) {
      dispatch(errorNotification(`${e}`));
    }
  }
);

export const returnItemComplete = createAsyncThunk(
  'returnItemComplete',
  async (
    params: { notification: ReturnItemNotification; registerId?: number; shipmentId: number },
    { dispatch, getState }
  ) => {
    const { notification, registerId, shipmentId } = params;
    const { customer } = getState() as State;
    const guestId = customer.details?.Guest_id;
    if (notification.Success) {
      dispatch(successNotification('Item Returned Successfully'));
      if (guestId) {
        await dispatch(loadProductHistory(guestId));
      }
      await dispatch(
        getProductHistoryDetail({
          TransactionId: shipmentId,
          RegisterId: registerId,
        })
      );
    } else {
      dispatch(errorNotification(`Error returning item: ${notification.Message}`));
    }
  }
);

export const editItemInCart = createAsyncThunk(
  'editItemInCart',
  async (body: EditCartItemRequest, { dispatch, getState }) => {
    const { customer, settings } = getState() as State;
    const { guestId } = getLoadedTransactionInfo(customer.details);
    await CartApi.editItemInCart(body);
    if (settings.selectedRegister?.value) {
      await refetchCartDetails(dispatch as AppDispatch, {
        guestId: guestId ?? 0,
        registerId: settings.selectedRegister.value,
        shipmentId: body.ShipmentId,
      });
    }
  }
);

export const addSpringBigReward = createAsyncThunk(
  'addSpringBigReward',
  async (body: AddSpringBigRewardRequest, { dispatch, getState }) => {
    try {
      const { settings } = getState() as State;
      await SpringBigApi.addSpringBigReward(body);
      if (settings.selectedRegister?.value) {
        await refetchCartDetails(dispatch as AppDispatch, {
          guestId: body.CustomerId ?? 0,
          registerId: settings.selectedRegister.value,
          shipmentId: body.ShipmentId ?? 0,
        });
      }
    } catch (message) {
      dispatch(errorNotification(`${message}`));
    }
  }
);

export const addSpringBigOffer = createAsyncThunk(
  'addSpringBigOffer',
  async (body: AddSpringBigOfferRequest, { dispatch, getState }) => {
    try {
      const { settings } = getState() as State;
      await SpringBigApi.addSpringBigOffer(body);
      if (settings.selectedRegister?.value) {
        await refetchCartDetails(dispatch as AppDispatch, {
          guestId: body.CustomerId ?? 0,
          registerId: settings.selectedRegister.value,
          shipmentId: body.ShipmentId ?? 0,
        });
      }
    } catch (message) {
      dispatch(errorNotification(`${message}`));
    }
  }
);
export const saveVisitReason = createAsyncThunk(
  'saveVisitReason',
  async (body: SaveVisitReasonRequest, { dispatch }) => {
    try {
      await CartApi.saveVisitReason(body);
      dispatch(successNotification('Customer visit reason saved'));
    } catch (e) {
      dispatch(errorNotification(`Error saving customer visit reason: ${e}`));
    }
  }
);

export const showProductHistory = createAction('showProductHistory', (payload: boolean) => ({ payload }));

export const removeItemFromPreOrder = createAction('removeItemFromPreOrder', (payload: number) => ({ payload }));

export const setDaysSupplyInPeriod = createAction('setDaysSupplyInPeriod', (payload: number) => ({ payload }));

export const updateElectronicPaymentDetails = createAction(
  'updateElectronicPaymentDetails',
  (payload: BaseElectronicPaymentResponse) => ({ payload })
);

export const isDutchiePayError = createAction('isDutchiePayError', (payload: boolean) => ({ payload }));
export const setPreauthAmount = createAction('setPreauthAmount', (payload: number) => ({ payload }));

export const setSelectedPreOrderQty = createAction('setSelectedPreOrderQty', (payload?: number) => ({ payload }));

export const setDutchiePayPreAuthError = createAction('setDutchiePayPreAuthError', (payload: boolean) => ({
  payload,
}));

export const setIsFalseNegativePinDebitError = createAction('setIsFalseNegativePinDebitError', (payload: boolean) => ({
  payload,
}));

export const setBlockScans = createAction('setBlockScans', (payload: boolean) => ({
  payload,
}));

export const openCustomerSelectionPanel = createAction('openCustomerSelectionPanel');
export const closeCustomerSelectionPanel = createAction('closeCustomerSelectionPanel');

export const openCustomerHistoryPanel = createAction(
  'openCustomerHistoryPanel',
  ({ tab }: { tab: CustomerHistoryPanelTabs }) => ({ payload: tab })
);
export const closeCustomerHistoryPanel = createAction('closeCustomerHistoryPanel');
