import React, { FC, useMemo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { colors, breakpoints } from 'css/Theme';
import { DrawerHeader } from '../PanelDrawerWrapper';
import { hideLoyaltyPanel } from 'store/actions/CartActions';
import { State } from 'store';
import { Button, BackButton } from 'components/buttons';
import { Input } from 'components/inputs';
import { NumberFormatValues } from 'react-number-format';
import { errorNotification } from 'store/actions/NotificationsActions';
import { SidePanel, SidePanelSection } from 'components/layout';
import { useLoyaltyCalculations } from './useLoyaltyCalculations';
import { useApplyLoyaltyPoints } from './useApplyLoyaltyPoints';
import { OrderTotals } from 'components/cart/OrderTotals';
import { TotalDetailsSection } from '../CheckoutSidebar/TotalDetailsSection';
import { Skeleton } from 'components/misc';
import { extendedPanelPortalID } from '../index';
import { LoyaltyIcon } from 'assets/icons/payments';
import { useCompactCartLayout } from 'util/hooks/responsive/useCompactCartLayout';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { useUpdateCartTotals } from 'pages/CartPage/hooks/useUpdateCartTotals';

export const LoyaltyPoints: FC = () => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const {
    totalPoints,
    availablePoints,
    availablePointsRemaining,
    appliedPoints,
    pointsSpentByDiscounts,
    userHasNoLoyaltyPoints,
  } = useLoyaltyCalculations();
  const adjustLoyalty = useApplyLoyaltyPoints();

  const checkoutStarted = useSelector((state: State) => state.checkout.running);
  const isStatefulCheckoutEnabled = useSelector((state: State) => state.settings.features.StatefulCheckout);
  const panelAppliedPoints = useMemo(
    () => appliedPoints - pointsSpentByDiscounts,
    [appliedPoints, pointsSpentByDiscounts]
  );
  const isExternalLoyalty = useSelector((state: State) => state.settings.features.ShowExternalLoyalty);
  const guestPoints = useSelector((state: State) => state.customer.details?.LoyaltyPoints) ?? 0;

  const [haveCartTotalsBeenCalculated, setHaveCartTotalsBeenCalculated] = useState(false);

  const { data: cart, isFetching: isCartLoading } = useGetCartDetails();

  const { mutate: updateCartTotals } = useUpdateCartTotals();

  // Extend to bigger panel if using a smaller tablet or screen
  const [isCompact, setIsCompact] = useState(false);
  const portalContainer = document.querySelector(`#${extendedPanelPortalID}`);
  const openPanelInPortal = portalContainer !== null && !checkoutStarted && isCompact;

  const showLoyaltyIconInTitle = checkoutStarted;

  useEffect(() => {
    const updateLayout = () => {
      setIsCompact(
        breakpoints.wideTablet.maxPx !== null &&
          window.innerWidth < breakpoints.wideTablet.maxPx &&
          window.innerWidth > breakpoints.wideTablet.minPx
      );
    };
    updateLayout();
    window.addEventListener('resize', updateLayout);
    return () => window.removeEventListener('resize', updateLayout);
  }, []);

  useEffect(() => {
    if (isStatefulCheckoutEnabled && !haveCartTotalsBeenCalculated) {
      setHaveCartTotalsBeenCalculated(true);
      updateCartTotals({ isCheckout: false });
    }
  }, [isStatefulCheckoutEnabled, updateCartTotals, haveCartTotalsBeenCalculated]);

  // When the appliedPoints value changes, update the input field
  useEffect(() => {
    setAmount(panelAppliedPoints);
  }, [panelAppliedPoints, appliedPoints, isExternalLoyalty]);

  const onApplyPoints = async () => {
    if (!amount || amount <= 0) {
      dispatch(errorNotification('Please enter a valid amount'));
      return;
    }
    await adjustLoyalty.apply(amount);
    if (checkoutStarted) {
      dispatch(hideLoyaltyPanel());
    }
  };

  const onApplyAvailablePoints = async () => {
    await adjustLoyalty.applyAvailable();
    if (checkoutStarted) {
      dispatch(hideLoyaltyPanel());
    }
  };

  const handleValueChange = ({ floatValue }: NumberFormatValues) => {
    setAmount(floatValue ?? 0);
  };

  const applyBtnDisabled = useMemo(() => {
    if (amount <= 0) {
      return true;
    }

    // Disable if input is equal to already applied points
    if (amount === panelAppliedPoints) {
      return true;
    }

    // Disable if input is more than what's available to apply
    if (amount > guestPoints) {
      return true;
    }

    if (amount > availablePointsRemaining + panelAppliedPoints) {
      return true;
    }

    return isCartLoading;
  }, [amount, isCartLoading, panelAppliedPoints, guestPoints, availablePointsRemaining]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!applyBtnDisabled && e.key === 'Enter') {
      onApplyPoints();
    }
  };

  const onBackClick = () => {
    dispatch(hideLoyaltyPanel());
  };

  const { isCompactLayout } = useCompactCartLayout();

  const LoyaltyPanel = (
    <SidePanel automationId='loyalty-panel-container'>
      {isCompactLayout ? (
        <DrawerHeader
          onGoBack={checkoutStarted ? onBackClick : undefined}
          actionProps={{ label: 'Close', onClick: onBackClick }}
        >
          {showLoyaltyIconInTitle && <LoyaltyIcon />}Loyalty Points
        </DrawerHeader>
      ) : (
        <SidePanelSection>
          <TitleContainer>
            <BackButton onClick={onBackClick} />
            <Title>{showLoyaltyIconInTitle && <LoyaltyIcon />}Loyalty Points</Title>
          </TitleContainer>
        </SidePanelSection>
      )}
      <SidePanelSection gap='0.5rem'>
        <PointsContainer>
          <PointsSection>
            <Points data-testid='loyalty-panel_total-points'>
              {isCartLoading ? <Skeleton data-testid='loyalty-panel_total-points_loading' width={50} /> : totalPoints}
            </Points>
            <PointsLabel>Total Pts</PointsLabel>
          </PointsSection>
          <PointsDivider />
          <PointsSection>
            <Points data-testid='loyalty-panel_available-points'>
              {isCartLoading ? (
                <Skeleton data-testid='loyalty-panel_available-points_loading' width={50} />
              ) : (
                availablePointsRemaining
              )}
            </Points>
            <PointsLabel>Available Pts</PointsLabel>
          </PointsSection>
          <PointsDivider />
          <PointsSection>
            <Points data-testid='loyalty-panel_applied-points'>
              {isCartLoading ? (
                <Skeleton data-testid='loyalty-panel_applied-points_loading' width={50} />
              ) : (
                appliedPoints
              )}
            </Points>
            <PointsLabel>Applied Pts</PointsLabel>
          </PointsSection>
        </PointsContainer>
        <Input
          type='number'
          decimalScale={2}
          allowNegative={false}
          placeholder='Enter points to apply'
          value={amount <= 0 ? '' : amount}
          onValueChange={handleValueChange}
          onKeyPress={handleKeyPress}
          disabled={availablePoints === 0 || isCartLoading}
          endAdornment={
            appliedPoints > 0 ? (
              <ClearButton isAdornment onClick={adjustLoyalty.clearApplied}>
                Clear applied points
              </ClearButton>
            ) : undefined
          }
        />
        <ButtonContainer>
          <Button
            tertiary
            fullWidth
            onClick={onApplyAvailablePoints}
            disabled={isCartLoading || userHasNoLoyaltyPoints}
          >
            Apply all available points
          </Button>
          <Button fullWidth onClick={onApplyPoints} disabled={applyBtnDisabled}>
            Apply entered points
          </Button>
        </ButtonContainer>
      </SidePanelSection>
      <SidePanelSection flex showBorder={false}>
        <OrderTotals cart={cart} loading={isCartLoading} hideGetTotalBtn showTotal={!checkoutStarted} align='top' />
      </SidePanelSection>
      {checkoutStarted && (
        <TotalDetailsSection paymentInProgress imgRef={{ current: null }}>
          <Button cta onClick={onBackClick}>
            Back to payment type
          </Button>
        </TotalDetailsSection>
      )}
    </SidePanel>
  );

  if (openPanelInPortal) {
    return createPortal(LoyaltyPanel, portalContainer);
  } else {
    return LoyaltyPanel;
  }
};

const TitleContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const Title = styled.div`
  display: flex;
  gap: 1rem;
  color: ${colors.dutchie.almostBlack};
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
`;

const PointsContainer = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${colors.dutchie.gray30};
  border-radius: 0.5rem;
  padding: 0.75rem 1.5rem;
  gap: 2rem;
  margin-bottom: 1rem;
`;

const PointsSection = styled.div`
  display: flex;
  width: 33%;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

const PointsDivider = styled.div`
  width: 1px;
  flex: 0 0 1px;
  height: 100%;
  background: ${colors.dutchie.gray30};
`;

const Points = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
  text-align: center;
`;

const PointsLabel = styled.div`
  color: ${colors.dutchie.gray70};
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
`;

const ClearButton = styled(Button)`
  &&& {
    font-size: 0.8125rem;
    line-height: 1rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 0.5rem;
`;
