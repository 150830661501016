import { useReceiptPrinters } from '@dutchie/capacitor-hardware';
import { useFormikContext } from 'formik';

import { FulfillmentReceiptPrinterSettingsContextData } from '../types';
import { SettingsSelectOption } from 'pages/SettingsPage/Select/SettingsSelect';
import { HardwareSettings } from '../../types';

export const useFulfillmentReceiptPrinterSettingsProvider = (): FulfillmentReceiptPrinterSettingsContextData => {
  const { values: formValues, setFieldValue } = useFormikContext<HardwareSettings>();

  const { receiptPrinters: allPrinters, search } = useReceiptPrinters({
    onDevicesAuthorized: (printers) => selectPrinter(printers[0].id),
  });

  /** @deprecated Switching to using Peripheral array */
  const options: SettingsSelectOption[] = allPrinters.map((printer) => {
    return {
      value: printer.id,
      label: printer.name,
    };
  });

  const selectPrinter = (id?: string): void => setFieldValue('fulfillmentPrinterId', id);
  const setAutoPrintTickets = (value: boolean) => setFieldValue('autoPrintFulfillmentTickets', value);

  return {
    formValues,
    options,
    printers: allPrinters,
    search,
    selectPrinter,
    setAutoPrintTickets,
  };
};
