import React, { useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { colors, eloOriPadBreakpoint } from 'css/Theme';
import { ReactComponent as MagnifyingGlass } from 'assets/icons/magnifying-glass.svg';
import { ReactComponent as ClearInputIcon } from 'assets/icons/clear-input.svg';

import { useResponsiveGuestListActionsFlag } from 'util/hooks/launch-darkly/useResponsiveGuestListActionsFlag';

type SearchBarProps = {
  onSearch: (value: string) => unknown;
  onChange?: (value: string) => unknown;
  placeholder: string;
  inputAutomationId?: string;
  inputId?: string;
  initialValue?: string;
  className?: string;
  maxWidth?: string;
  fullWidth?: boolean;
  showClearButton?: boolean;
};

export const SearchBar = React.memo(SearchBarImpl);

export function SearchBarImpl({
  onSearch,
  onChange,
  placeholder,
  inputAutomationId,
  inputId,
  initialValue = '',
  className = '',
  maxWidth = '312px',
  showClearButton = false,
}: SearchBarProps): JSX.Element {
  const [value, setValue] = useState(initialValue);
  const [hasFocus, setHasFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const isResponsiveGuestListActionsEnabled = useResponsiveGuestListActionsFlag();

  const handleChange = (newValue: string) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        onSearch(value);
        inputRef.current?.blur();
      }
    },
    [onSearch, value]
  );

  const handleIconClick = useCallback(() => inputRef.current?.focus(), []);

  return (
    <SearchContainer isResponsiveGuestListActionsEnabled={isResponsiveGuestListActionsEnabled} onKeyPress={handleKeyPress} hasFocus={hasFocus} maxWidth={maxWidth}>
      <SearchIcon onClick={handleIconClick} />
      <SearchInput isResponsiveGuestListActionsEnabled={isResponsiveGuestListActionsEnabled}
        className={className}
        ref={inputRef}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={placeholder}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        data-testid={inputAutomationId}
        id={inputId}
        enterKeyHint='search'
      />
      {showClearButton && value && (
        <IconContainer
          onClick={() => {
            setValue('');
            onChange && onChange('');
          }}
        >
          <ClearInputIcon />
        </IconContainer>
      )}
    </SearchContainer>
  );
}

const SearchInput = styled.input.withConfig({ shouldForwardProp: (prop) => true })<{isResponsiveGuestListActionsEnabled: boolean}>`
  border: none;
  border-radius: 6px;
  padding: 12px 20px 12px 42px;
  width: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: ${colors.dutchie.black};

  &:focus-within {
    box-shadow: none;
    outline: none;
  }

  ${eloOriPadBreakpoint} {
    ${({ isResponsiveGuestListActionsEnabled }) => !isResponsiveGuestListActionsEnabled && css`
      padding: 13.75px 42px;  
    `}
  }
`;

const SearchIcon = styled(MagnifyingGlass)`
  bottom: 0;
  color: ${colors.primary};
  left: 0;
  margin: auto 0;
  position: absolute;
  top: 0;
  width: 38px;
  padding-left: 6px;
`;

export const SearchContainer = styled.div<{ hasFocus: boolean; maxWidth?: string; isResponsiveGuestListActionsEnabled: boolean }>`
  border: 1px solid ${colors.dutchie.borderGrey};
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  position: relative;
  width: 100%;

  ${({ isResponsiveGuestListActionsEnabled }) => isResponsiveGuestListActionsEnabled && css`
    height: 45px;
  `}

  &:focus-within {
    border-color: ${colors.dutchie.almostBlack};
  }

  ${eloOriPadBreakpoint} {
    min-width: ${({ hasFocus }) => (hasFocus ? '200px' : '100px')};
  }
`;

const IconContainer = styled.div`
  min-width: 42px;
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  align-items: center;
  justify-content: center;
  min-height: 100%;
`;
