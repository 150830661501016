import { useLDClient } from 'launchdarkly-react-client-sdk';

export const useCheckExpirationOnScan = () => {
  const ldClient = useLDClient();
  const isCheckExpirationOnScanEnabled = ldClient?.variation('pos.register.check-expiration-on-scan', false) === true;
  return {
    /** pos.register.check-expiration-on-scan */
    isCheckExpirationOnScanEnabled,
  };
};
