import React, { useState, useEffect } from 'react';
import { Scanner, DeviceBarcode, isHidScanner, HardwareService, WebviewAppScanner } from '@dutchie/capacitor-hardware';
import { isNativeScanner } from '@dutchie/capacitor-peripheral';
import { Column } from 'components/layout';
import { AlertBanner, AlertBannerStyles } from 'components/misc';
import { SetupTitle } from './styled';
import { useHardwareServices } from '../../../hooks/useHardwareServices';
import { TestLicenseBarcode } from './TestLicenseBarcode';
import { isAndroid, isWebViewApp } from 'util/hooks';

export const ScannerTestStep = ({ onSuccess }: { onSuccess: (scanner: Scanner) => void }) => {
  const [detected, setDetected] = useState(false);
  const { isWebHidSupported } = useHardwareServices();
  const canBeConfiguredForIdScanning = isWebHidSupported || isAndroid || isWebViewApp;
  const [warning, setWarning] = useState(!canBeConfiguredForIdScanning ? 'Not configured for ID scanning.' : '');

  const handleScan = (success: boolean, data: DeviceBarcode) => {
    if (!isHidScanner(data.device) && !isNativeScanner(data.device) && !isWebViewApp) {
      setWarning('Not configured for ID scanning.');
      return;
    }

    if (success) {
      setDetected(true);
      onSuccess(data.device);
    } else {
      setWarning('Barcode scan failed. Please try again.');
    }
  };

  useEffect(() => {
    // Ensure we're listening to all HID scanners
    HardwareService.scanner.devices
      .filter((it) => !it.isConnected && (isHidScanner(it) || isNativeScanner(it) || it instanceof WebviewAppScanner))
      .forEach((it) => it.connect());
  }, []);

  return (
    <Column gap={20}>
      {!detected && warning && <AlertBanner text={warning} style={AlertBannerStyles.warning} marginTop='0px' />}
      {detected && (
        <AlertBanner text='Test barcode successfully scanned' style={AlertBannerStyles.success} marginTop='0px' />
      )}
      <div>Scan the barcode below to test scanner functionality.</div>
      <Column gap={8}>
        <SetupTitle>Test barcode</SetupTitle>
        <TestLicenseBarcode onScan={handleScan} />
      </Column>
    </Column>
  );
};
