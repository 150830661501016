import React from 'react';
import { useScanners, isHidScanner, HardwareService } from '@dutchie/capacitor-hardware';

import { Column, Row } from 'components/layout';
import { SetupInstructions } from './SetupInstructions';
import { Button } from 'components/buttons';
import { AlertBannerStyles } from 'components/misc';
import { SettingsAlertBanner } from 'pages/SettingsPage/Status/SettingsAlertBanner';

export const AuthorizeStep = () => {
  const { scanners: allScanners } = useScanners();
  const scanners = allScanners.filter((it) => isHidScanner(it));
  const hasHidScanner = scanners.length > 0;

  const authorize = async () => {
    await HardwareService.scanner.search({ requestNewDevices: true });
  };

  return (
    <Column gap={24}>
      {!hasHidScanner && <SettingsAlertBanner text='No scanner detected' style={AlertBannerStyles.error} />}
      {hasHidScanner && <SettingsAlertBanner text='Scanner detected' style={AlertBannerStyles.success} />}
      <SetupInstructions
        steps={[
          <div>Click "Authorize scanner" button below.</div>,
          <div>
            Authorize your connected device when prompted by your browser. You will only need to do this the first time
            you connect a new scanner.
          </div>,
        ]}
      />
      <Row>
        <Button secondary onClick={authorize}>
          Authorize scanner
        </Button>
      </Row>
    </Column>
  );
};
