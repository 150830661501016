import { colors } from 'css/Theme';
import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { useImprovedSettingsUI } from 'util/hooks/launch-darkly/useImprovedSettingsUI';

type SettingsCardProps = {
  automationId?: string;
  verticalPadding?: boolean;
  footer?: JSX.Element;
};

export const SettingsCard = ({
  automationId,
  verticalPadding = false,
  footer,
  children,
}: PropsWithChildren<SettingsCardProps>) => {
  const { isImprovedSettingsUIEnabled } = useImprovedSettingsUI();

  return (
    <Container data-testid={automationId} isImprovedSettingsUIEnabled={isImprovedSettingsUIEnabled}>
      <Content verticalPadding={verticalPadding}>{children}</Content>
      {footer && <FooterContainer>{footer}</FooterContainer>}
    </Container>
  );
};

const Container = styled.div<{ isImprovedSettingsUIEnabled?: boolean }>`
  background: ${colors.white};
  border: 1px solid ${colors.dutchie.gray30};
  border-radius: 12px;
  max-width: 800px;
  margin: 0 auto;

  ${({ isImprovedSettingsUIEnabled }) =>
    isImprovedSettingsUIEnabled &&
    css`
      max-width: unset;
      width: clamp(400px, 100%, 800px);
    `}
`;

const Content = styled.div<{ verticalPadding: boolean }>`
  padding: ${(props) => (props.verticalPadding ? '24px' : '0')} 24px;
`;

const FooterContainer = styled.div`
  align-items: end;
  border-top: 1px solid ${colors.dutchie.gray30};
  padding: 16px;
`;
