import React from 'react';

import { Pill } from "components/misc";
import { CheckmarkFilledIcon } from 'assets/icons/settings/CheckmarkFilledIcon';
import { UnavailableIcon } from 'assets/icons/settings/UnavailableIcon';

export type ConnectionStatusPillProps = {
  automationId?: string;
  connected: boolean;
  deviceName?: string;
};

export const ConnectionStatusPill = ({ automationId, connected, deviceName }: ConnectionStatusPillProps) => {
  const text = connected ? 'Connected' : 'Not connected';
  const variation = connected ? 'green' : 'grey';
  const icon = connected ? <CheckmarkFilledIcon /> : <UnavailableIcon />;
  const pill = <Pill automationId={automationId} icon={icon} text={text} variation={variation} />;

  if (deviceName) {
    return (
      <span title={`Connected to ${deviceName}`}>{pill}</span>
    );
  }

  return pill;
};
