import { HardwareService, useLabelPrinters } from '@dutchie/capacitor-hardware';
import { TEST_FOOTER, TEST_HEADER } from 'pages/SettingsPage/Printers/Constants/ZPLEncodingConstants';
import { useLabels } from 'pages/SettingsPage/Printers/hooks/useLabels';
import { SettingsSelectOption } from 'pages/SettingsPage/Select/SettingsSelect';
import { useHardwareSettingsDrawer } from '../hooks/useHardwareSettingsDrawer';
import { useAppDispatch, useAppSelector } from 'util/hooks';
import { errorNotification } from 'store/actions/NotificationsActions';

export const useLabelPrinterSettingsListItem = () => {
  const dispatch = useAppDispatch();
  const { showDrawer, hideDrawer, isDrawerOpen } = useHardwareSettingsDrawer();

  const locationName = useAppSelector((state) => state.user.selectedLocation?.location_name) ?? 'Location';

  const { labelPrinters, search } = useLabelPrinters();
  const options: SettingsSelectOption[] = labelPrinters.map((printer) => {
    return {
      value: printer.id,
      label: printer.name,
    };
  });

  const { labelDropdownOptions } = useLabels();
  const labelOptions: SettingsSelectOption[] = labelDropdownOptions.map((label) => {
    return {
      value: label.value.toString(),
      label: label.label,
    };
  });

  const buildTestPrint = (): Uint8Array => {
    const testString = `^FD^FD${locationName}^FS^A0,16^FO17,47^FB284,3^FDProduct Name (Bulk)^FS^FB2,1,0,C^A0,16^FO35,108^BY2,2,100^BC,34^FD0123420`;

    let bytes: number[] = [];
    bytes = bytes.concat(TEST_HEADER);
    for (let i = 0; i < testString.length; i++) {
      bytes.push(testString.charCodeAt(i));
    }
    bytes = bytes.concat(TEST_FOOTER);
    return Uint8Array.from(bytes);
  };

  const printTestLabel = async (printerId: string) => {
    const printer = HardwareService.labelPrinter.deviceById(printerId);
    if (!printer) {
      return;
    }

    try {
      const bytes = buildTestPrint();
      const success = await printer.print(bytes);
      if (!success) {
        throw new Error('Failed to print test label');
      }
    } catch (error) {
      dispatch(errorNotification('Failed to print test label'));
    }
  };

  return {
    isDrawerOpen,
    hideDrawer,
    showDrawer,
    labelOptions,
    options,
    printTestLabel,
    search,
  };
};
