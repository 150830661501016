
import { PaymentMethod, PaymentResultPusherNotification, PaymentType } from "models/Checkout";
import { useRefetchCartDetails } from "pages/CartPage/hooks/useRefetchCartDetails";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { addPaymentMethod, handlePinDebitErrorResponse, stopSpinning } from "store/actions/CheckoutActions";
import { successNotification } from "store/actions/NotificationsActions";
import { getIsHardwareLibraryActive } from "util/hooks/launch-darkly/useHardwareLibrary";
import { printRegalaReceipt as printRegalaReceiptHardwareLibrary } from 'util/hooks/printing/printJobs/usePrintRegalaReceipt';
import { v4 as uuidv4 } from 'uuid';
import { useAppSelector } from 'util/hooks';
import { usePrintJob } from 'util/hooks/printing/usePrintJob';
import { updateElectronicPaymentDetails } from 'store/actions/CartActions';
import { useTransactionManager } from "pages/CartPage/hooks/useTransactionManager";
import { useMetrics } from "util/metrics/useMetrics";

export const useOnPusherPaymentCompleted = () => {
    const dispatch = useDispatch();

    const printerId = useAppSelector((state) => state.settings.userSettings.selectedReceiptPrinter?.PrinterId);
    const localPrinter = useAppSelector((state) => state.settings.userSettings.selectedReceiptPrinter?.LocalPrinter);
    const { printRegalaReceipt } = usePrintJob();
    const refetchCartDetails = useRefetchCartDetails();

    const { shipmentId } = useTransactionManager();
    const metrics = useMetrics();

    const onPusherPaymentCompleted = useCallback(
        async (notification: PaymentResultPusherNotification) => {
            metrics.addCount('payments.pusher.payment-complete.message-receipt');

            if (notification.ShipmentId !== shipmentId) {
                metrics.addCount('payments.pusher.payment-complete.invalid-shipment-id');
                return;
            }

            if (notification.PaymentSuccess) {
                metrics.addCount('payments.pusher.payment-complete.payment-success');
                dispatch(updateElectronicPaymentDetails(notification));
                const paymentMethod: PaymentMethod = {
                    id: uuidv4(),
                    tipAmount: notification.TipAmount ?? 0,
                    name: 'Card',
                    amount: notification.TotalPaid,
                    type: PaymentType.Debit,
                };

                // DutchiePay charges are preauthorizations, which confuse the register into adding a $0.00
                // charge to the payment methods list. Theres no legitimate use case for a zero-sum payment,
                // so we can just strip these payments off the register.
                if (paymentMethod.amount !== 0) {
                    dispatch(addPaymentMethod(paymentMethod));
                    dispatch(successNotification('Electronic Payment Success'));
                }

                if (notification.RefreshCart) {
                    await refetchCartDetails();
                }

                //We need to print this receipt only if the payment made was regala pay/USAG
                //Therefore, print it if they're using DCDirect with USAG
                // Or if they are using hub and a debit payment was made with USAG
                if (notification.PrintUsagReceipt) {
                    const isHardwareLibraryActive = getIsHardwareLibraryActive();
                    if (isHardwareLibraryActive) {
                        await printRegalaReceiptHardwareLibrary({
                            dispatch,
                            popCashDrawer: false,
                            receiptParameters: shipmentId ?? 0,
                            receiptType: 'Receipt',
                            showDeliveryDetails: false,
                        });
                    } else {
                        printRegalaReceipt({
                            ReceiptType: 'Receipt',
                            ReceiptParameters: shipmentId,
                            ForDelivery: false,
                            PrinterId: printerId || 0,
                            localPrinter: localPrinter || false,
                            popCashDrawer: false,
                        });
                    }
                }
            } else {
                const message = notification.Message ?? 'Electronic Payment Failed';
                metrics.addCount('payments.pusher.payment-complete.payment-failure');
                await handlePinDebitErrorResponse(notification, dispatch, message);
            }

            dispatch(stopSpinning());
        },
        [shipmentId, dispatch, refetchCartDetails, printRegalaReceipt, printerId, localPrinter, metrics]
    );

    return onPusherPaymentCompleted;
};

