import { post } from 'api/HttpHelpers';
import {
  ProductHistoryDetail,
  Transaction,
  TransactionHistoryRecord,
  TransactionPaymentRecord,
} from 'models/Transactions';

export type TransactionsRequest = {
  guest_id?: number;
  includeCanceledTransactions?: boolean;
};

export type SearchSalesHistoryRequest = {
  Search?: string;
  includeCanceledTransactions?: boolean;
  fromDate: string;
  toDate: string;
  registerId: number;
};

export type TransactionHistoryRequest = {
  TransactionId?: number | string;
  Register?: number;
  IsCanceledTransaction?: boolean;
};

export type ItemReturnValueRequest = {
  AllocatedInventoryId: number;
};

export type ItemReturnDetailRequest = {
  AllocatedInventoryId: number;
  ShipmentId: number;
};

export enum RefundType {
  Cash = 'Cash',
  Credit = 'Credit',
  Debit = 'Debit',
  LoyaltyCredit = 'Loyalty-Credit',
  LoyaltyCharge = 'Loyalty-Charge',
}

export enum RefundTypeNum {
  Cash = 1,
  Credit = 2,
  Debit = 3,
  Loyalty = 4,
}

export type RefundDetail = {
  RefundType: RefundType;
  RefundValue: number;
  LoyaltyPointRefund: number;
  CardLast4: string | null;
  CardPresentRequired: boolean;
};

export type ItemReturnDetail = {
  ItemInventoryId: number;
  ItemName: string;
  RefundDetails: RefundDetail[];
};

export type ItemReturnValue = {
  CashBack: number;
  ReturnItemTotal: number;
  ReturnTotalCharges: number;
  ReturnDiscountAmt: number;
  TaxReturned: number;
  LoyaltyPointRedeposit: number;
};

type VoidTransactionRequest = {
  TransactionId?: number;
  Register?: number;
  PinUserId?: string;
  VoidReason?: string;
};

type TransactionPaymentRequest = {
  ShipmentId?: number;
};

type EmailRequest = {
  Email: string;
  Register?: number;
  ShipmentId?: number;
};

export const getTransactions = (body?: TransactionsRequest) => {
  return post<{ Data?: Array<Transaction> }>('v2/product/Transaction_History', body, { ignoreFormat: true });
};

export const getTransactionsV2 = (body?: TransactionsRequest) => {
  return post<{ Data?: Array<Transaction> }>('v2/transaction_history/Transaction_History', body, {
    ignoreFormat: true,
  });
};

export const searchSalesistory = (body?: SearchSalesHistoryRequest) => {
  return post<{ Data?: Array<Transaction> }>('v2/product/search-sales-history', body, { ignoreFormat: true });
};

export const getTransactionHistory = (body?: TransactionHistoryRequest) => {
  return post<Array<TransactionHistoryRecord>>('posv3/maintenance/GetTransactionHistory', body);
};

export const getTransactionPayment = (body?: TransactionPaymentRequest) => {
  return post<TransactionPaymentRecord>('v2/product/get_transaction_payment', body);
};

export const getProductHistoryDetail = (body?: TransactionHistoryRequest & { RegisterId?: number }) => {
  return post<{ Data?: Array<ProductHistoryDetail> }>('v2/product/product_History_Detail', body, {
    ignoreFormat: true,
  });
};

export const getItemReturnValue = (body: ItemReturnValueRequest) => {
  return post<ItemReturnValue>('v2/product/item_return_value', body);
};
export const getItemReturnDetails = (body: ItemReturnDetailRequest) => {
  return post<ItemReturnDetail>('v2/product/item_return_details', body);
};

export const voidTransaction = (body?: VoidTransactionRequest) => {
  return post<Array<ProductHistoryDetail>>('v2/cart/Void_Transaction', body);
};

export const sendEmail = (body: EmailRequest) => {
  return post('v2/cart/email_receipt', body);
};
