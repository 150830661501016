import React from 'react';
import { Column } from 'components/layout';
import { ConfigurationBarcode, ScannerBarcodeType } from './ScannerBarcode';
import { SetupInstructions } from './SetupInstructions';
import styled from 'styled-components';
import { useHardwareServices } from '../../../hooks/useHardwareServices';

export const ConfigureStep = () => {
  const { isWindows, isWebHidSupported } = useHardwareServices();

  const showConfigurationBarcode = !isWindows || isWebHidSupported;

  return (
    <Column>
      <SetupInstructions
        steps={[
          <Column>
            Scan factory reset barcode
            <Container>
              <ConfigurationBarcode type={ScannerBarcodeType.reset} />
            </Container>
          </Column>,
          ...(showConfigurationBarcode
            ? [
                <ConfigurationBarcodeContainer>
                  Scan configuration barcode
                  <Container>
                    <ConfigurationBarcode type={ScannerBarcodeType.configure} />
                  </Container>
                </ConfigurationBarcodeContainer>,
              ]
            : []),
        ]}
      />
    </Column>
  );
};

const Container = styled.div`
  margin: 8px 0 12px;
`;

const ConfigurationBarcodeContainer = styled(Column)`
  margin-top: 20px;
`;
