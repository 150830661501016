import { useFormikContext } from 'formik';
import { HardwareService, useLabelPrinters } from '@dutchie/capacitor-hardware';

import { LabelPrinterSettingsContextData } from '../types';
import { SettingsSelectOption } from 'pages/SettingsPage/Select/SettingsSelect';
import { HardwareSettings } from '../../types';
import { TEST_FOOTER, TEST_HEADER } from 'pages/SettingsPage/Printers/Constants/ZPLEncodingConstants';
import { useLabels } from 'pages/SettingsPage/Printers/hooks/useLabels';

export const useLabelPrinterSettingsProvider = (): LabelPrinterSettingsContextData => {
  const { values: formValues, setFieldValue } = useFormikContext<HardwareSettings>();

  const { labelDropdownOptions, handleSearchLabels } = useLabels();

  const labelOptions: SettingsSelectOption[] = labelDropdownOptions.map((label) => {
    return {
      value: label.value.toString(),
      label: label.label,
    };
  });

  const { labelPrinters: allPrinters, search } = useLabelPrinters({
    onDevicesAuthorized: (printers) => selectPrinter(printers[0]?.id),
  });

  /** @deprecated Switching to using Peripheral array */
  const options: SettingsSelectOption[] = allPrinters.map((printer) => {
    return {
      value: printer.id,
      label: printer.name,
    };
  });

  const buildTestPrint = (): Uint8Array => {
    const testString = 'TEST LOCAL LABEL PRINT';

    let bytes: number[] = [];
    bytes = bytes.concat(TEST_HEADER);
    for (let i = 0; i < testString.length; i++) {
      bytes.push(testString.charCodeAt(i));
    }
    bytes = bytes.concat(TEST_FOOTER);
    return Uint8Array.from(bytes);
  };

  const printTestLabel = async (printerId: string) => {
    const printer = HardwareService.labelPrinter.deviceById(printerId);
    if (!printer) {
      return;
    }

    const bytes = buildTestPrint();
    await printer.print(bytes);
  };

  const refreshLabels = () => handleSearchLabels({ userInitiated: true });

  const selectPrinter = (id?: string): void => setFieldValue('labelPrinterId', id);
  const setAutoPrintLabels = (value: boolean) => setFieldValue('autoPrintLabels', value);
  const setDefaultLabel = (id?: string) => setFieldValue('defaultLabelId', id);

  return {
    formValues,
    labelOptions,
    options,
    printers: allPrinters,
    printTestLabel,
    refreshLabels,
    search,
    selectPrinter,
    setAutoPrintLabels,
    setDefaultLabel,
  };
};
