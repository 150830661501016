import React from 'react';

import {
  PeripheralSetupConnectionStatus,
  ConnectionStatusBanner,
} from 'pages/SettingsPage/Status/ConnectionStatusBanner';
import { useConnectedScale } from 'util/hooks/hardware/useConnectedHardware';
import { isNativeScale } from '@dutchie/capacitor-peripheral';

export const ScaleStatusBanner = () => {
  const { device, hasPreferredDevice } = useConnectedScale();

  if (!hasPreferredDevice) {
    return null;
  }

  const status =
    device?.isConnected === true || (device && isNativeScale(device))
      ? PeripheralSetupConnectionStatus.connected
      : PeripheralSetupConnectionStatus.unavailable;
  return <ConnectionStatusBanner deviceTypeName='Scale' deviceName={device?.name} status={status} />;
};
