import { useLDClient } from 'launchdarkly-react-client-sdk';
import { getLDClient } from 'util/launchDarkly';

const PREVENT_ALLOTMENT_WARNING_KEY = 'pos.register.prevent-allotment-warning-before-guest-loads';

export const getPreventAllotmentWarningBeforeGuestLoads = () => {
  const ldClient = getLDClient();
  const isPreventAllotmentWarningsBeforeGuestLoadsEnabled =
    ldClient?.variation(PREVENT_ALLOTMENT_WARNING_KEY, false) === true;
  return {
    /** pos.register.prevent-allotment-warning-before-guest-loads */
    isPreventAllotmentWarningsBeforeGuestLoadsEnabled,
  };
};

export const usePreventAllotmentWarningBeforeGuestLoads = () => {
  const ldClient = useLDClient();
  const isPreventAllotmentWarningsBeforeGuestLoadsEnabled =
    ldClient?.variation(PREVENT_ALLOTMENT_WARNING_KEY, false) === true;
  return {
    /** pos.register.prevent-allotment-warning-before-guest-loads */
    isPreventAllotmentWarningsBeforeGuestLoadsEnabled,
  };
};
