import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { breakpoints, colors, zIndices } from 'css/Theme';
import { DoubleChevronIcon } from 'assets/icons/navigation/double-chevron';
import { DutchieLogoSmallWhite } from 'assets/icons/dutchie-logo-small-white';
import { NavigationItem, NavigationItemVariant } from 'components/layout/NavigationItem';
import { useBuildNumber } from 'util/hooks/useBuildNumber';
import { useNavigationItems } from 'util/hooks/useNavigationItems';
import { useResponsiveNavigationFlag } from 'util/hooks/launch-darkly/useResponsiveNavigationFlag';

type NavigationSidebarProps = {
  onOpenDrawer: () => void;
};

const SIDEBAR_WIDTH = 64;

export function NavigationSidebar(props: NavigationSidebarProps) {
  const { onOpenDrawer } = props;

  const buildNumber = useBuildNumber();
  const { primaryItems, secondaryItems } = useNavigationItems();
  const isResponsiveNavigationEnabled = useResponsiveNavigationFlag();

  return (
    <Container data-testid='navigation-sidebar-container' isResponsiveNavigationEnabled={isResponsiveNavigationEnabled}>
      <PrimaryItemsContainer data-testid='navigation-sidebar-primary-items'>
        <LogoLink to='/' data-testid='navigation-sidebar-logo-link'>
          <DutchieLogoSmallWhite height={24} width={24} />
        </LogoLink>
        {primaryItems.map((item) => (
          <NavigationItem
            key={item.label}
            item={item}
            parentWidth={SIDEBAR_WIDTH}
            variant={NavigationItemVariant.Sidebar}
          />
        ))}
      </PrimaryItemsContainer>
      <SecondaryItemsContainer data-testid='navigation-sidebar-secondary-items'>
        {secondaryItems.map((item) => (
          <NavigationItem
            key={item.label}
            item={item}
            parentWidth={SIDEBAR_WIDTH}
            variant={NavigationItemVariant.Sidebar}
          />
        ))}
        <Divider />
        <ExpandButton
          onClick={onOpenDrawer}
          data-testid='navigation-sidebar-expand-button'
          data-dd-action-name='open navigation drawer'
        >
          <DoubleChevronIcon color={colors.dutchie.opal40} />
        </ExpandButton>
        <BuildNumber>{buildNumber}</BuildNumber>
      </SecondaryItemsContainer>
    </Container>
  );
}

const Container = styled.div<{ isResponsiveNavigationEnabled: boolean }>`
  height: 100%;
  width: 64px;
  min-width: 64px;
  z-index: ${zIndices.navigationSidebar};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${colors.dutchie.opal90};

  ${({ isResponsiveNavigationEnabled }) => isResponsiveNavigationEnabled && css`
    ${breakpoints.smallTablet.maxQuery} {
      display: none;
    }
  `}
`;

const LogoLink = styled(NavLink)`
  position: sticky;
  top: 0;
  z-index: 1;
  height: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.dutchie.opal90};
`;

const PrimaryItemsContainer = styled.ul`
  background: ${colors.dutchie.opal90};
  overflow: auto;
  flex-grow: 1;
`;

const SecondaryItemsContainer = styled.ul`
  padding-bottom: 16px;
  background: ${colors.dutchie.opal90};
`;

const Divider = styled.hr`
  height: 1px;
  width: 100%;
  margin: 0;
  background: ${colors.dutchie.opal70};
  border: none;
`;

const ExpandButton = styled.button`
  appearance: none;
  height: 62px;
  width: 100%;
  margin: none;
  padding: none;
  background: none;
  border: none;
  cursor: pointer;
`;

const BuildNumber = styled.span`
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  color: ${colors.dutchie.opal60};
`;
