import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { ConfirmationPopup } from 'components/popups';
import { Input, Select } from 'components/inputs';
import { Label } from 'components/text';
import { Loader } from 'components/backoffice/loader';

import { reRouteProduct, getMMURProductForms, getBioTrackProductForms } from 'api/CartApi';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { useRefetchCartDetails } from 'pages/CartPage/hooks/useRefetchCartDetails';
import { useTransactionManager } from 'pages/CartPage/hooks/useTransactionManager';
import { useBooleanLDFlag } from '../../../util/launchDarkly';

import type { CartItem } from 'models/Cart';
import type { MMURFormOption, MMUROrderOption } from 'models/MMUR';

type ReReouteProductPopupProps = {
  hide: () => void;
  item: CartItem;
};

export const ReRouteProductPopup: FC<ReReouteProductPopupProps> = ({ hide, item }) => {
  const dispatch = useDispatch();

  const refetchCartDetails = useRefetchCartDetails();
  const { guestId, shipmentId } = useTransactionManager();

  const useBioTrack = useBooleanLDFlag('pos.traceability.replace-mmur-reroute-api-with-biotrack.rollout', false);

  const productId = item?.ProductId || 0;
  const serialNumber = item?.SerialNo || '';
  const formId = item?.MMURFormId;
  const orderId = item?.MMUROrderId;

  const [selectedFormId, setSelectedFormId] = useState<string | undefined>(formId?.toString());
  const [availableForms, setAvailableForms] = useState<MMURFormOption[]>([]);
  const [availableOrders, setAvailableOrders] = useState<MMUROrderOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    if (useBioTrack) {
      getBioTrackProductForms({ ProductId: productId, CustomerId: guestId ?? 0, ShipmentId: shipmentId ?? 0 }).then(
        (data) => {
          setAvailableForms(data.AvailableForms);
          setAvailableOrders(data.AvailableOrders);
          setLoading(false);
        }
      );
    } else {
      getMMURProductForms({ ProductId: productId, CustomerId: guestId ?? 0, ShipmentId: shipmentId ?? 0 }).then(
        (data) => {
          setAvailableForms(data.AvailableForms);
          setAvailableOrders(data.AvailableOrders);
          setLoading(false);
        }
      );
    }
  }, [productId, guestId, shipmentId, useBioTrack]);

  const reloadCart = () => refetchCartDetails();

  const saveRoute = async () => {
    if (!selectedFormId) {
      dispatch(errorNotification('Please select a valid Form'));
      return;
    }

    try {
      await reRouteProduct({
        ShipmentId: shipmentId ?? 0,
        SerialNumber: serialNumber,
        NewFormId: Number(selectedFormId),
        NewOrderId: Number(orderId),
      });
      reloadCart();
      dispatch(successNotification('Product Re-Routed'));
      hide();
    } catch {
      dispatch(errorNotification('Error re-routing product'));
    }
  };

  return (
    <ConfirmationPopup
      isVisible
      hide={() => {
        reloadCart();
        hide();
      }}
      title={`Re-route package ${serialNumber}`}
      confirm={{
        text: 'Re-route',
        onClick: saveRoute,
        disabled: !selectedFormId || loading,
      }}
    >
      {loading && (
        <Container alignCenter>
          <Loader variant='black' size='2x' />
        </Container>
      )}
      {!loading && (
        <Container>
          {!useBioTrack && (
            <Input label='Order' disabled value={availableOrders.find((x) => x.OrderId === orderId)?.OrderName || ''} />
          )}
          <div>
            <Label>Form</Label>
            <Select
              value={selectedFormId || ''}
              placeholder={'None found'}
              options={availableForms.map((route) => ({
                label: route.FormName,
                value: String(route.FormId),
              }))}
              onChange={(c) => setSelectedFormId(c)}
            />
          </div>
        </Container>
      )}
    </ConfirmationPopup>
  );
};

const Container = styled.div<{ alignCenter?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  gap: 1rem;

  ${({ alignCenter }) => alignCenter && `align-items: center;`}
`;
