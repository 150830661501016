import React from 'react';
import {
  ConnectionStatusBanner,
  PeripheralSetupConnectionStatus,
} from 'pages/SettingsPage/Status/ConnectionStatusBanner';
import { useConnectedLabelPrinter } from 'util/hooks/hardware/useConnectedHardware';

export const LabelPrinterStatusBanner = () => {
  const { device, hasPreferredDevice } = useConnectedLabelPrinter();

  if (!hasPreferredDevice) {
    return null;
  }

  const status = device ? PeripheralSetupConnectionStatus.connected : PeripheralSetupConnectionStatus.unavailable;
  return <ConnectionStatusBanner deviceTypeName='Printer' deviceName={device?.name} status={status} />;
};
