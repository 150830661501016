import React from 'react';
import styled from 'styled-components';
import { Column } from 'components/layout';
import { SetupTitle } from './styled';

export const SetupInstructions = ({ steps, title }: { steps: string[] | JSX.Element[]; title?: string }) => {
  return (
    <Column gap={8}>
      <SetupTitle>{title ?? 'Instructions'}</SetupTitle>
      <StyledList>
        {steps.map((step, idx) => (
          <li key={`instruction_${idx}`}>{step}</li>
        ))}
      </StyledList>
    </Column>
  );
};

const StyledList = styled.ol`
  padding-left: 1.5rem;
`;
