import React, { useCallback, useMemo } from 'react';
import { isUsbReceiptPrinter, PeripheralType, useReceiptPrinters } from '@dutchie/capacitor-hardware';

import { ReceiptIcon } from 'assets/icons/settings/ReceiptIcon';
import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { SettingsBottomDrawerForm } from '../SettingsBottomDrawerForm';
import { SettingsCard } from 'pages/SettingsPage/SettingsCard';
import { SettingsDivider } from 'pages/SettingsPage/SettingsDivider';
import { SaveSettingsFooter } from '../../SaveSettingsFooter';
import { useHardwareSettingsDrawer } from '../hooks/useHardwareSettingsDrawer';
import { ReceiptPrinterSettingsProvider } from './components/ReceiptPrinterSettingsProvider';
import { ReceiptPrinterStatusPill } from './components/ReceiptPrinterStatusPill';
import { ReceiptPrinterStatusBanner } from './components/ReceiptPrinterStatusBanner';
import { ReceiptPrinterSelectListItem } from './components/ReceiptPrinterSelectListItem';
import { ReceiptPrinterAutoPrintListItem } from './components/ReceiptPrinterAutoPrintListItem';
import { ReceiptPrinterTestPrintListItem } from './components/ReceiptPrinterTestPrintListItem';
import { AddDeviceListItem } from '../components/AddDeviceListItem/AddDeviceListItem';

export const ReceiptPrinterSettingsListItem = () => {
  const { showDrawer, hideDrawer, isDrawerOpen } = useHardwareSettingsDrawer();

  const { receiptPrinters, search } = useReceiptPrinters();

  const hasUsbPrinters = useMemo(() => {
    return receiptPrinters.filter(isUsbReceiptPrinter).length > 0;
  }, [receiptPrinters]);

  const handleAuthorize = useCallback(() => {
    search({ requestNewDevices: true });
  }, [search]);

  return (
    <>
      <SettingsListItem
        automationId='receipt_printer_settings'
        icon={<ReceiptIcon />}
        title='Receipt printer'
        subtitle='Settings for receipt printer'
        onClick={showDrawer}
        actions={[<ReceiptPrinterStatusPill />]}
      />
      <SettingsBottomDrawerForm
        automationId='receipt_printer_settings-drawer'
        title='Receipt printer'
        open={isDrawerOpen}
        hide={hideDrawer}
      >
        <ReceiptPrinterSettingsProvider>
          <SettingsCard footer={<SaveSettingsFooter />}>
            <ReceiptPrinterStatusBanner />
            <ReceiptPrinterSelectListItem />
            <SettingsDivider />
            <ReceiptPrinterAutoPrintListItem />
            <SettingsDivider />
            <ReceiptPrinterTestPrintListItem />
          </SettingsCard>
          <AddDeviceListItem
            deviceType={PeripheralType.receiptPrinter}
            isDeviceDetected={hasUsbPrinters}
            onAuthorize={handleAuthorize}
          />
        </ReceiptPrinterSettingsProvider>
      </SettingsBottomDrawerForm>
    </>
  );
};
