import React, { useCallback, useMemo } from 'react';
import { HardwareService, isUsbReceiptPrinter, PeripheralType } from '@dutchie/capacitor-hardware';

import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { SettingsSelect } from 'pages/SettingsPage/Select/SettingsSelect';
import { useAddNewDevice } from '../../components/AddDeviceListItem/useAddNewDevice';
import { useFulfillmentReceiptPrinterSettings } from '../hooks/useFulfillmentReceiptPrinterSettings';
import { useHardwareServices } from '../../hooks/useHardwareServices';
import { useImprovedSettingsUI } from 'util/hooks/launch-darkly/useImprovedSettingsUI';

export const FulfillmentReceiptPrinterSelectListItem = () => {
  const { formValues, options, printers, search, selectPrinter } = useFulfillmentReceiptPrinterSettings();
  const { isWebUsbSupported } = useHardwareServices();

  const hasUsbPrinters = useMemo(() => {
    return printers.filter(isUsbReceiptPrinter).length > 0;
  }, [printers]);

  const handleAuthorize = useCallback(() => {
    search({ requestNewDevices: true });
  }, [search]);

  const handleRefresh = async () => {
    HardwareService.receiptPrinter.filterEnabled = true;
    search({ requestNewDevices: true });
  };

  const handleShowAll = async () => {
    HardwareService.receiptPrinter.filterEnabled = false;
    search({ requestNewDevices: true });
  };

  const { handleShowAddNewDeviceModal } = useAddNewDevice({
    deviceType: PeripheralType.receiptPrinter,
    isDeviceDetected: hasUsbPrinters,
    onAuthorize: handleAuthorize,
  });

  const { isImprovedSettingsUIEnabled } = useImprovedSettingsUI();

  return (
    <SettingsListItem
      title={isImprovedSettingsUIEnabled ? 'Select fulfillment ticket printer' : 'Fulfillment ticket printer'}
      subtitle={isImprovedSettingsUIEnabled ? undefined : 'Select printer for all fulfillment tickets'}
      actions={[
        <SettingsSelect
          automationId='fulfillment_printer_settings-select'
          devices={printers}
          emptyOptionsMessage='No printers available'
          value={formValues.fulfillmentPrinterId}
          placeholder='Select fulfillment printer'
          onChange={(option) => selectPrinter(option?.value)}
          onRefresh={handleRefresh}
          onShowAll={isWebUsbSupported ? handleShowAll : undefined}
          options={options}
          secondaryAction={
            isWebUsbSupported ? { label: 'Add new printer', onClick: handleShowAddNewDeviceModal } : undefined
          }
        />,
      ]}
    />
  );
};
