import React, { useCallback, useMemo } from 'react';
import { isHidScanner, PeripheralType, useScanners } from '@dutchie/capacitor-hardware';

import { SettingsListItem } from 'pages/SettingsPage/ListItem/SettingsListItem';
import { SettingsCard } from 'pages/SettingsPage/SettingsCard';
import { SettingsDivider } from 'pages/SettingsPage/SettingsDivider';
import { SaveSettingsFooter } from '../../SaveSettingsFooter';
import { BarcodeScannerIcon } from 'assets/icons/settings/BarcodeScannerIcon';
import { useHardwareSettingsDrawer } from '../hooks/useHardwareSettingsDrawer';
import { ScannerSettingsProvider } from './components/ScannerSettingsProvider';
import { ScannerStatusPill } from './components/ScannerStatusPill';
import { ScannerStatusBanner } from './components/ScannerStatusBanner';
import { ScannerSelectListItem } from './components/ScannerSelectListItem';
import { ScannerSetupListItem } from './components/ScannerSetupListItem';
import { SettingsBottomDrawerForm } from '../SettingsBottomDrawerForm';
import { ScannerTestListItem } from './components/ScannerTestListItem';
import { AddDeviceListItem } from '../components/AddDeviceListItem/AddDeviceListItem';

export const ScannerSettingsListItem = () => {
  const { showDrawer, hideDrawer, isDrawerOpen } = useHardwareSettingsDrawer();

  const { scanners, search } = useScanners();

  const hasHidScanners = useMemo(() => {
    return scanners.filter(isHidScanner).length > 0;
  }, [scanners]);

  const handleAuthorize = useCallback(() => {
    search({ requestNewDevices: true });
  }, [search]);

  return (
    <>
      <SettingsListItem
        automationId='scanner_settings'
        icon={<BarcodeScannerIcon />}
        title={'Barcode scanner'}
        subtitle={'Settings for barcode scanner'}
        onClick={showDrawer}
        actions={[<ScannerStatusPill />]}
      />
      <SettingsBottomDrawerForm
        automationId='scanner_settings-drawer'
        title='Barcode scanner'
        open={isDrawerOpen}
        hide={hideDrawer}
      >
        <ScannerSettingsProvider>
          <SettingsCard footer={<SaveSettingsFooter />}>
            <ScannerStatusBanner />
            <ScannerSelectListItem />
            <SettingsDivider />
            <ScannerTestListItem />
            <SettingsDivider />
            <ScannerSetupListItem />
          </SettingsCard>
          <AddDeviceListItem
            deviceType={PeripheralType.scanner}
            isDeviceDetected={hasHidScanners}
            onAuthorize={handleAuthorize}
          />
        </ScannerSettingsProvider>
      </SettingsBottomDrawerForm>
    </>
  );
};
