import React from 'react';
import {
  PeripheralSetupConnectionStatus,
  ConnectionStatusBanner,
} from 'pages/SettingsPage/Status/ConnectionStatusBanner';
import { useConnectedZReportPrinter } from 'util/hooks/hardware/useConnectedHardware';

export const ZReportReceiptPrinterStatusBanner = () => {
  const { device, hasPreferredDevice } = useConnectedZReportPrinter();

  if (!hasPreferredDevice) {
    return null;
  }

  const status = device ? PeripheralSetupConnectionStatus.connected : PeripheralSetupConnectionStatus.unavailable;
  return <ConnectionStatusBanner deviceTypeName='Printer' deviceName={device?.name} status={status} />;
};
