import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useGetCartDetails } from 'pages/CartPage/hooks/useGetCartDetails';
import { useSelector } from 'react-redux';
import { State } from 'store';

type LoyaltyCalcuations = {
  totalPoints: number;
  appliedPoints: number;
  availablePoints: number;
  availablePointsRemaining: number;
  pointsSpentByDiscounts: number;
  userHasNoLoyaltyPoints?: boolean;
};

export const useLoyaltyCalculations = (): LoyaltyCalcuations => {
  // Feature flags
  const isExternalLoyalty = useSelector((state: State) => state.settings.features.ShowExternalLoyalty);
  const isLoyaltyAsDiscount = useSelector((state: State) => state.settings.features.LoyaltyAsDiscount);
  const ldClient = useLDClient();
  // Transaction loyalty points are recorded to the ledger only on checkout, so when the
  // loyalty ledger is rolled out (phases 2/3) it behaves like an external loyalty.
  const loyaltyServiceRolloutMode = ldClient?.variation('pos.customers.loyalty-service.rollout-phase', 0);
  const usingLoyaltyLedger = loyaltyServiceRolloutMode > 1;

  // Needed customer and cart data
  const guestPoints = useSelector((state: State) => state.customer.details?.LoyaltyPoints) ?? 0;

  const {
    data: {
      GrandTotalRounded: cartGrandTotalRounded,
      Loyalty,
      SubTotal: cartSubTotal,
      TotalDiscountAndLoyalty: cartTotalDiscountAndLoyalty,
    },
  } = useGetCartDetails();

  const cartAppliedPoints = Loyalty?.AppliedLoyaltyPoints ?? 0;
  const pointsSpentByDiscounts = Loyalty?.AppliedLoyaltyFromDiscounts ?? 0;
  const preventNegativeLoyaltyFlag = ldClient?.variation(
    'core.cats.prevent-negative-loyalty-application.rollout',
    false
  );

  const { totalPoints, appliedPoints, availablePoints, availablePointsRemaining } = calculateLoyaltyDisplayPoints({
    cartAppliedPoints,
    cartGrandTotalRounded,
    cartSubTotal,
    cartTotalDiscountAndLoyalty,
    guestPoints,
    isExternalLoyalty,
    isLoyaltyAsDiscount,
    pointsSpentByDiscounts,
    usingLoyaltyLedger,
  });

  return {
    totalPoints,
    appliedPoints,
    availablePoints,
    availablePointsRemaining,
    pointsSpentByDiscounts,
    userHasNoLoyaltyPoints: preventNegativeLoyaltyFlag ? availablePointsRemaining <= 0 : availablePointsRemaining === 0,
  };
};

type TruncateParams = {
  value: number;
  places?: number;
};

export function truncate(params: TruncateParams) {
  const { value, places = 0 } = params;
  const [integerPart, fractionalPart] = value.toString().split('.');

  let truncated = integerPart;

  if (fractionalPart && places) {
    truncated = `${truncated}.${fractionalPart.slice(0, places)}`;
  }

  return Number(truncated);
}

type CalculateLoyaltyDisplayPointsParams = {
  guestPoints?: number;
  cartAppliedPoints?: number;
  cartSubTotal?: number;
  cartGrandTotalRounded?: number;
  cartTotalDiscountAndLoyalty?: number;
  isExternalLoyalty?: boolean;
  isLoyaltyAsDiscount?: boolean;
  pointsSpentByDiscounts?: number;
  usingLoyaltyLedger?: boolean;
};

export function calculateLoyaltyDisplayPoints(params: CalculateLoyaltyDisplayPointsParams = {}): LoyaltyCalcuations {
  const {
    guestPoints = 0,
    cartAppliedPoints = 0,
    cartSubTotal = 0,
    cartGrandTotalRounded = 0,
    cartTotalDiscountAndLoyalty = 0,
    isExternalLoyalty = false,
    isLoyaltyAsDiscount = false,
    pointsSpentByDiscounts = 0,
    usingLoyaltyLedger = false,
  } = params;


  // When we're fully transitioned to the new ledger, internal and external loyalty calculations will be
  // the same, based on loyalty paid in this current shipment not being automatically subtracted from the
  // customer balance, as they are for legacy loyalty.
  const guestPointsExcludeCurrentCart = isExternalLoyalty || usingLoyaltyLedger;

  // calculate total points
  const totalPoints = guestPointsExcludeCurrentCart ? guestPoints - cartAppliedPoints : guestPoints - pointsSpentByDiscounts;

  // calculate applicable cart value
  const applicableCartValue = isLoyaltyAsDiscount ? cartSubTotal - cartTotalDiscountAndLoyalty : cartGrandTotalRounded;

  // determine maximum available points up to applicable cart value
  const availablePoints = Math.max(totalPoints, 0);
  const availablePointsRemaining = Math.max(Math.min(availablePoints, applicableCartValue), 0);

  // truncate all values to 2 decimal places
  return {
    totalPoints: truncate({ value: totalPoints, places: 2 }),
    appliedPoints: truncate({ value: cartAppliedPoints, places: 2 }),
    availablePoints: truncate({ value: availablePointsRemaining, places: 2 }),
    availablePointsRemaining: truncate({ value: availablePointsRemaining, places: 2 }),
    pointsSpentByDiscounts: truncate({ value: pointsSpentByDiscounts, places: 2 }),
  };
}
