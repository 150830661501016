import { createSlice, ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { reduce } from 'lodash';
import {
  LabelResponse,
  PrinterResponse,
  RegisterResponse,
  UserSettings,
  Register,
  RegisterTransactionRecord,
  CustomerSearchConfig,
  PreorderConfig,
  EnvironmentDetails,
} from 'models/Misc';
import { setBaseUrl } from 'api/HttpHelpers';
import { RegionList } from 'util/RegionList';
import { login, logout, SelectLocationAction } from 'store/actions/UserActions';
import { Storage } from '@capacitor/storage';
import { Capacitor } from '@capacitor/core';
import {
  LocationSettings,
  LocationIntegrations,
  BuildNumberResponse,
  HelpDeskLinkResponse,
  LocationSettingsResponse,
} from 'models/Location';
import { ValidatedForms, ValidatedFormsResponse, DefaultStatuses, CustomerType, Reason } from 'models/Settings';
import {
  assignRegisterToHardware,
  loadBuildNumber,
  loadCancellationReasons,
  loadCustomerTypes,
  loadDefaultStatuses,
  loadHelpDeskLink,
  loadLabelPrinters,
  loadLabels,
  loadLocationSettings,
  loadPermissions,
  loadReceiptPrinters,
  loadRegisters,
  loadRegisterTransactions,
  loadUserSettings,
  loadValidatedForms,
  loadVisitReasons,
  loadVoidReasons,
  saveUserSettings,
  selectRegister,
  setSidebarExpanded,
  validateMassDPHSavedCredentials,
  selectUserRegion,
  loadRegion,
  updateLabelPrinterStatus,
  updateReceiptPrinterStatus,
  loadPreorderConfig,
  setExternalSitesLoaded,
  loadEnvironmentSettings,
  setCfdStatus,
  featuresUpdated,
  settingsUpdated,
  setServerMigrationConfig,
} from 'store/actions/SettingsActions';
import { getUseNewSettingsUi } from 'util/hooks/launch-darkly/useNewSettingsUi';
import { LDServerMigrationConfig } from 'components/layout/ServerMigrationUI/useServerMigrationConfig';
import { isWebViewApp } from 'util/hooks';
import { getLDClient } from 'util/launchDarkly';

export type SettingsState = {
  registers: Array<RegisterResponse>;
  selectedRegister?: Register;
  registersLoading: boolean;
  labels: Array<LabelResponse>;
  labelPrinters: Array<PrinterResponse>;
  receiptPrinters: Array<PrinterResponse>;
  isCfdConnected: boolean;
  userSettings: UserSettings;
  integrations?: LocationIntegrations;
  features: Record<string, boolean>;
  permissions: Record<string, boolean>;
  locationSettings?: LocationSettings;
  buildNumber?: BuildNumberResponse;
  validatedForms?: ValidatedForms;
  validatedFormsLoading: boolean;
  defaultStatuses?: DefaultStatuses;
  customerTypes?: Array<CustomerType>;
  cancellationReasons?: Array<Reason>;
  visitReasons?: Array<Reason>;
  voidReasons?: Array<Reason>;
  sidebarExpanded: boolean;
  externalSitesLoaded: boolean;
  MassDPHCredsValid: boolean;
  helpDeskLink?: HelpDeskLinkResponse;
  registerTransactions: Array<RegisterTransactionRecord>;
  MapboxKey: string;
  DeliveryTitleFormat?: string;
  Lat: number;
  Lng: number;
  region?: string;
  preorderSettings?: PreorderConfig;
  environmentDetails?: EnvironmentDetails;
  serverMigrationConfig?: LDServerMigrationConfig;
};

export const initialSettingsState: SettingsState = {
  registers: [],
  selectedRegister: undefined,
  registersLoading: false,
  labels: [],
  labelPrinters: [],
  receiptPrinters: [],
  isCfdConnected: false,
  userSettings: {
    useConnectedLabelPrinter: false,
    useConnectedReceiptPrinter: false,
    useConnectedFulfillmentPrinter: false,
    selectedLabel: undefined,
    selectedLabelPrinter: undefined,
    selectedReceiptPrinter: undefined,
    selectedFulfillmentPrinter: undefined,
    autoPrintLabels: false,
    customerSearchConfig: undefined,
    autoPrintReceipts: false,
    autoPrintFulfillmentTicket: false,
    showGuests: false,
    showPopupNotes: false,
    showSearch: true,
  },
  features: {},
  permissions: {},
  locationSettings: undefined,
  buildNumber: undefined,
  validatedForms: undefined,
  validatedFormsLoading: false,
  defaultStatuses: undefined,
  customerTypes: [],
  visitReasons: [],
  cancellationReasons: [],
  voidReasons: [],
  sidebarExpanded: false,
  externalSitesLoaded: false,
  MassDPHCredsValid: false,
  helpDeskLink: undefined,
  registerTransactions: [],
  MapboxKey: '',
  DeliveryTitleFormat: '',
  Lat: 0,
  Lng: 0,
  region: undefined,
  preorderSettings: undefined,
  environmentDetails: undefined,
  serverMigrationConfig: {
    deadlineDateLabel: '',
    shouldShowBlockingUI: false,
    registerURL: '',
  },
};
type loadLocationSettingsOptions = {
  resp: LocationSettingsResponse;
  setDefaults: boolean;
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialSettingsState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<SettingsState>) => {
    builder.addCase(login, (state) => {
      const ldClient = getLDClient();
      const isClearRegisterOnLoginEnabled: boolean = ldClient?.variation('pos.register.clear-register-on-login.rollout', false);

      if (isClearRegisterOnLoginEnabled) {
        state.selectedRegister = undefined;
      }
    });
    builder.addCase(logout, (state: SettingsState) => {
      state.labels = initialSettingsState.labels;
      state.labelPrinters = initialSettingsState.labelPrinters;
      state.receiptPrinters = initialSettingsState.receiptPrinters;
      state.registers = initialSettingsState.registers;
      state.selectedRegister = initialSettingsState.selectedRegister;
      state.features = initialSettingsState.features;
      state.permissions = initialSettingsState.permissions;
      state.integrations = initialSettingsState.integrations;
      state.userSettings = initialSettingsState.userSettings;
      state.locationSettings = initialSettingsState.locationSettings;
      state.buildNumber = initialSettingsState.buildNumber;
    });
    builder.addCase(
      assignRegisterToHardware.fulfilled,
      (state: SettingsState, action: PayloadAction<number | undefined>) => {
        const selectedRegister = state.registers.find((x) => x.id === action.payload);
        if (selectedRegister) {
          state.selectedRegister = { label: selectedRegister.TerminalName, value: selectedRegister.id };
        }
      }
    );
    builder.addCase(setCfdStatus, (state: SettingsState, action: PayloadAction<boolean>) => {
      if (Capacitor.getPlatform() === 'android' || getUseNewSettingsUi()) {
        state.isCfdConnected = action.payload;
      }
    });
    builder.addCase(saveUserSettings, (state: SettingsState, action: PayloadAction<Partial<UserSettings>>) => {
      if (Capacitor.getPlatform() === 'android') {
        if (action.payload.selectedLabelPrinter) {
          if (action.payload.selectedLabelPrinter.LocalPrinter) {
            Storage.set({ key: 'LocalLabelPrinter', value: JSON.stringify(action.payload.selectedLabelPrinter) });
          } else {
            Storage.remove({ key: 'LocalLabelPrinter' });
          }
        }

        if (action.payload.selectedReceiptPrinter) {
          if (action.payload.selectedReceiptPrinter.LocalPrinter) {
            Storage.set({ key: 'LocalReceiptPrinter', value: JSON.stringify(action.payload.selectedReceiptPrinter) });
          } else {
            Storage.remove({ key: 'LocalReceiptPrinter' });
          }
        }

        if (action.payload.selectedFulfillmentPrinter) {
          if (action.payload.selectedFulfillmentPrinter.LocalPrinter) {
            Storage.set({
              key: 'LocalFulfillmentPrinter',
              value: JSON.stringify(action.payload.selectedFulfillmentPrinter),
            });
          } else {
            Storage.remove({ key: 'LocalFulfillmentPrinter' });
          }
        }

        if (action.payload.useConnectedLabelPrinter !== undefined) {
          Storage.set({
            key: 'useConnectedLabelPrinter',
            value: JSON.stringify(action.payload.useConnectedLabelPrinter),
          });
        }

        if (action.payload.useConnectedReceiptPrinter !== undefined) {
          Storage.set({
            key: 'useConnectedReceiptPrinter',
            value: JSON.stringify(action.payload.useConnectedReceiptPrinter),
          });
        }

        if (action.payload.useConnectedFulfillmentPrinter !== undefined) {
          Storage.set({
            key: 'useConnectedFulfillmentPrinter',
            value: JSON.stringify(action.payload.useConnectedFulfillmentPrinter),
          });
        }
      }

      state.userSettings = { ...state.userSettings, ...action.payload } as UserSettings;
    });
    builder.addCase(setSidebarExpanded, (state, action) => {
      state.sidebarExpanded = action.payload;
    });
    builder.addCase(setExternalSitesLoaded, (state, action) => {
      state.externalSitesLoaded = action.payload;
    });
    builder.addCase(updateLabelPrinterStatus.fulfilled, (state, action) => {
      if (action.payload.deviceId && state.userSettings.selectedLabelPrinter?.PrinterId === action.payload.deviceId) {
        state.userSettings.selectedLabelPrinter.Status = action.payload.status;
      }
    });
    builder.addCase(updateReceiptPrinterStatus.fulfilled, (state, action) => {
      if (action.payload.deviceId && state.userSettings.selectedReceiptPrinter?.PrinterId === action.payload.deviceId) {
        state.userSettings.selectedReceiptPrinter.Status = action.payload.status;
      }
      if (
        action.payload.deviceId &&
        state.userSettings.selectedFulfillmentPrinter?.PrinterId === action.payload.deviceId
      ) {
        state.userSettings.selectedFulfillmentPrinter.Status = action.payload.status;
      }
    });
    builder.addCase(SelectLocationAction.fulfilled, (state: SettingsState) => {
      state.selectedRegister = undefined;
    });
    builder.addCase(validateMassDPHSavedCredentials.fulfilled, (state) => {
      state.MassDPHCredsValid = true;
    });
    builder.addCase(validateMassDPHSavedCredentials.rejected, (state) => {
      state.MassDPHCredsValid = false;
    });
    builder.addCase(selectRegister.fulfilled, (state: SettingsState, action: PayloadAction<Register | undefined>) => {
      state.selectedRegister = action.payload;
    });
    builder.addCase(loadLabels.fulfilled, (state: SettingsState, action: PayloadAction<Array<LabelResponse>>) => {
      state.labels = action.payload;
    });
    builder.addCase(selectUserRegion, (state: SettingsState, action: PayloadAction<string | undefined>) => {
      if (isWebViewApp) {
        state.region = action.payload;
        return;
      }

      if (action.payload) {
        setBaseUrl(RegionList[action.payload]);
        Storage.set({ key: 'Region', value: action.payload });
      } else {
        Storage.remove({ key: 'Region' });
      }
      state.region = action.payload;
    });
    builder.addCase(loadRegion.fulfilled, (state: SettingsState, action: PayloadAction<string | undefined>) => {
      state.region = action.payload || undefined;
    });
    builder.addCase(
      loadLabelPrinters.fulfilled,
      (state: SettingsState, action: PayloadAction<Array<PrinterResponse>>) => {
        state.labelPrinters = action.payload;
      }
    );
    builder.addCase(
      loadReceiptPrinters.fulfilled,
      (state: SettingsState, action: PayloadAction<Array<PrinterResponse>>) => {
        state.receiptPrinters = action.payload;
      }
    );
    builder.addCase(loadRegisters.pending, (state: SettingsState) => {
      state.registersLoading = true;
    });
    builder.addCase(loadRegisters.fulfilled, (state: SettingsState, action: PayloadAction<Array<RegisterResponse>>) => {
      state.registers = action.payload;
      state.registersLoading = false;
    });
    builder.addCase(loadRegisters.rejected, (state: SettingsState) => {
      state.registersLoading = false;
    });
    builder.addCase(
      loadLocationSettings.fulfilled,
      (state: SettingsState, { payload }: PayloadAction<loadLocationSettingsOptions>) => {
        const features = reduce(
          payload.resp.Features,
          (acc, feature) => {
            return { ...acc, [feature.FeatureName]: feature.IsEnabled };
          },
          {} as Record<string, boolean>
        );
        state.features = features;
        state.integrations = payload.resp.Integrations;
        state.locationSettings = payload.resp.Settings;
        state.MassDPHCredsValid = payload.resp.MassDphValid || false;
        state.Lat = payload.resp.Lat || 0;
        state.Lng = payload.resp.Lng || 0;
        state.MapboxKey = payload.resp.MapboxKey;
        state.DeliveryTitleFormat = payload.resp.DeliveryTitleFormat;
        if (payload.setDefaults) {
          state.userSettings = { ...state.userSettings };
        }
      }
    );
    builder.addCase(loadBuildNumber.fulfilled, (state: SettingsState, action: PayloadAction<BuildNumberResponse>) => {
      state.buildNumber = action.payload;
    });
    builder.addCase(
      loadUserSettings.fulfilled,
      (state: SettingsState, action: PayloadAction<Array<CustomerSearchConfig>>) => {
        state.userSettings.customerSearchConfig = action.payload;
      }
    );
    builder.addCase(loadPermissions.fulfilled, (state: SettingsState, action: PayloadAction<Array<String>>) => {
      const permissions = reduce(
        action.payload,
        (acc, permissionName) => {
          return { ...acc, [permissionName.toString()]: true };
        },
        {} as Record<string, boolean>
      );
      state.permissions = permissions;
    });
    builder.addCase(loadValidatedForms.pending, (state) => {
      state.validatedForms = undefined;
      state.validatedFormsLoading = true;
    });
    builder.addCase(loadValidatedForms.fulfilled, (state, action) => {
      const validatedForms = reduce<ValidatedFormsResponse, ValidatedForms>(
        action.payload,
        (acc, field) => {
          acc[field.FormName] = {
            ...acc[field.FormName],
            [field.DisplayName]: {
              Required: field.CustomerOption === 2 || field.SystemRequired,
              Hidden: field.CustomerOption === 3,
            },
          };
          return acc;
        },
        {}
      );
      state.validatedForms = validatedForms;
      state.validatedFormsLoading = false;
    });
    builder.addCase(loadValidatedForms.rejected, (state) => {
      state.validatedFormsLoading = false;
    });
    builder.addCase(loadDefaultStatuses.fulfilled, (state, action) => {
      state.defaultStatuses = action.payload;
    });
    builder.addCase(loadCancellationReasons.fulfilled, (state, action) => {
      state.cancellationReasons = action.payload;
    });
    builder.addCase(loadVisitReasons.fulfilled, (state, action) => {
      state.visitReasons = action.payload;
    });
    builder.addCase(loadVoidReasons.fulfilled, (state, action) => {
      state.voidReasons = action.payload;
    });
    builder.addCase(loadCustomerTypes.fulfilled, (state, action) => {
      state.customerTypes = action.payload;
    });
    builder.addCase(loadHelpDeskLink.fulfilled, (state, action) => {
      state.helpDeskLink = action.payload;
    });
    builder.addCase(loadRegisterTransactions.fulfilled, (state, action) => {
      state.registerTransactions = action.payload;
    });
    builder.addCase(loadPreorderConfig.fulfilled, (state, action) => {
      state.preorderSettings = action.payload;
    });
    builder.addCase(loadEnvironmentSettings.fulfilled, (state, action) => {
      state.environmentDetails = action.payload;
    });
    builder.addCase(featuresUpdated, (state, action) => {
      for (const feat of action.payload.Features) {
        state.features[feat.FeatureName] = feat.IsEnabled;
      }

      state.integrations = action.payload.Integrations;
    });
    builder.addCase(settingsUpdated, (state, action) => {
      for (const feat of action.payload.Features) {
        state.features[feat.FeatureName] = feat.IsEnabled;
      }
      state.integrations = action.payload.Integrations;
      state.locationSettings = action.payload.DefaultSettings;
    });
    builder.addCase(setServerMigrationConfig, (state, action) => {
      state.serverMigrationConfig = action.payload;
    });
  },
});
