import React, { FC, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { find } from 'lodash';
import { validateBiotrackUser } from 'api/GuestApi';
import { State } from 'store';
import { CustomerAddress, CustomerDetails, EditAllotmentTypeEnum, EditIdentificationTypeEnum } from 'models/Customer';
import {
  SectionDiv,
  SectionH3,
  SectionHeaderSpan,
  StyledSelectField,
  InputField,
  IconButton,
} from 'pages/EditCustomerPage/style';
import {
  ContactMethodOptions,
  CustomerStatusOptions,
  GenderOptions,
  UpdatedGenderOptions,
  StateOptions,
  CountryOptions,
  ProvinceOptions,
  OptInOptions,
} from 'components/inputs/defaults';
import { ReactComponent as PlusIcon } from 'assets/icons/roundedPlus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { IdentificationImage } from 'pages/EditCustomerPage/components/IdentificationImage';
import {
  TopInfoLine as _TopInfoLine,
  TopInfoLabel as _TopInfoLabel,
} from 'pages/EditCustomerPage/components/TopInfoLine';
import { EditLoyaltyPointsPopup } from 'pages/EditCustomerPage/components/EditLoyaltyPointsPopup';
import { EditAllotmentPopup } from 'pages/EditCustomerPage/components/EditAllotmentPopup';
import { ClearAllotmentOverridePopup } from 'pages/EditCustomerPage/components/ClearAllotmentOverridePopup';
import { EditIdentificationPopup } from 'pages/EditCustomerPage/components/EditIdentificationPopup';
import { EditExternalPatientAllotmentPopup } from 'pages/EditCustomerPage/components/EditExternalPatientAllotmentPopup';
import { Identification } from 'pages/EditCustomerPage/components/Identification';
import { usePopup } from 'components/popups';
import { DatePickerField } from 'components/inputs';
import { DaysSupplyCalculator } from '../../components/DaysSupplyCalculator';
import { errorNotification, successNotification } from 'store/actions/NotificationsActions';
import { medicalCustomerTypes } from 'util/Helpers';
import { ValidatedField } from 'models/Settings';
import { breakpoints, colors } from 'css/Theme';
import { removeAddressBookItem } from 'store/actions/CustomerActions';
import { DeleteCustomerAddressPopup } from 'components/CustomerPopups/components/DeleteCustomerAddressPopup';
import moment from 'moment';
import { useDynamicYearRange } from 'util/helpers/date-helpers/getDynamicYearRange';
import { Button } from 'components/buttons';
import { getAllotmentValueToDisplay } from 'util/helpers/allotment';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useSecondaryIdentificationQuery } from 'queries/v2/guest/secondary-identification';
import { useCuraleafLoyaltyTier } from 'util/hooks/launch-darkly/useCuraleafLoyaltyTier';
import { useNewGenderOptionsEnabled } from 'util/hooks/launch-darkly/useNewGenderOptionsEnabled';
import { useIsDaysSupplyFeatureEnabled } from 'util/hooks/features/useIsDaysSupplyFeatureEnabled';

type PersonalTabProps = {
  customer?: CustomerDetails;
  fieldOptions?: ValidatedField;
  setCustomerTypeId: React.Dispatch<React.SetStateAction<null | string>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema?: any;
};

export const PersonalTab: FC<PersonalTabProps> = ({ customer, fieldOptions, setCustomerTypeId, validationSchema }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customerTypeRef = useRef<any>();
  const { values, setFieldValue } = useFormikContext<CustomerDetails>();
  const isCanada = values.address.Country_Code === 'CA';
  const referralSources = useSelector((state: State) => state.customer.options.referralSources);
  const referralSourcesLoading = useSelector((state: State) => state.customer.options.referralSourcesLoading);
  const discountGroups = useSelector((state: State) => state.customer.availableGroups);
  const settings = useSelector((state: State) => state.settings);
  const maxDiscountGroups = 6;
  const maxAddresses = 4;
  const [referralSource, setReferralSource] = useState(false);
  const [mmjOutOfState, setmmjOutOfState] = useState(false);
  const [showMedicalInfo, setShowMedicalInfo] = useState(false);
  const permissions = useSelector((state: State) => state.settings.permissions);
  const locationSettings = useSelector((state: State) => state.settings.locationSettings);
  const dispatch = useDispatch();
  const customerTypeId = customerTypeRef.current?.value;
  const driverLicenseYearRange = useDynamicYearRange({ numberOfYearsAhead: 20 });

  const ldClient = useLDClient();
  const enableMMCEUCalculator = ldClient?.variation('pos.register.enable-mmceu-calculator.rollout', false);
  const isDaysSupplyEnabled = useIsDaysSupplyFeatureEnabled();
  const useUpdatedGenderOptions = useNewGenderOptionsEnabled();
  const isUseCuraleafLoyaltyTierEnabled = useCuraleafLoyaltyTier();

  const { data: secondaryIdentifications } = useSecondaryIdentificationQuery({ guestId: customer?.Guest_id ?? 0 });

  useEffect(() => {
    setCustomerTypeId(customerTypeId);
  }, [customerTypeId, setCustomerTypeId]);

  useEffect(() => {
    if (Number(values.ReferralSourceId) === 7) {
      setReferralSource(true);
    } else {
      setReferralSource(false);
    }

    if (Number(values.CustomerTypeId) === 21) {
      setmmjOutOfState(true);
    } else {
      setmmjOutOfState(false);
    }

    if (Number(values.CustomerTypeId) in medicalCustomerTypes) {
      setShowMedicalInfo(true);
    } else {
      setShowMedicalInfo(false);
    }
  }, [values, settings]);

  const { isVisible: loyaltyPopupVisible, toggle: toggleLoyaltyPopup } = usePopup();
  const { isVisible: allotmentPopupVisible, toggle: toggleAllotmentPopup } = usePopup();
  const { isVisible: overridePopupVisible, toggle: toggleOverridePopup } = usePopup();
  const { isVisible: externalPatientAllotmentPopupVisible, toggle: toggleExternalPatientAllotmentPopup } = usePopup();
  const { isVisible: identificationPopupVisible, toggle: toggleIdentificationPopup } = usePopup();
  const [allotmentEditType, setAllotmentEditType] = useState<EditAllotmentTypeEnum>(EditAllotmentTypeEnum.Max);
  const { isVisible: daysSupplyCalcVisible, toggle: toggleDaysSupplyCalc } = usePopup();
  const [deletedAddressIdx, setDeletedAddressIdx] = useState<number | null>(null);
  const { isVisible: deleteAddressPopupVisible, toggle: toggleDeleteAddressPopup } = usePopup();
  const useMissippiUnits = locationSettings?.State.toLowerCase() === 'ms' && enableMMCEUCalculator;
  const isConnecticutLocation = locationSettings?.State.toLowerCase() === 'ct';
  const showNewAllotmentFields =
    isConnecticutLocation && showMedicalInfo && !customer?.AllotmentData?.CurrentAllotmentOverriden;
  const genderList = useUpdatedGenderOptions ? UpdatedGenderOptions : GenderOptions;

  const showAllotmentPopup = (type: EditAllotmentTypeEnum) => {
    setAllotmentEditType(type);
    toggleAllotmentPopup();
  };

  const showClearOverridePopup = (type: EditAllotmentTypeEnum) => {
    setAllotmentEditType(type);
    toggleOverridePopup();
  };

  const isFieldRequired = (fieldName: string) => {
    return (
      validationSchema &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      validationSchema.fields[fieldName]?.tests.findIndex(({ name }: any) => name === 'required') >= 0
    );
  };

  const isNestedFieldRequired = (parentFieldName: string, childFieldName: string) => {
    return (
      validationSchema &&
      validationSchema.fields[parentFieldName].fields[childFieldName].tests
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .findIndex(({ name }: any) => name === 'required') >= 0
    );
  };

  const customerImage = find(values.identifications, 'image_url')?.image_url;

  const canAdjustLoyalty = () => {
    if (customer?.IsAnonymous) {
      return false;
    }

    if (
      customer?.MJStateIDNo &&
      customer?.MJStateIDNo.length > 1 &&
      customer?.MJStateIDNo[0].toLowerCase() === 'c' &&
      customer?.MJStateIDNo[1].toLowerCase() === 'g' &&
      !settings.features['AllowAdjustCaregiverLoyaltyPoints']
    ) {
      return false;
    }
    return true;
  };

  const customerTypes = settings.customerTypes
    ? settings.customerTypes.reduce(
        (acc, t) => {
          if (t.IsRetail && !t.IsMedical) {
            acc.push({ label: t.CustomerType, value: String(t.CustomerTypeId) });
          } else if (!customer?.IsAnonymous) {
            acc.push({ label: t.CustomerType, value: String(t.CustomerTypeId) });
          }
          return acc;
        },
        [] as Array<{
          value: string;
          label: string;
        }>
      )
    : [];

  const checkBiotrack = async () => {
    try {
      await validateBiotrackUser({
        PatientScan: customer?.MJStateIDNo || '',
      });
      dispatch(successNotification('Validated'));
    } catch {
      dispatch(errorNotification('Validation failed'));
    }
  };

  const addAddressBookItem = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    const blankAddress: CustomerAddress = {
      Country_Code: '',
      city: '',
      postal_code: '',
      state: '',
      street: '',
    };
    setFieldValue('AddressBook', values.AddressBook ? [...values.AddressBook, blankAddress] : [blankAddress]);
  };

  const deleteAddressBookItem = (index: number | null) => async () => {
    if (index === null) {
      return;
    }
    const nextAddressBook = [...values.AddressBook];
    const [deletedItem] = nextAddressBook.splice(index, 1);
    const AddressId = deletedItem?.AddressId;
    const GuestId = customer?.Guest_id;

    if (AddressId && GuestId) {
      await dispatch(
        removeAddressBookItem({
          AddressId,
          GuestId,
        })
      );
    }

    setFieldValue('AddressBook', nextAddressBook);
  };

  const showDeleteAddressPopup = (index: number) => {
    if (!deleteAddressPopupVisible) {
      setDeletedAddressIdx(index);
      toggleDeleteAddressPopup();
    }
  };

  const addDiscountGroup = () => {
    setFieldValue('discounts', [...values.discounts, {}]);
  };

  const deleteDiscountGroup = (index: number) => {
    const nextDiscounts = values.discounts.filter((_item, itemIdx) => itemIdx !== index);
    setFieldValue('discounts', nextDiscounts);
  };

  const internalDispensed = () => {
    // TotalUsed = internal + external, so subtract external
    return (customer?.AllotmentData?.TotalUsed ?? 0) - (customer?.AllotmentData?.ExternalDispensed ?? 0);
  };

  return (
    <>
      <DeleteCustomerAddressPopup
        onConfirm={deleteAddressBookItem(deletedAddressIdx)}
        isVisible={deleteAddressPopupVisible}
        hide={toggleDeleteAddressPopup}
      />
      {customer && (
        <>
          <EditLoyaltyPointsPopup customer={customer} isVisible={loyaltyPopupVisible} hide={toggleLoyaltyPopup} />
          <EditAllotmentPopup
            customer={customer}
            type={allotmentEditType}
            isVisible={allotmentPopupVisible}
            hide={toggleAllotmentPopup}
          />
          <ClearAllotmentOverridePopup
            customer={customer}
            type={allotmentEditType}
            isVisible={overridePopupVisible}
            hide={toggleOverridePopup}
          />
          <EditExternalPatientAllotmentPopup
            customer={customer}
            type={allotmentEditType}
            isVisible={externalPatientAllotmentPopupVisible}
            hide={toggleExternalPatientAllotmentPopup}
          />
          {daysSupplyCalcVisible && <DaysSupplyCalculator customer={customer} hide={toggleDaysSupplyCalc} />}
          <EditIdentificationPopup
            guestId={customer.Guest_id}
            type={EditIdentificationTypeEnum.Secondary}
            isVisible={identificationPopupVisible}
            hide={toggleIdentificationPopup}
          />
          <TopSectionDiv>
            <IdImageContainer>
              <IdentificationImage imageUrl={customerImage} alt='Customer ID' />
            </IdImageContainer>
            {isDaysSupplyEnabled ? (
              <TopInfoContainer>
                <TopInfoLine label='Last Purchase' value={customer?.LastPurchase} placeholder='-' showEdit={false} />
                {settings.features.ShowLeafLogixLoyalty && (
                  <TopInfoLine
                    label='Available Loyalty Points'
                    value={String(customer?.LoyaltyPoints)}
                    onEdit={toggleLoyaltyPopup}
                    showEdit={!customer?.IsAnonymous}
                    valueTestId='personal-tab-loyalty_points'
                  />
                )}
                <TopInfoLabel>Days Supply Info</TopInfoLabel>
                <TopInfoLine
                  label='Days Supply Remaining'
                  dataTestId='personal-tab-days-supply-remaining'
                  editTestId='personal-tab-days-supply-remaining-edit'
                  value={customer?.DaysSupplyRemaining}
                  onEdit={toggleDaysSupplyCalc}
                  showEdit={!customer?.IsAnonymous}
                />
              </TopInfoContainer>
            ) : (
              <TopInfoContainer>
                <TopInfoLine label='Last Purchase' value={customer?.LastPurchase} placeholder='-' showEdit={false} />
                {(settings.features.ShowLeafLogixLoyalty || settings.features.ShowExternalLoyalty) && (
                  <>
                    <TopInfoLine
                      label='Available Loyalty Points'
                      value={String(customer?.LoyaltyPoints)}
                      onEdit={toggleLoyaltyPopup}
                      showEdit={canAdjustLoyalty()}
                      valueTestId='personal-tab-loyalty_points'
                    />
                    {settings.features.ShowExternalLoyalty && (
                      <LoyaltyTier
                        label='Loyalty Tier'
                        value={String(customer?.LoyaltyTier)}
                        showEdit={false}
                        isUseCuraleafLoyaltyTierEnabled={isUseCuraleafLoyaltyTierEnabled}
                        color={customer.LoyaltyTextColor}
                        valueTestId='personal-tab-loyalty_tier'
                      />
                    )}
                  </>
                )}
                <TopInfoLabel>Allotment Info</TopInfoLabel>
                {settings.features.ShowAllotmentValidDates && (
                  <>
                    <TopInfoLine
                      label='Allotment Valid From'
                      value={customer?.AllotmentData?.ValidFrom}
                      placeholder='-'
                      showEdit={false}
                    />
                    <TopInfoLine
                      label='Allotment Valid Until'
                      value={customer?.AllotmentData?.ValidUntil}
                      placeholder='-'
                      showEdit={false}
                    />
                  </>
                )}
                {customer?.AllotmentData?.MaxLimit != null && (
                  <TopInfoLine
                    label='Max Allotment'
                    dataTestId='personal-tab-max-allotment'
                    editTestId='personal-tab-max-allotment-edit'
                    value={getAllotmentValueToDisplay(customer?.AllotmentData?.MaxLimit)}
                    suffix='g'
                    showEdit={customer?.AllotmentData?.AllowOverrides}
                    showDelete={customer?.AllotmentData?.MaxAllotmentOverriden}
                    onEdit={() => showAllotmentPopup(EditAllotmentTypeEnum.Max)}
                    onDelete={() => showClearOverridePopup(EditAllotmentTypeEnum.Max)}
                  />
                )}
                {showNewAllotmentFields && (
                  <>
                    <TopInfoLine
                      dataTestId='internal-dispensed-allotment'
                      label='Internal Dispensed'
                      value={internalDispensed()}
                      suffix='g'
                      showEdit={false}
                      showDelete={false}
                    />
                    <TopInfoLine
                      dataTestId='external-dispensed-allotment'
                      editTestId='personal-tab-external-dispensed-edit'
                      label='External Dispensed'
                      value={customer?.AllotmentData?.ExternalDispensed ?? 0}
                      suffix='g'
                      showEdit={true}
                      showDelete={false}
                      onEdit={() => showAllotmentPopup(EditAllotmentTypeEnum.External)}
                    />
                  </>
                )}
                {useMissippiUnits ? (
                  <>
                    <TopInfoLine
                      label='Current MMCEU Allotment'
                      value={getAllotmentValueToDisplay((customer?.AllotmentData?.CurrentLimit ?? 0) / 3.5)}
                      showEdit={false}
                      showDelete={false}
                    />
                  </>
                ) : (
                  <>
                    <TopInfoLine
                      dataTestId='personal-tab-current-allotment'
                      editTestId='personal-tab-current-allotment-edit'
                      label='Current Allotment'
                      value={getAllotmentValueToDisplay(customer?.AllotmentData?.CurrentLimit ?? 0)}
                      suffix='g'
                      showEdit={customer?.AllotmentData?.AllowOverrides}
                      showDelete={customer?.AllotmentData?.CurrentAllotmentOverriden}
                      onEdit={() =>
                        showNewAllotmentFields
                          ? showAllotmentPopup(EditAllotmentTypeEnum.BackfillExternal)
                          : showAllotmentPopup(EditAllotmentTypeEnum.Current)
                      }
                      onDelete={() => showClearOverridePopup(EditAllotmentTypeEnum.Current)}
                    />
                  </>
                )}
                {customer?.AllotmentData?.ShowCycleData && moment(customer?.CycleDate).isValid() && (
                  <TopInfoLine
                    label='Cycle Date'
                    value={moment(customer?.CycleDate).format('MM/DD/YYYY')}
                    showEdit={false}
                    showDelete={false}
                  />
                )}
                {settings.integrations?.UseMETRC && settings.integrations?.EnableMETRCUpdatePatients && (
                  <TopButton secondary onClick={toggleExternalPatientAllotmentPopup}>
                    Edit Allotments
                  </TopButton>
                )}
              </TopInfoContainer>
            )}
          </TopSectionDiv>
        </>
      )}

      <SectionH3>Personal Details</SectionH3>
      <SectionDiv>
        {fieldOptions && !fieldOptions['Prefix']?.Hidden && (
          <InputField
            name='NamePrefix'
            label={`Prefix${isFieldRequired('NamePrefix') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_prefix'
          />
        )}
        {fieldOptions && !fieldOptions['Name']?.Hidden && (
          <InputField
            name='FirstName'
            label={`First Name${isFieldRequired('FirstName') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_first-name'
          />
        )}
        {fieldOptions && !fieldOptions['Last Name']?.Hidden && (
          <InputField
            name='LastName'
            label={`Last Name${isFieldRequired('LastName') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_last-name'
          />
        )}
        {fieldOptions && !fieldOptions['Suffix']?.Hidden && (
          <InputField
            name='NameSuffix'
            label={`Suffix${isFieldRequired('NameSuffix') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_name-suffix'
          />
        )}
        {fieldOptions && !fieldOptions['Middle Name']?.Hidden && (
          <InputField
            name='MiddleName'
            label={`Middle Name${isFieldRequired('MiddleName') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_middel-name'
          />
        )}
        {fieldOptions && !fieldOptions['External Name']?.Hidden && (
          <InputField
            name='ExternalName'
            label={`External Name${isFieldRequired('nickname') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_tab_input-field_externalName'
          />
        )}
        {fieldOptions && !fieldOptions['Nickname']?.Hidden && (
          <InputField
            name='nickname'
            label={`Nickname${isFieldRequired('nickname') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_nickname'
          />
        )}
        {fieldOptions && !fieldOptions['Custom Identifier']?.Hidden && (
          <InputField
            name='CustomIdentifier'
            label={`Custom Identifier${isFieldRequired('CustomIdentifier') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_CustomIdentifier'
          />
        )}
        {fieldOptions && !fieldOptions['Gender']?.Hidden && (
          <StyledSelectField
            name='Gender'
            label={`Gender${isFieldRequired('Gender') ? '*' : ''}`}
            placeholder='Select'
            options={genderList}
            disabled={customer?.IsAnonymous && !settings.features['CollectAnonymousDemo']}
            automationId='personal-tab_select-field_gender'
          />
        )}
        {fieldOptions && !fieldOptions['Pronoun']?.Hidden && (
          <InputField
            name='Pronoun'
            label={`Preferred Pronoun${isFieldRequired('Pronoun') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous && !settings.features['CollectAnonymousDemo']}
            automationId='personal-tab_select-field_pronoun'
          />
        )}
        {fieldOptions && !fieldOptions['Date of Birth']?.Hidden && (
          <FormDatePicker
            name='DOB'
            labelText={`Date of birth${isFieldRequired('DOB') ? '*' : ''}`}
            placeholderText='mm/dd/yyyy'
            disabled={customer?.IsAnonymous && !settings.features['CollectAnonymousDemo']}
            automationId='personal-tab_format-date-picker_dob'
          />
        )}
        {customer?.AllotmentData?.ShowCycleData && (
          <FormDatePicker
            name='CycleDate'
            labelText='Cycle Date'
            placeholderText='mm/dd/yyyy'
            disabled={customer?.IsAnonymous && !settings.features['CollectAnonymousDemo']}
            automationId='personal-tab_format-date-picker_cycle_date'
          />
        )}
        {fieldOptions && !fieldOptions['Customer Type']?.Hidden && (
          <StyledSelectField
            ref={customerTypeRef}
            name='CustomerTypeId'
            label={`Customer Type${isFieldRequired('CustomerTypeId') ? '*' : ''}`}
            placeholder='Select'
            options={customerTypes}
            disabled={customer?.IsAnonymous && !settings.features['CollectAnonymousDemo']}
            automationId='personal-tab_select-field_customer-type'
          />
        )}
        {fieldOptions && !fieldOptions['Tribal Affiliation']?.Hidden && (
          <InputField
            name='TribalAffiliation'
            label={`Tribal Affiliation${isFieldRequired('TribalAffiliation') ? '*' : ''}`}
            placeholder='Tribal Affiliation'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_tribal-affiliation'
          />
        )}
        {fieldOptions && !fieldOptions['Status']?.Hidden && (
          <StyledSelectField
            name='status'
            label={`Status${isFieldRequired('status') ? '*' : ''}`}
            placeholder='Select'
            options={CustomerStatusOptions}
            disabled={!permissions.EditCustomers || customer?.IsAnonymous}
            automationId='personal-tab_select-field_status'
          />
        )}
        {fieldOptions && customer && !fieldOptions['Referred By']?.Hidden && (
          <StyledSelectField
            name='ReferralSourceId'
            label={`Referred by${isFieldRequired('ReferralSourceId') ? '*' : ''}`}
            placeholder='Select'
            disabled={referralSourcesLoading || customer?.IsAnonymous}
            options={referralSources.map((source) => ({
              value: String(source.ReferralSourceId),
              label: source.ReferralSource,
            }))}
            automationId='personal-tab_select-field_referral-source'
          />
        )}
        {fieldOptions && referralSource && !fieldOptions['Other Referral Source']?.Hidden && (
          <InputField
            name='OtherReferralSource'
            label={`Other Referral Source${isFieldRequired('OtherReferralSource') ? '*' : ''}`}
            placeholder='Other Referral Souce'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_other'
          />
        )}
      </SectionDiv>
      {showMedicalInfo && (
        <>
          <SectionH3>MMJ State ID</SectionH3>
          <SectionDiv>
            {(mmjOutOfState || (fieldOptions && !fieldOptions['MMJ ID State']?.Hidden)) && (
              <StyledSelectField
                name='MMJIDState'
                label={`State${isFieldRequired('MMJIDState') ? '*' : ''}`}
                placeholder='Select'
                options={StateOptions}
                disabled={customer?.IsAnonymous}
                automationId='personal-tab_select-field_mmjid-state'
              />
            )}
            {fieldOptions && !fieldOptions['MMJ ID Start Date']?.Hidden && (
              <FormDatePicker
                name='MJStartDate'
                labelText={`Start Date${isFieldRequired('MJStartDate') ? '*' : ''}`}
                placeholderText='mm/dd/yyyy'
                disabled={customer?.IsAnonymous}
                automationId='personal-tab_format-date-picker_mj-start-date'
              />
            )}
            {fieldOptions && !fieldOptions['MMJ ID']?.Hidden && (
              <InputField
                name='MJStateIDNo'
                label={`Number${isFieldRequired('MJStateIDNo') ? '*' : ''}`}
                placeholder='...'
                disabled={customer?.IsAnonymous}
                automationId='personal-tab_input-field_mj-state-id'
              />
            )}
            {fieldOptions && !fieldOptions['MMJ expiration date']?.Hidden && (
              <FormDatePicker
                name='MJExpirationDate'
                labelText={`Expiration Date${isFieldRequired('MJExpirationDate') ? '*' : ''}`}
                tooltipText={`Valid through ${
                  settings.features.ThroughMMJIdExpiry ? ' 11:59pm' : '12:01am'
                } the date of expiration`}
                placeholderText='mm/dd/yyyy'
                disabled={customer?.IsAnonymous}
                automationId='personal-tab_format-date-picker_mj-expiration-date'
              />
            )}
            {fieldOptions && !fieldOptions['DDNumber']?.Hidden && (
              <InputField
                name='DDNumber'
                label={`Doctor Designation Number${isFieldRequired('DDNumber') ? '*' : ''}`}
                placeholder='...'
                disabled={customer?.IsAnonymous}
                automationId='personal-tab_input-field_dd-number'
              />
            )}
          </SectionDiv>
          {fieldOptions && !fieldOptions['Patient Certification']?.Hidden && (
            <>
              <SectionH3>Patient Certification</SectionH3>
              <SectionDiv>
                <FormDatePicker
                  name='CertificationCollectionDate'
                  labelText={`Collection Date${isFieldRequired('CertificationCollectionDate') ? '*' : ''}`}
                  placeholderText='mm/dd/yyyy'
                  disabled={customer?.IsAnonymous}
                  automationId='personal-tab_format-date-picker_certification-collection-date'
                />

                <FormDatePicker
                  name='CertificationExpirationDate'
                  labelText={`Expiration Date${isFieldRequired('CertificationExpirationDate') ? '*' : ''}`}
                  placeholderText='mm/dd/yyyy'
                  disabled={customer?.IsAnonymous}
                  automationId='personal-tab_format-date-picker_certification-expiration-date'
                />
              </SectionDiv>
            </>
          )}
        </>
      )}
      <SectionH3>Driver's License</SectionH3>
      <SectionDiv>
        {fieldOptions && !fieldOptions['(Drivers) License Number']?.Hidden && (
          <InputField
            autoComplete='new-password'
            name='DriversLicenseId'
            label={`Number${isFieldRequired('DriversLicenseId') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            type={settings.features.MaskDriversLicenseID ? 'password' : 'text'}
            automationId='personal-tab_input-field_driver-license'
          />
        )}
        {fieldOptions && !fieldOptions['(Drivers) License Expiration']?.Hidden && (
          <FormDatePicker
            name='DLExpirationDate'
            labelText={`Expiration Date${isFieldRequired('DLExpirationDate') ? '*' : ''}`}
            placeholderText='mm/dd/yyyy'
            yearRange={driverLicenseYearRange}
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_format-date-picker_dl-expiration-date'
          />
        )}
        {fieldOptions && !fieldOptions['Additional State Identifiers']?.Hidden && (
          <InputField
            name='additionalStateIdentifiers'
            label={`Additional State Identifiers${isFieldRequired('additionalStateIdentifiers') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_additional-state-id'
          />
        )}
      </SectionDiv>
      {customer && (
        <>
          {!customer?.IsAnonymous && (
            <SectionHeaderSpan>
              <SectionH3>Identification</SectionH3>
              <IconButton
                automationId='customer-page_button_add-identification'
                icon={PlusIcon}
                onClick={toggleIdentificationPopup}
                secondary
              />
            </SectionHeaderSpan>
          )}
          <SectionDiv>
            <Identification
              type={EditIdentificationTypeEnum.Primary}
              id={customer.identifications[1]}
              guestId={customer.Guest_id}
            />
            {secondaryIdentifications?.map((sId) => (
              <Identification
                key={JSON.stringify(sId)}
                type={EditIdentificationTypeEnum.Secondary}
                id={sId}
                guestId={customer.Guest_id}
              />
            ))}
          </SectionDiv>
        </>
      )}
      {settings.integrations?.UseBioTrackPOS && (
        <SectionDiv>
          <Button onClick={checkBiotrack}>Check Biotrack</Button>
        </SectionDiv>
      )}

      <SectionHeaderSpan>
        <SectionH3>Addresses</SectionH3>
        <IconButton
          icon={PlusIcon}
          disabled={values.AddressBook && values.AddressBook.length >= maxAddresses}
          onClick={addAddressBookItem}
          automationId='personal-tab_icon-button_plus-address'
          secondary
        />
      </SectionHeaderSpan>
      <SectionDiv>
        {fieldOptions && !fieldOptions['Street']?.Hidden && (
          <InputField
            name='address.street'
            label={`Street${isNestedFieldRequired('address', 'street') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_street'
          />
        )}
        {fieldOptions && !fieldOptions['Street2']?.Hidden && (
          <InputField
            name='address.street2'
            label={`Street 2${isNestedFieldRequired('address', 'street2') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_street2'
          />
        )}
        {fieldOptions && !fieldOptions['City']?.Hidden && (
          <InputField
            name='address.city'
            label={`City${isNestedFieldRequired('address', 'city') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_city'
          />
        )}
        {fieldOptions && !fieldOptions['Postal Code']?.Hidden && (
          <InputField
            name='address.postal_code'
            label={`Postal Code${isNestedFieldRequired('address', 'postal_code') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous && !settings.features['CollectAnonymousDemo']}
            automationId='personal-tab_input-field_postal-code'
          />
        )}
        {fieldOptions && !fieldOptions['State']?.Hidden && (
          <StyledSelectField
            name='address.state'
            label={`${isCanada ? 'Province' : 'State'}${isNestedFieldRequired('address', 'state') ? ' *' : ''}`}
            placeholder='Select'
            options={isCanada ? ProvinceOptions : StateOptions}
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_select-field_state'
          />
        )}
        {fieldOptions && !fieldOptions['Country']?.Hidden && (
          <StyledSelectField
            name='address.Country_Code'
            label={`Country${isNestedFieldRequired('address', 'Country_Code') ? ' *' : ''}`}
            options={CountryOptions}
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_select-field_country'
          />
        )}
      </SectionDiv>

      <SectionDiv>
        {values.AddressBook &&
          values.AddressBook.map((_item, index) => (
            <>
              <InputField
                name={`AddressBook[${index}].street`}
                label={`Street${isNestedFieldRequired('address', 'street') ? '*' : ''}`}
                automationId={`personal-tab_input-field_street-${index}`}
                placeholder='...'
              />
              <InputField
                name={`AddressBook[${index}].city`}
                label={`City${isNestedFieldRequired('address', 'city') ? '*' : ''}`}
                automationId={`personal-tab_input-field_city-${index}`}
                placeholder='...'
              />
              <InputField
                name={`AddressBook[${index}].postal_code`}
                label={`Postal Code${isNestedFieldRequired('address', 'postal_code') ? '*' : ''}`}
                automationId={`personal-tab_field_postal-code-${index}`}
                placeholder='...'
                type='number'
              />
              <StyledSelectField
                name={`AddressBook[${index}].state`}
                label={`${values.AddressBook[index]?.Country_Code === 'CA' ? 'Province' : 'State'}${
                  isNestedFieldRequired('address', 'state') ? ' *' : ''
                }`}
                automationId={`personal-tab_input-field_state-${index}`}
                placeholder='Select'
                options={values.AddressBook[index]?.Country_Code === 'CA' ? ProvinceOptions : StateOptions}
              />
              <StyledSelectField
                name={`AddressBook[${index}].Country_Code`}
                label={`Country${isNestedFieldRequired('address', 'Country_Code') ? ' *' : ''}`}
                automationId={`personal-tab_input-field_country-${index}`}
                options={CountryOptions}
              />
              <TrashIconDiv>
                <TrashIconButton
                  icon={DeleteIcon}
                  onClick={(e) => {
                    e.preventDefault();
                    showDeleteAddressPopup(index);
                  }}
                  secondary
                />
              </TrashIconDiv>
            </>
          ))}
      </SectionDiv>

      <SectionH3>Contact Details</SectionH3>
      <SectionDiv>
        {fieldOptions && !fieldOptions['Phone Number']?.Hidden && (
          <InputField
            name='PhoneNo'
            label={`Phone Number${isFieldRequired('PhoneNo') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_phone'
          />
        )}
        {fieldOptions && !fieldOptions['Mobile Phone Number']?.Hidden && (
          <InputField
            name='CellPhone'
            label={`Mobile Phone Number${isFieldRequired('CellPhone') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_cellphone'
          />
        )}
        {fieldOptions && !fieldOptions['Preferred Method of Contact']?.Hidden && (
          <StyledSelectField
            name='PreferredMethodOfContact'
            label={`Preferred Contact Method${isFieldRequired('PreferredMethodOfContact') ? '*' : ''}`}
            placeholder='Select'
            options={ContactMethodOptions}
            disabled={customer?.IsAnonymous}
          />
        )}
        {fieldOptions && !fieldOptions['Email Address']?.Hidden && (
          <InputField
            name='Email'
            label={`Email Address${isFieldRequired('Email') ? '*' : ''}`}
            placeholder='...'
            disabled={customer?.IsAnonymous}
            automationId='personal-tab_input-field_email'
          />
        )}
        {settings.features.UseCRM && (
          <>
            <StyledSelectField
              name='OptedIntoEngagementSMS'
              label={`Opt Into Informational SMS`}
              placeholder='-'
              options={OptInOptions}
              disabled={customer?.IsAnonymous}
              automationId='personal-tab_select-field_opt-sms'
            />
            <StyledSelectField
              name='OptedIntoMarketing'
              label={`Opt Into Marketing SMS`}
              placeholder='-'
              options={OptInOptions}
              disabled={customer?.IsAnonymous}
              automationId='personal-tab_select-field_opt-marketing'
            />
          </>
        )}
      </SectionDiv>

      {customer && (
        <>
          <SectionHeaderSpan>
            <SectionH3>Discount Groups</SectionH3>
            <IconButton
              icon={PlusIcon}
              disabled={!permissions.EditDiscountGroupCustomers || values.discounts.length >= maxDiscountGroups}
              onClick={addDiscountGroup}
              automationId='personal-tab_icon-button_plus-discount-group'
              secondary
            />
          </SectionHeaderSpan>

          <SectionDiv>
            {values.discounts.map((_item, index) => (
              <StyledSelectField
                key={index}
                name={`discounts[${index}].id`}
                placeholder='Select'
                options={discountGroups.map((group) => ({
                  value: String(group.Id),
                  label: group.DiscountDescription,
                }))}
                disabled={!permissions.EditDiscountGroupCustomers || customer?.IsAnonymous}
                automationId='personal-tab_select-field_discount-group'
                endAdornment={
                  <Button onClick={() => deleteDiscountGroup(index)} isAdornment>
                    Remove
                  </Button>
                }
              />
            ))}
          </SectionDiv>
        </>
      )}
    </>
  );
};

const FormDatePicker = styled(DatePickerField)`
  max-width: 27rem;
  flex: 1 0 50%;
  margin: 0 1rem 1rem 0;
`;

const TopSectionDiv = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 2.1875rem;
  ${breakpoints.smallTablet.maxQuery} {
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }
`;

const IdImageContainer = styled.div`
  margin-right: 1rem;
  padding: 0.25rem 0;
  width: fit-content;
`;

export const TopInfoContainer = styled.div`
  margin-right: 1rem;
  max-width: 27rem;
  padding: 0.25rem 0;
  width: 100%;
  ${StyledSelectField} {
    margin-right: 0;
  }
  ${InputField} {
    margin-right: 0;
  }
`;

const TopInfoLine = styled(_TopInfoLine)`
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
`;

const LoyaltyTier = styled(TopInfoLine)<{ isUseCuraleafLoyaltyTierEnabled: boolean; color?: string }>`
  ${({ isUseCuraleafLoyaltyTierEnabled }) =>
    isUseCuraleafLoyaltyTierEnabled &&
    `
    > .top-info-line-value {
      text-transform: uppercase;
      font-weight: 600;
    }
  `}

  ${({ isUseCuraleafLoyaltyTierEnabled, color }) =>
    isUseCuraleafLoyaltyTierEnabled &&
    color &&
    `
    > .top-info-line-value {
      color: ${color}
    }
  `}
`;

const TopInfoLabel = styled(_TopInfoLabel)`
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
`;

const TopButton = styled(Button)<{ secondary?: boolean; width?: string }>`
  height: 2rem;

  &:disabled {
    opacity: 0.5;
  }
`;

const TrashIconDiv = styled.div`
  flex-basis: 100%;
  margin-bottom: 0.75rem;
`;

const TrashIconButton = styled(IconButton)`
  margin-left: 0;

  > svg {
    width: 0.8rem;
    height: 0.8rem;

    path,
    line {
      color: ${colors.dutchie.red};
      fill: none;
    }
  }
`;
